<template>
  <Form
    id="create_jobposting_form"
    :validation-schema="form"
    class="form"
    @submit="onSubmit"
  >
    <div class="row g-7">
      <!--begin::Contacts-->
      <div class="card card-flush h-lg-100" id="kt_contacts_main">
        <!--begin::Card header-->
        <div class="card-header pt-3" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <!--begin::Title-->
          <h2 class="fw-bolder d-flex align-items-center text-dark">
            {{ $t("modals.interview.createJobPosting") }}
            <i
              class="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              :title="$t('modals.interview.createJobPostingToolTip')"
            ></i>
          </h2>
          <!--end::Title-->

          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Input group-->
          <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label class="form-label mb-3 required">
              {{ $t("modals.interview.jobPostingTitle") }}</label
            >
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="title"
              placeholder=""
              v-model="jobPosting.title"
            />
            <ErrorMessage
              name="title"
              class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
            <!--end::Input-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label class="form-label mb-3 required">{{
              $t("modals.interview.jobPostingDesc")
            }}</label>
            <!--end::Label-->
            <vue-editor
              name="description"
              v-model="jobPosting.description"
              class="form-control form-control-solid"
              style="padding: 0px"
              :editor-options="editorOptions"
            ></vue-editor>

            <ErrorMessage
              name="description"
              class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
            <!--end::Input-->
          </div>
          <!--end::Input group-->

          <div class="row">
            <!-- Başlangıç Tarihi -->
            <div class="col-md-6">
              <div class="fv-row mb-10">
                <label
                  class="d-flex align-items-center form-label mb-5 required"
                  >{{ $t("modals.interview.startDate") }}</label
                >
                <div class="position-relative align-items-center">
                  <Field
                    type="date"
                    name="startDate"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('modals.interview.enterStartDate')"
                    :min="today"
                    v-model="jobPosting.startDate"
                  />
                  <ErrorMessage
                    name="startDate"
                    class="fv-plugins-message-container invalid-feedback"
                  ></ErrorMessage>
                </div>
              </div>
            </div>

            <!-- Bitiş Tarihi -->
            <div class="col-md-6 fv-row">
              <label
                class="d-flex align-items-center form-label mb-5 required"
                >{{ $t("modals.interview.endDate") }}</label
              >
              <div class="position-relative align-items-center">
                <Field
                  type="date"
                  name="endDate"
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('modals.interview.enterEndDate')"
                  :min="jobPosting.startDate ? jobPosting.startDate : undefined"
                  v-model="jobPosting.endDate"
                />
                <ErrorMessage
                  name="endDate"
                  class="fv-plugins-message-container invalid-feedback"
                ></ErrorMessage>
              </div>
            </div>
          </div>

          <!--begin::Input group-->
          <div class="mb-6 fv-row">
            <!--begin::Label-->
            <label class="form-label mb-3 required">{{
              $t("modals.interview.qualifications")
            }}</label>
            <!--end::Label-->
            <!--begin::Input-->

            <div class="d-flex mb-3">
              <input
                type="text"
                name="features"
                v-model="featureInput"
                @keyup.enter="addQualification"
                class="form-control form-control-lg form-control-solid me-3"
                :placeholder="$t('modals.interview.forAddQualification')"
                v-on:keydown.enter.prevent
              />
              <button
                class="btn btn-secondary ms-2"
                type="button"
                @click="addQualification"
              >
                {{ $t("common.button.add") }}
              </button>
            </div>

            <ErrorMessage
              name="features"
              class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
            <!--end::Input-->
            <span class="fs-7 text-gray-400">{{
              $t("modals.interview.min3Qualification")
            }}</span>
          </div>
          <!--end::Input group-->

          <ul v-if="features.length" class="list-group mt-2 mb-4">
            <li
              v-for="(qualification, index) in features"
              :key="index"
              class="list-group-item d-flex justify-content-between"
            >
              {{ qualification }}
              <button
                type="button"
                @click="removeFeature(index)"
                class="btn btn-sm btn-danger"
              >
                {{ $t("common.button.delete") }}
              </button>
            </li>
          </ul>
          <!--end::Feature List-->
          <!-- <button type="button" @click="show">Test</button> -->
          <div class="d-flex justify-content-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label">{{
                $t("common.button.create")
              }}</span>
              <span class="indicator-progress">
                {{ $t("common.pleaseWait") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Submit button-->
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->
    </div>
  </Form>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { CreateJobPostingModel } from "@/domain/interviewer/create-job-posting/model/CreateJobPostingModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import striptags from "striptags";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "create-jobposting",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const today = dateToIsoDate(new Date())
    const endDate = ref("");
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);

    const swalNotification = new SwalNotification();

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const jobPosting = ref<CreateJobPostingModel>({});

    const { t } = useI18n();
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      //       description: Yup.string()
      // .test(
      //   "is-valid-description",
      //   t("validators_error.required"),
      //   (value) => striptags(value || "").trim() !== ""
      // )
      // .required(t("validators_error.required"))
      // .label("description"),
      startDate: Yup.date()
        .required(t("validators_error.required"))
        .label("startDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("endDate")
        .test(
          "is-greater",
          t("validators_error.startDateBiggerEndDate"),
          function (value) {
            const { startDate } = this.parent;
            return startDate
              ? new Date(value as Date) >= new Date(startDate as Date)
              : true;
          }
        ),
      //     features: Yup.array()
      // .of(Yup.string().required(t("validators_error.required")))
      // .min(3, t("validators_error.minFeatures", { min: 3 }))
      // .label("Features"),
    });

    const addQualification = () => {
      if (
        features.value &&
        features.value.length >= 20
      ) {
        swalNotification.error(
          t("validators_error.max20Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      }

      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = "";
      }
    };

    const removeFeature = (index) => {
      features.value.splice(index, 1);
    };

    const editorOptions = ref({
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Başlık seçenekleri
          ["bold", "italic", "underline", "strike"], // Metin stilleri
          [{ list: "ordered" }, { list: "bullet" }], // Liste türleri
          [{ script: "sub" }, { script: "super" }], // Alt ve üst simgeler
          [{ indent: "-1" }, { indent: "+1" }], // Girintiler
          [{ direction: "rtl" }], // Metin yönü
          [{ color: [] }, { background: [] }], // Renkler
          [{ align: [] }], // Hizalama
          ["link"], // Bağlantı
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    });

    const onSubmit = () => {
      const errors: string[] = [];

      if (
        !jobPosting.value.description ||
        striptags(jobPosting.value.description.trim()) === ""
      ) {
        errors.push(t("validators_error.requiredDesc"));
      }

      if (!features.value || features.value.length < 3) {
        errors.push(t("validators_error.requiredMin3Features", { min: 3 }));
      }

      if (errors.length > 0) {
        Swal.fire({
          icon: "error",
          title: t("validators_error.title"),
          html: errors.join("<br />"),
        });
        return;
      } else {
        submitButton.value?.setAttribute("data-kt-indicator", "on");
        loading.value = true;

        jobPosting.value.jobPostingQualifications = features.value;

        interviewerController
          .createJobPosting(jobPosting.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");

              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CREATE_JOBPOSTING),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "create-interview" });
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            loading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const show = () => {
      jobPosting.value.jobPostingQualifications = features.value;
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      onSubmit,
      featureInput,
      features,
      addQualification,
      removeFeature,
      today,
      endDate,
      jobPosting,
      form,
      show,
      editorOptions,
    };
  },
});
</script>
