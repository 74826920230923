import { BaseDomain } from "@/core/base/BaseDomain";

export class CandidateCvAnalysisModel extends BaseDomain {
  candidate?: number;
  ok?: boolean;

  constructor(
    candidate?: number,
    ok?: boolean,
  ) {
    super();
    this.candidate = candidate;
    this.ok = ok;
  }
}
