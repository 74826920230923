export interface MenuItem {
  pages: {
    sectionTitle?: string;
    heading?: string;
    route: string;
    svgIcon: string;
    fontIcon: string;
    permission?: string;
    shortCode?: string;
    sub?: {
      heading?: string;
      route: string;
      sectionTitle?: string;
      sub?: {
        heading: string;
        route: string;
        sectionTitle?: string;
      }[];
    }[];
  }[];
  sectionTitle?: string;
  heading?: string;
  route?: string;
  permission?: string;
  active?: boolean;
  shortCode?: string;
  sub?: {
    heading?: string;
    route: string;
    sectionTitle?: string;
    sub?: {
      heading: string;
      route: string;
      sectionTitle?: string;
    }[];
  }[];
}

const AIMenuConfig: MenuItem[] = [
  {
    pages: [
      {
        heading: "dashboard.title",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "asideMenu.modules.activeModules",
    route: "/modules",
    active: true,
    pages: [
      {
        sectionTitle: "asideMenu.modules.hotelManagement.title",
        route: "/hotel-management/",
        permission: "TENANT_ADMIN",
        shortCode: "OGZA_HOTEL_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen003.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "asideMenu.modules.hotelManagement.assistant",
            route: "/hotel-management/",
          },
          {
            heading: "asideMenu.modules.hotelManagement.history",
            route: "/hotel-management/staff-list",
          },
        ],
      },
      {
        heading: "asideMenu.modules.hotelManagement.title",
        route: "/hotel-management/",
        permission: "TENANT_USER",
        shortCode: "OGZA_HOTEL_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen003.svg",
        fontIcon: "bi-person",
      },
      {
        sectionTitle: "asideMenu.modules.pollster.title",
        route: "/pollster",
        permission: "TENANT_ADMIN",
        shortCode: "OGZA_SURVEY_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "asideMenu.modules.pollster.create",
            route: "/pollster/create",
          },
          {
            heading: "asideMenu.modules.pollster.polls",
            route: "/pollster/polls",
          },
          {
            heading: "asideMenu.modules.pollster.userAssignment",
            route: "/pollster/user-assignment",
          },
          {
            heading: "asideMenu.modules.pollster.userPolls",
            route: "/pollster/userPolls/",
          },
        ],
      },
      {
        sectionTitle: "asideMenu.modules.interviewer.title",
        route: "/interviewer",
        permission: "TENANT_ADMIN",
        shortCode: "OGZA_INTERVIEW_ASSISTANT_AI",
        svgIcon: "/media/icons/duotune/general/gen002.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "asideMenu.modules.interviewer.jobPostingList",
            route: "/interviewer/jobposting-list",
          },
          {
            heading: "asideMenu.modules.interviewer.createJobPosting",
            route: "/interviewer/new-jobposting",
          },
          {
            heading: "asideMenu.modules.interviewer.create",
            route: "/interviewer/new-interview",
          },
          // {
          //   heading: "asideMenu.modules.interviewer.candidateList",
          //   route: "/interviewer/candidate-list",
          // },
          // {
          //   heading: "asideMenu.modules.interviewer.candidate.detail",
          //   route: "/interviewer/candidate-detail",
          // },
          // {
          //   heading: "asideMenu.modules.interviewer.userAssignment",
          //   route: "/interviewer/choose-invite-type",
          // },
        ],
      },
      // {
      //   sectionTitle: "asideMenu.modules.interviewer.title",
      //   route: "/interviewer",
      //   permission: "TENANT_ADMIN",
      //   shortCode: "OGZA_INTERVIEW_ASSISTANT_AI",
      //   svgIcon: "/media/icons/duotune/general/gen002.svg",
      //   fontIcon: "bi-archive",
      //   sub: [
      //     {
      //       heading: "asideMenu.modules.interviewer.create",
      //       route: "/interviewer/create",
      //     },
      //     {
      //       heading: "asideMenu.modules.interviewer.jobPostingList",
      //       route: "/interviewer/interviewers",
      //     },
      //     {
      //       heading: "asideMenu.modules.interviewer.userAssignment",
      //       route: "/interviewer/add-candidate",
      //     },
      //     {
      //       heading: "asideMenu.modules.interviewer.historyList",
      //       route: "/interviewer/userInterviews",
      //     },
      //   ],
      // },
    ],
  },
  {
    heading: "asideMenu.modules.header",
    route: "/modules",
    permission: "TENANT_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.modules",
        route: "/module-list",
        permission: "TENANT_ADMIN",
        // permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  {
    heading: "asideMenu.admin.header",
    route: "/system",
    permission: "TENANT_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.accountTypes",
        route: "/account-types",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.accountStatus",
        route: "/account-status",
        // permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.staff",
        route: "/staff-list",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.admin.inviteStaff",
        route: "/invite-staff",
        permission: "TENANT_ADMIN",
        svgIcon: "/media/icons/duotune/general/gen041.svg",
        fontIcon: "bi-person",
      },
    ],
  },
  {
    heading: "asideMenu.ogza.header",
    route: "/system",
    // permission: "TENANT_ADMIN",
    permission: "SUPER_ADMIN",
    pages: [
      {
        heading: "asideMenu.admin.modules",
        route: "/all-module",
        //   permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.companies",
        route: "/companies",
        //   permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.createCompany",
        route: "/create-company",
        // permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com008.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "asideMenu.ogza.chatgptSettings",
        route: "/chat-gpt-settings",
        //  permission: "TENANT_ADMIN",
        permission: "SUPER_ADMIN",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
    ],
  },
];

export default AIMenuConfig;
