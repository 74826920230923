import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { InterviewCountResponseDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountResponseDto";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { InterviewCountRequestDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountRequestDto";

export class InterviewCountMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: InterviewCountResponseDto): Result<InterviewCountModel[]> {
    try {
      const interviewCountModel: InterviewCountModel = {
        total: dto.data.attributes.total,
        completed: dto.data.attributes.completed,
        inProgress: dto.data.attributes.inProgress,
        failed: dto.data.attributes.failed,
      };

      return Result.ok(interviewCountModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_COUNTS))
      );
    }
  }

  mapToDTO(domain: InterviewCountModel): Result<InterviewCountRequestDto> {
    try {
      const interviewCountModelRequest = new InterviewCountRequestDto(
        domain.jobPostingId,
        domain.interviewId
      );

      return Result.ok(interviewCountModelRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_COUNTS))
      );
    }
  }
}
