import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class CandidateActionListModel extends BaseDomain {
  candidateAction: {
    candidateId?: number,
    interviewTitle?: string,
    status?: string,
    updatedAt?: Date,
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;

  constructor(
    candidateId?: number,
    interviewTitle?: string,
    status?: string,
    updatedAt?: Date,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.candidateAction = {
      candidateId,
      interviewTitle,
      status,
      updatedAt,
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
