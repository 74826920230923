import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class PollListModel extends BaseDomain {
  poll: {
    id?: number;
    title?: string;
    description?: string;
    status?: string;
    startDate?: Date;
    endDate?: Date;
    isPrivate?: boolean;
    sessionCount?: number;
    surveyQuestionsCount?: number;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  
  constructor(
    id?: number,
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    isPrivate?: boolean,
    sessionCount?: number,
    surveyQuestionsCount?: number,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.poll = {
      id,
      title,
      description,
      startDate,
      endDate,
      isPrivate,
      sessionCount,
      surveyQuestionsCount
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
