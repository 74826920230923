import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class CountryListModel extends BaseDomain {
  country: {
    id?: number;
    name?: string;
    flagName?: string;
    flagWidth?: number;
    flagHeight?: number;
    flagUrl?: string;
    previewUrl?: string | null;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;

  constructor(
    id?: number,
    name?: string,
    flagName?: string,
    flagWidth?: number,
    flagHeight?: number,
    flagUrl?: string,
    previewUrl?: string | null,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.country = {
      id: id,
      name: name,
      flagName: flagName,
      flagWidth: flagWidth,
      flagHeight: flagHeight,
      flagUrl: flagUrl,
      previewUrl: previewUrl,
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
