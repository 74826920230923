
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { VueEditor } from "vue3-editor";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import JobPostingDropdown from "@/presentation/components/dropdowns/JobPostingDropdown.vue";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import {
  InterviewCandidateStatusClass,
  JobPostingStatusBadge,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "jobposting-detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
    JobPostingDropdown,
  },
  methods: {
    positionSuitabilityClass(value) {
      if (value >= 85) {
        return "badge-success";
      } else if (value >= 45) {
        return "badge-warning";
      } else {
        return "badge-danger";
      }
    },
  },
  computed: {},
  setup() {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const store = useStore();
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();

    const jobPostingId = ref<any>(Number(route.params["jobpostingId"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const candidateList = ref<CandidateListModel[]>([]);
    const originalCandidateList = ref<CandidateListModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(50);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const sortPositionSuitability = ref("positionSuitability:asc");

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const arrowDirection = ref<boolean>(false); // false = 'down', true = 'up'

    const toggleArrow = () => {
      sortPositionSuitability.value =
        sortPositionSuitability.value === "positionSuitability:asc"
          ? "positionSuitability:desc"
          : "positionSuitability:asc";
      arrowDirection.value = !arrowDirection.value;
      getCandidateList(1);
    };
    
    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = computed(() => {
      const completed = Number(jobPostingInterviewCount.value?.completed) ?? 0;
      const inProgress =
        Number(jobPostingInterviewCount.value?.inProgress) ?? 0;
      const failed = Number(jobPostingInterviewCount.value?.failed) ?? 0;

      return [completed, inProgress, failed];
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });


    const getJobPostingInterviewCounts = (interviewId) => {
      isLoading.value = true;
      const jobPostingInterviewCountModel: InterviewCountModel = {};
      if (!interviewId || interviewId === "All") {
        jobPostingInterviewCountModel.jobPostingId = jobPostingId.value;
      } else {
        jobPostingInterviewCountModel.interviewId = Number(interviewId);
      }

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: response.getValue().total,
              completed: response.getValue().completed,
              inProgress: response.getValue().inProgress,
              failed: response.getValue().failed,
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleInterviewChange = (event) => {
      const selectedInterviewId = event.target.value;
      getJobPostingInterviewCounts(selectedInterviewId);
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts(null);
      getCandidateList(1);
      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListModel = {
        candidate: {
          jobPostingId: jobPostingId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
        sort: sortPositionSuitability.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateList(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const goToCandidateDetail = (candidateId: number) => {
      router.push({
        path:
          "/interviewer/jobposting-detail/" +
          jobPostingId.value +
          "/candidate/" +
          candidateId,
      });
    };

    watch(
      () => store.getters.changedAnyJobPosting,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getJobPosting();
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      isLoading,
      isCandidatesLoading,
      jobPostingId,
      positionName,
      featureInput,
      features,
      positionDesc,
      chartOptions,
      series,
      arrowDirection,
      today,
      startDate,
      endDate,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      jobPostingInterviewCount,
      getJobPostingInterviewCounts,
      handleInterviewChange,
      JobPostingStatus,
      JobPostingStatusBadge,
      candidateList,
      goToCandidateDetail,
      InterviewCandidateStatusClass,
      toggleArrow,
    };
  },
});
