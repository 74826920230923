import { BaseDomain } from "@/core/base/BaseDomain";

export class InviteUserModel extends BaseDomain {
  id?: number;
  survey?: number;
  emails?: string[] | string;
  locale?: string;

  constructor(id?: number, survey?: string, emails?: string[] | string,   locale?: string,) {
    super();
    this.id = id;
    this.survey = Number(survey);
    this.emails = emails;
    this.locale = locale;
  }
}
