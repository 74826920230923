import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";
import { JobPostingQualificationModel } from "./JobPostingQualificationModel";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";

export class JobPostingListModel extends BaseDomain {
  jobPosting: {
    jobPostingId?: number;
    title?: string;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    status?: string;
    candidateCount?: number;
    hasCompletedInterviewSession?: boolean;
    hasCvAnalysis?: boolean;
    jobPostingQualifications?: JobPostingQualificationModel[];
    interviews?: JobPostingInterviewModel[];
    isActive?: boolean;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;

  constructor(
    jobPostingId?: number,
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    status?: string,
    candidateCount?: number,
    hasCompletedInterviewSession?: boolean,
    hasCvAnalysis?: boolean,
    jobPostingQualifications?: JobPostingQualificationModel[],
    interviews?: JobPostingInterviewModel[],
    isActive?: boolean,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.jobPosting = {
      jobPostingId,
      title,
      description,
      startDate,
      endDate,
      status,
      candidateCount,
      hasCompletedInterviewSession,
      hasCvAnalysis,
      jobPostingQualifications,
      interviews,
      isActive
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
