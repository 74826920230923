<template>
  <!--begin::Basic info-->
  <div class="card">
    <div
      v-if="isLoading"
      style="
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-35px"
          src="/media/loading_icon.gif"
          style="width: 40px"
        />
      </div>
    </div>

    <!--begin::Card header-->
    <div v-else class="card-header border-0 pt-3" style="text-align: center">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{ $t("profile.personelInfo") }}</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div v-if="!isLoading" id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        @submit="onSubmit"
        :validation-schema="infoForm"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9 ps-14">
          <div class="row mb-2">
            <div class="row mb-5">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                <span>{{ $t("profile.name") }}</span>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-6 fv-row">
                <Field
                  type="string"
                  name="name"
                  v-model="updateUserModel.name"
                  class="form-control form-control-lg"
                  :placeholder="$t('profile.enterName')"
                />

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
          </div>
          <div class="row mb-2">
            <div class="row mb-5">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                <span>{{ $t("profile.surname") }}</span>
                <!--
                  <i
                    class="fas fa-exclamation-circle ms-1 fs-7"
                    data-bs-toggle="tooltip"
                    title="Phone number must be active"
                  ></i>
    -->
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-6 fv-row">
                <Field
                  type="text"
                  name="surname"
                  v-model="updateUserModel.surname"
                  class="form-control form-control-lg"
                  :placeholder="$t('profile.enterSurname')"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="surname" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
          </div>
          <div class="row mb-2">
            <div class="row mb-5">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                <span>{{ $t("profile.email") }}</span>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-6 fv-row">
                <Field
                  type="text"
                  name="email"
                  v-model="updateUserModel.email"
                  class="form-control form-control-lg"
                  :placeholder="$t('profile.enterEmail')"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="email" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
          </div>
          <div class="row mb-2">
            <div class="row mb-5">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                <span>{{ $t("profile.phone") }}</span>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-1 fv-row">
                <Field
                  type="text"
                  name="countryCode"
                  v-model="updateUserModel.countryCode"
                  class="
                    form-control form-control-lg form-control-solid
                    attention-cursor
                  "
                  :placeholder="$t('profile.enterPhone')"
                  readonly
                />
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-lg-5 fv-row">
                <Field
                  type="text"
                  name="phone"
                  v-model="phone"
                  class="
                    form-control form-control-lg form-control-solid
                    attention-cursor
                  "
                  :placeholder="$t('profile.enterPhone')"
                  readonly
                />
              </div>
              <!--end::Col-->
            </div>
          </div>
        </div>
        <!--end::Card body-->
        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <!--begin::Submit button-->
          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton"
            class="btn btn-primary"
          >
            <span class="indicator-label">
              {{ $t("common.button.save") }}
            </span>
            <span class="indicator-progress">
              {{ $t("common.pleaseWait") }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Submit button-->
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";

export default defineComponent({
  name: "Profilim",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const scrollElRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const userController = store.state.ControllersModule.userController;
    const updateUserModel = ref<UpdateUserModel>({});
    const phone = ref<string>("");

    //Create form validation object
    const infoForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
    });

    const getUserInfo = () => {
      isLoading.value = true;

      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {
            updateUserModel.value.name = response.getValue().name;
            updateUserModel.value.surname = response.getValue().surname;
            updateUserModel.value.email = response.getValue().email;
            updateUserModel.value.countryCode = response.getValue().countryCode;
            phone.value = response.getValue().phoneNumber;
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    //Form submit function
    const onSubmit = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      userController
        .updateUser(updateUserModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    onMounted(() => {
      getUserInfo();
      setCurrentPageTitle(t("profile.title"));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    return {
      infoForm,
      PAGE_TITLE_PATH,
      translate,
      onSubmit,
      updateUserModel,
      phone,
      isLoading,
    };
  },
});
</script>

<style>
.attention-cursor:hover {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJElEQVQY02NgwA8YGBgYPDQ4QWIDBuIQiA1iwjIQFBoBcsgAAUEIGAGh3DRgAAAAASUVORK5CYII="),
    auto;
}
</style>
