import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeleteQuestionOptionService from "../service/DeleteQuestionOptionService";
import { IDeleteQuestionOptionRepository } from "@/domain/interviewer/update-interview/deleteQuestionOption/repository/IDeleteQuestionOptionRepository";
import { DeleteQuestionOptionRequestDto } from "@/domain/interviewer/update-interview/deleteQuestionOption/dto/DeleteQuestionOptionRequestDto";

const deleteQuestionOptionService = new DeleteQuestionOptionService();

export class DeleteQuestionOptionRepository implements IDeleteQuestionOptionRepository {
  async deleteQuestionOption(request: DeleteQuestionOptionRequestDto): Promise<Result<any>> {
    try {
      return await deleteQuestionOptionService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.DELETE_INTERVIEW_QUESTION_OPTION)));
    }
  }
}
