import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateQuestionModel } from "@/domain/interviewer/update-interview/updateQuestion/model/UpdateQuestionModel";
import { UpdateQuestionRequestDto } from "@/domain/interviewer/update-interview/updateQuestion/dto/UpdateQuestionRequestDto";

export class UpdateQuestionMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateQuestionModel> {
    try {
        const updateJobPostingModel: UpdateQuestionModel = {
          ok: dto.ok,
        };
        return Result.ok(updateJobPostingModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW_QUESTION))
      );
    }
  }

  mapToDTO(domain: UpdateQuestionModel): Result<UpdateQuestionRequestDto> {
    try {
      const updateJobPosting: UpdateQuestionRequestDto = {
        id: domain.id,
        data: {
          questionText: domain.questionText,
        }
      };

      return Result.ok(updateJobPosting);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW_QUESTION))
      );
    }
  }
}
