
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { AddCandidateModel } from "@/domain/interviewer/add-candidate/model/AddCandidateModel";
import Swal from "sweetalert2";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { JobPostingStatusBadge } from "@/presentation/helper/StatusBadge";
import { formatLocalizedDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "invite-candidate",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);
    const startDate = ref("");
    const endDate = ref("");

    const countryList = ref<CountryListModel[]>([]);

    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const inviteCandidateModel = ref<AddCandidateModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = computed(() => {
      const completed = Number(jobPostingInterviewCount.value?.completed) ?? 0;
      const inProgress =
        Number(jobPostingInterviewCount.value?.inProgress) ?? 0;
      const failed = Number(jobPostingInterviewCount.value?.failed) ?? 0;

      return [completed, inProgress, failed];
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });

    const validateFile = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }

        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }
      }
    };

    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailPattern.test(email);
    };

    const inviteCandidateForm = Yup.object().shape({
      firstName: Yup.string()
        .required(t("validators_error.required"))
        .label("firstName"),
      lastName: Yup.string()
        .required(t("validators_error.required"))
        .label("lastName"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("email"),
    });

    const submitForm = () => {
      if (!inviteCandidateModel.value.cv) {
        swalNotification.error(
          t("validators_error.requiredCV"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      } else if (!validateEmail(inviteCandidateModel.value.email)) {
        swalNotification.error(
          `Lütfen geçerli bir e-posta adresi giriniz. `,
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      } else {
        inviteCandidateModel.value.jobPosting = jobPostingId.value;

        interviewerController
          .addCandidate(inviteCandidateModel.value)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_CANDIDATE),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({
                  path: "/interviewer/candidate-list/" + jobPostingId.value,
                });
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            isLoading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const getJobPostingInterviewCounts = () => {
      const jobPostingInterviewCountModel: InterviewCountModel = {
        jobPostingId: jobPostingId.value,
      };

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: response.getValue().total,
              completed: response.getValue().completed,
              inProgress: response.getValue().inProgress,
              failed: response.getValue().failed,
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts();

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const focusNextInput = (fieldName) => {
      // `data-field` attribute ile element seçimi
      const nextInput = document.querySelector(
        `[data-field="${fieldName}"]`
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      JobPostingStatus,
      validateFile,
      focusNextInput,
      countryList,
      inviteCandidateForm,
      submitForm,
      inviteCandidateModel,
      isLoading,
      chartOptions,
      series,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      JobPostingStatusBadge,
      jobPostingInterviewCount,
    };
  },
});
