import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { GetMultipleCandidateTemplateModel } from "@/domain/interviewer/multiple-candidate-template/model/GetMultipleCandidateTemplateModel";
import { GetMultipleCandidateTemplateResponseDto } from "@/domain/interviewer/multiple-candidate-template/dto/GetMultipleCandidateTemplateResponseDto";

export class GetMultipleCandidateTemplateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: GetMultipleCandidateTemplateResponseDto): Result<GetMultipleCandidateTemplateModel> {
    try {
      const getTemplateModel: GetMultipleCandidateTemplateModel = {
      };

      if(dto.data.attributes.file.data)
      {
        getTemplateModel.templateUrl = dto.data.attributes.file.data.attributes.url
      }

      return Result.ok(getTemplateModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.GET_MULTI_CANDIDATE_TEMPLATE))
      );
    }
  }

  mapToDTO(): Result<any> {
    try {
      return Result.ok(null);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.GET_MULTI_CANDIDATE_TEMPLATE))
      );
    }
  }
}
