
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import InterviewDropdown from "@/presentation/components/dropdowns/InterviewDropdown.vue";
import { useRoute, useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import {
  dateToIsoDate,
  formatFullDateWithTime,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";
import { CandidateListByInterviewModel } from "@/domain/interviewer/candidate-list-by-interview/model/CandidateListByInterviewModel";
import {
  CandidateInterviewStatusClass,
  InterviewCandidateStatusClass,
} from "@/presentation/helper/StatusBadge";
import { CandidateInterviewStatus } from "@/domain/constants/CandidateInterviewStatus";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import Swal from "sweetalert2";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "NewInterviewDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    InterviewDropdown,
  },
  data() {
    return {
      today: dateToIsoDate(new Date()), // YYYY-MM-DD formatında bugünün tarihi
    };
  },
  methods: {
    getStatusLabel(status) {
      switch (status) {
        case CandidateInterviewStatus.COMPLETED:
          return "Tamamlandı";
        case CandidateInterviewStatus.EXPIRED:
          return "Süresi Doldu";
        case CandidateInterviewStatus.FAILED:
          return "Başarısız";
        case CandidateInterviewStatus.IN_PROGRESS:
          return "Devam Ediyor";
        case CandidateInterviewStatus.ELIMINATED:
          return "Elendi";
        case CandidateInterviewStatus.PASSIVE:
          return "Pasif";
        case CandidateInterviewStatus.INVITED:
          return "Davet Edildi";
        case CandidateInterviewStatus.PENDING:
          return "Davet Bekliyor";
        default:
          return "Bilinmiyor";
      }
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const isInvitingCandidateToInterview = ref<boolean>(false);
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const candidateInvite = ref<InterviewInviteModel>({
      expirationDate: new Date(),
    });

    const revokeInterviewInviteModel = ref<RevokeInterviewInviteModel>({});

    const interviewId = ref<any>(Number(route.params["id"]));
    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const interview = ref<InterviewDetailModel>({});
    const candidateList = ref<CandidateListByInterviewModel[]>([]);
    const originalCandidateList = ref<CandidateListByInterviewModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(10);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const inviteInterviewForm = Yup.object().shape({
      expirationDate: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? dateToIsoDate(new Date(oldDate)) : "";
      selectedInterviewId.value = id;
    };

    const getInterviewDetail = async () => {
      isLoading.value = true;

      const interviewDetailModel: InterviewDetailModel = {
        id: interviewId.value,
      };

      interviewerController
        .getInterviewDetail(interviewDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(interview.value, response.getValue());
            getCandidateList(1);
          } else {
            if (!interview.value.id) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      isCandidatesLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListByInterviewModel = {
        candidate: {
          interviewId: interviewId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateListByInterview(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const inviteCandidateToInterview = (
      candidateId?: number,
      interviewId?: number
    ) => {
      if (isInvitingCandidateToInterview.value == true) {
        return;
      }

      isInvitingCandidateToInterview.value = true;

      candidateInvite.value.candidate = candidateId;
      candidateInvite.value.interview = interviewId;
      candidateInvite.value.locale = i18n.global.locale.value;

      Swal.fire({
        title: t("swalMessages.INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .interviewInvite(candidateInvite.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidateList(1);
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
              isInvitingCandidateToInterview.value = false;
              document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const revokeInterviewInvite = (
      candidateId?: number,
      interviewId?: number
    ) => {
      revokeInterviewInviteModel.value.candidate = candidateId;
      revokeInterviewInviteModel.value.interview = interviewId;

      Swal.fire({
        title: t("swalMessages.REVOKE_INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .revokeInterviewInvite(revokeInterviewInviteModel.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(
                    SWAL_MESSAGES.SUCCESS.SUCCESS_REVOKE_INVITE_INTERVIEW
                  ),
                  icon: "success",
                }).then(() => {
                  getCandidateList(1);
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewDetail();
    });

    return {
      formatFullDateWithTime,
      CandidateInterviewStatus,
      CandidateInterviewStatusClass,
      InterviewCandidateStatusClass,
      formatLocalizedDate,
      url,
      form,
      inviteInterviewForm,
      candidateInvite,
      newEndDate,
      changeDateRef,
      selectInterview,
      isLoading,
      isCandidatesLoading,
      isInvitingCandidateToInterview,
      positionDesc,
      interviewId,
      interview,
      visibleRequirements,
      hiddenRequirementCount,
      inviteCandidateToInterview,
      revokeInterviewInvite,
      candidateList,
      candidateTotalItems,
      candidateCurrentPage,
      candidatePageCount,
      candidatePageSize,
      getCandidateList,
      originalCandidateList,
    };
  },
});
