import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import SessionListService from "../service/SessionListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ISessionListRepository } from "@/domain/hotel-management/session-list/repository/ISessionListRepository";
import { SessionListRequestDto } from "@/domain/hotel-management/session-list/dto/SessionListRequestDto";
import { SessionListResponseDto } from "@/domain/hotel-management/session-list/dto/SessionListResponseDto";

const sessionListService = new SessionListService();

export class SessionListRepository implements ISessionListRepository {
  async sessionList(
    request: SessionListRequestDto
  ): Promise<Result<SessionListResponseDto>> {
    try {
      return await sessionListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<SessionListResponseDto>(response.getValue());
          } else {
            return Result.fail<SessionListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<SessionListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.SESSION_LIST))
      );
    }
  }
}
