
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { useStore } from "vuex";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import SwalNotification from "@/presentation/plugins/SwalNotification";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
    VueEditor,
  },
  setup(props, { emit }) {
    const jobPostingId = ref(0);
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const { t } = useI18n();

    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const swalNotification = new SwalNotification();

    const getJobPostingList = async (page) => {
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interviews: [],
          status: "ACTIVE, PENDING, PASSIVE",
        },
        page: page,
        pageSize: pageSize.value,
      };

      interviewerController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              totalItems.value = jobPosting.pagination.total;
              currentPage.value = jobPosting.pagination.page;
              pageCount.value = jobPosting.pagination.pageCount;

              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const sendJobPosting = () => {
      // Emit selected job posting to parent component
      if (jobPostingId.value) {
        emit("updateJobPosting", jobPostingId.value);
      }
    };

    onMounted(() => {
      getJobPostingList(1);
    });

    return {
      jobPostingId,
      jobPostingList,
      sendJobPosting,
    };
  },
});
