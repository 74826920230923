
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { AddQuestionMapper } from "../mapper/AddQuestionMapper";
import { AddQuestionRepository } from "../repository/AddQuestionRepository";
import { IAddQuestionUseCase } from "@/domain/interviewer/update-interview/addQuestion/usecase/IAddQuestionUsecase";
import { IAddQuestionRepository } from "@/domain/interviewer/update-interview/addQuestion/repository/IAddQuestionRepository";
import { AddQuestionModel } from "@/domain/interviewer/update-interview/addQuestion/model/AddQuestionModel";

export class AddQuestionUsecase implements IAddQuestionUseCase {
  addQuestionRepository : IAddQuestionRepository = new AddQuestionRepository();
  addQuestionMapper = new AddQuestionMapper();

  constructor() {
  }

  async execute(model: AddQuestionModel): Promise<Result<AddQuestionModel>> {
    try {
      const dto = this.addQuestionMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<AddQuestionModel>(dto.getError());
      } else {
        const response = await this.addQuestionRepository.addQuestion(dto);

        if (response.isSuccess) {
          const domain = this.addQuestionMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AddQuestionModel>(domain.getValue());
        } else {
          return Result.fail<AddQuestionModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AddQuestionModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.ADD_INTERVIEW_QUESTION))
      );
    }
  }
}
