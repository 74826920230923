
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { SurveyQuestionsModel } from "@/domain/pollster/create-poll/model/SurveyQuestionsModel";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<CreatePollModel>({
      surveyQuestions: [],
      isPrivate: false,
    });

    const questions = ref<
      Array<{
        text: string;
        type: string;
        options: { optionText: string }[];
        option: string;
      }>
    >([]);

    //Create form validation object
    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("Title"),
      desc: Yup.string()
        .required(t("validators_error.required"))
        .label("Description"),
      startDate: Yup.string()
        .required(t("validators_error.required"))
        .label("StartDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("EndDate")
        .test("is-greater", t("validators_error.startDateBiggerEndDate"), function (value) {
          const { startDate } = this.parent;
          return startDate
            ? new Date(value as Date) >= new Date(startDate as Date)
            : true;
        }),
    });

    const addQuestion = () => {
      const newQuestion = {
        text: "",
        type:QuestionTypes.OPEN_ENDED,
        options: [],
        option: "",
      };
      questions.value.push(newQuestion);
      poll.value.surveyQuestions.push(
        new SurveyQuestionsModel("", QuestionTypes.OPEN_ENDED, [])
      );
    };

    const updateOptions = (index: number) => {
      questions.value[index].options = [];
      questions.value[index].option = "";
      poll.value.surveyQuestions[index].surveyQuestionOptions = [];
    };

    const deleteQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const addOption = (index: number) => {
      if (questions.value[index].option) {
        questions.value[index].options.push({
          optionText: questions.value[index].option,
        });
        poll.value.surveyQuestions[index].surveyQuestionOptions.push({
          optionText: questions.value[index].option,
        });
        questions.value[index].option = "";
        (
          document.querySelector(
            `input[ref="questionOption"]`
          ) as HTMLInputElement
        )?.focus();
      }
    };

    const onSubmit = () => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true;

      const surveyQuestions: SurveyQuestionsModel[] = questions.value.map(
        (question) => {
          return {
            questionText: question.text,
            questionType: question.type,
            surveyQuestionOptions: question.options,
          };
        }
      );

      poll.value.surveyQuestions = surveyQuestions;

      pollsterController
        .createPoll(poll.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CREATE_POLL),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "polls" });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          loading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
    });

    return {
      form,
      onSubmit,
      addQuestion,
      updateOptions,
      deleteQuestion,
      addOption,
      questions,
      poll,
      QuestionTypes
    };
  },
});
