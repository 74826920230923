import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IUpdateCandidateService } from "@/domain/interviewer/update-candidate/service/IUpdateCandidateService";
import { UpdateCandidateRequestDto } from "@/domain/interviewer/update-candidate/dto/UpdateCandidateRequestDto";

export default class UpdateCandidateService implements IUpdateCandidateService {
  async update(
    request: UpdateCandidateRequestDto
  ): Promise<Result<any>> {
    try {
      const formData = new FormData();
      if (request.cv) {
        formData.append("cv", request.cv[0]);
      }
      formData.append("data", JSON.stringify(request.data));

      formData.forEach((value, key) => {
        console.log(key, value);
      });
      
      return await instance
        .put<any>(API_ROUTE.CANDIDATE + request.id, formData)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_UPDATE_CANDIDATE)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.UPDATE_CANDIDATE))
      );
    }
  }
}
