import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { GenerateOTPUsecase } from "@/application/auth/generate-otp/usecase/GenerateOTPUseCase";
import { GenerateOTPModel } from "@/domain/auth/generate-otp/model/GenerateOTPModel";
import { VerifyOTPUsecase } from "@/application/auth/verify-otp/usecase/VerifyOTPUseCase";
import { VerifyOTPModel } from "@/domain/auth/verify-otp/model/VerifyOTPModel";

export function createOTPController() {
  const useCases = {
    generateOTP: {
      useCase: new GenerateOTPUsecase(),
      model: GenerateOTPModel,
    },
    verifyOTP: {
      useCase: new VerifyOTPUsecase(),
      model: VerifyOTPModel,
    },
  };

  return new OTPController(useCases);
}

export class OTPController implements IController {
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
