import { InterviewCountRepository } from "../repository/InterviewCountRepository";
import { Result } from "@/core/utility/ResultType";
import { InterviewCountMapper } from "../mapper/InterviewCountMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInterviewCountUseCase } from "@/domain/interviewer/interview-counts/usecase/IInterviewCountService";
import { IInterviewCountRepository } from "@/domain/interviewer/interview-counts/repository/IInterviewCountRepository";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";

export class InterviewCountUseCase implements IInterviewCountUseCase {
  tenantUsersSessionListRepository: IInterviewCountRepository = new InterviewCountRepository();
  tenantUsersSessionListMapper = new InterviewCountMapper();

  constructor() {
  }

  async execute(model: InterviewCountModel): Promise<Result<InterviewCountModel>> {
    try {
      const dto = this.tenantUsersSessionListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<InterviewCountModel>(dto.getError());
      } else {
        const response = await this.tenantUsersSessionListRepository.getInterviewCounts(dto);

        if (response.isSuccess) {
          const domain = this.tenantUsersSessionListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<InterviewCountModel>(domain.getValue());
        } else {
          return Result.fail<InterviewCountModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<InterviewCountModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.INTERVIEW_COUNTS))
      );
    }
  }
}
