import { Result } from "@/core/utility/ResultType";
import ChangeTenantEstateService from "../service/ChangeTenantEstateUserService";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/application/constants/Repository";
import { IChangeTenantEstateRepository } from "@/domain/auth/change-tenant-estate/repository/IChangeTenantEstateRepository";
import { ChangeTenantEstateRequestDto } from "@/domain/auth/change-tenant-estate/dto/ChangeTenantEstateRequestDto";
import { ChangeTenantEstateResponseDto } from "@/domain/auth/change-tenant-estate/dto/ChangeTenantEstateResponseDto";

const changeTenantEstateService = new ChangeTenantEstateService();

export default class ChangeTenantEstateRepository
  implements IChangeTenantEstateRepository
{
  async changeTenantEstate(
    request: ChangeTenantEstateRequestDto
  ): Promise<Result<ChangeTenantEstateResponseDto>> {
    try {
      return changeTenantEstateService
        .changeTenantEstate(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<ChangeTenantEstateResponseDto>(
              response.getValue()
            );
          } else {
            return Result.fail<ChangeTenantEstateResponseDto>(
              response.getError()
            );
          }
        })
        .catch((error) => {
          return Result.fail<ChangeTenantEstateResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.CHANGE_TENANT_ESTATE)
        )
      );
    }
  }
}
