import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICandidateListByInterviewUseCase } from "@/domain/interviewer/candidate-list-by-interview/usecase/ICandidateListByInterviewUsecase";
import { ICandidateListByInterviewRepository } from "@/domain/interviewer/candidate-list-by-interview/repository/ICandidateListByInterviewRepository";
import { CandidateListByInterviewRepository } from "../repository/CandidateListByInterviewRepository";
import { CandidateListByInterviewMapper } from "../mapper/CandidateListByInterviewMapper";
import { CandidateListByInterviewModel } from "@/domain/interviewer/candidate-list-by-interview/model/CandidateListByInterviewModel";

export class CandidateListByInterviewUseCase implements ICandidateListByInterviewUseCase {
  candidateListRepository: ICandidateListByInterviewRepository =
    new CandidateListByInterviewRepository();
  candidateListMapper = new CandidateListByInterviewMapper();

  constructor() {}

  async execute(
    model: CandidateListByInterviewModel
  ): Promise<Result<CandidateListByInterviewModel>> {
    try {
      const dto = this.candidateListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<CandidateListByInterviewModel>(dto.getError());
      } else {
        const response = await this.candidateListRepository.candidateList(dto);

        if (response.isSuccess) {
          const domain = this.candidateListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateListByInterviewModel>(domain.getValue());
        } else {
          return Result.fail<CandidateListByInterviewModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateListByInterviewModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_LIST_BY_INTERVIEW))
      );
    }
  }
}
