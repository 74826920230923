import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AddQuestionService from "../service/AddQuestionService";
import { IAddQuestionRepository } from "@/domain/interviewer/update-interview/addQuestion/repository/IAddQuestionRepository";
import { AddQuestionRequestDto } from "@/domain/interviewer/update-interview/addQuestion/dto/AddQuestionRequestDto";

const addQuestionService = new AddQuestionService();

export class AddQuestionRepository implements IAddQuestionRepository {
  async addQuestion(
    request: AddQuestionRequestDto
  ): Promise<Result<any>> {
    try {
      return await addQuestionService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.ADD_INTERVIEW_QUESTION))
      );
    }
  }
}
