import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { GetProductRequestDto } from "@/domain/product/get-product/dto/GetProductRequestDto";
import { IGetProductService } from "@/domain/product/get-product/service/IGetProductService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";

export default class GetProductService implements IGetProductService {
  async get(
    request: GetProductRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .get<any>(API_ROUTE.PRODUCTS + request.shortCode  +
          request.toQueryString() )
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.API_ERROR_MESSAGE)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.PRODUCT.GET_PRODUCT))
      );
    }
  }
}
