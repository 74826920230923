<template>
  <div class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-6 mx-auto">
      <!--begin::Contact group wrapper-->
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>   {{ $t("modals.interview.jobPosting") }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-control form-select-md form-control-solid
                fw-bolder
                mb-1
              "
            >
              <option value="Active">{{ $t("common.pleaseChoose") }}</option>
              <option value="Hybrid" selected>
                Hybrid Çalışan Frontend Developer
              </option>
              <option value="Approved">Hybrid Çalışan Backend Developer</option>
              <option value="Declined">
                Ofisten Çalışan Fullstack Developer
              </option>
              <option value="Declined">
                Hybrid Çalışan Software Specialist
              </option>
            </select>
            <!--end::Select-->
          </div>
          <!--end::Contact groups-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->

      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Hybrid Çalışacak Frontend Developer</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Contact group-->
          <div class="mb-5">
            <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
              >Pozisyon:
            </a>
            <div class="badge badge-light-info d-inline">
              Frontend Developer
            </div>
          </div>

          <!-- <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Pozisyon:
              </a>
              <div class="badge badge-light-info d-inline">
                Frontend Developer
              </div>
            </div> -->

          <a class="fs-6 fw-bold text-gray-800 text-hover-primary mt-3"
            >Tanım:
          </a>
          <p>
            Yaratıcı ve dinamik bir yazılım ekibine katılacak, kullanıcı dostu
            web uygulamaları geliştirmek için güçlü bir frontend developer
            arıyoruz. Çalışma modeli hybrid olacak, ofis ve uzaktan çalışma
            fırsatı sunulmaktadır.
          </p>
          <a class="fs-6 fw-bold text-gray-800 mt-3">Teklif Edilen İmkanlar</a>
          <ul>
            <li>Hybrid çalışma modeli (ofis ve uzaktan çalışma seçeneği)</li>
            <li>Esnek çalışma saatleri</li>
            <li>Yaratıcı ve destekleyici bir çalışma ortamı</li>
            <li>
              Yeteneklerinizi geliştirmek için eğitim ve gelişim fırsatları
            </li>
          </ul>

          <!--begin::Contact group-->
          <!--begin::Permissions-->
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 3 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Vuejs, Typescript ile
              tecrübesi olan
            </div>

            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>9 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
        </div>
        <!--end::Card body-->
      </div>
      <div class="row">
        <router-link to="/interviewer/choose-invite-type">
          <a class="btn btn-md btn-primary btn-active-primary my-1 w-100">
            Aday Davet Et
          </a>
        </router-link>
      </div>
    </div>
    <!--end::Contact groups-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);
    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [t("modals.interview.completedInterview"), t("modals.interview.ongoingInterview"), t("modals.interview.canceledInterview")],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = [5, 4, 2];

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: 146,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: 146,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case QuestionTypes.OPEN_ENDED:
          return "Açık Uçlu";
        case QuestionTypes.SINGLE_CHOICE:
          return "Tek Seçenekli";
        case QuestionTypes.MULTIPLE_CHOICE:
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
      getPollChatLogs();
    });

    return {
      questionTypeLabel,
      pollResponse,
      pollChatLogs,
      isLoading,
      chartOptions,
      series,
    };
  },
});
</script>
