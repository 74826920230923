
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import * as Yup from "yup";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<StatisticPollListModel[]>([]);
    const originalPollList = ref<StatisticPollListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref<Date>();
    const selectedPollId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getPollList = async (page) => {
      isChanged.value = false;
      pollList.value = [];
      originalPollList.value = [];

      isLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: StatisticPollListModel = {
        poll: {},
        page: page,
        pageSize: pageSize.value,
      };

      pollsterController
        .statisticPollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getPollList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      url,
      pollList,
      form,
      newEndDate,
      changeDateRef,
      isLoading,
      getPollList,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
    };
  },
});
