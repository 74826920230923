
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { CandidateInterviewModel } from "@/domain/interviewer/candidate-detail/model/CandidateInterviewModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import Swal from "sweetalert2";
import { InterviewCandidateStatusClass } from "@/presentation/helper/StatusBadge";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";
import { CandidateCvAnalysisStatus } from "@/domain/constants/CandidateCvAnalysisStatus";
import { CandidateInterviewResponsesModel } from "@/domain/interviewer/candidate-interview-responses/model/CandidateInterviewResponsesModel";
import { CandidateInterviewLogsModel } from "@/domain/interviewer/candidate-interview-logs/model/CandidateInterviewLogsModel";

export default defineComponent({
  name: "jobposting-detail-candidate",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const store = useStore();
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const isCvAnalysisLoading = ref<boolean>(false);
    const isCandidateLoading = ref<boolean>(false);
    const isCandidateInterviewDetailLoading = ref<boolean>(false);

    const features = ref<string[]>([]);
    const router = useRouter();

    const selectedInterviewId = ref<number>(0);

    const candidate = ref<CandidateDetailModel>({});

    const jobPostingId = ref<any>(Number(route.params["jobpostingId"]));
    const candidateId = ref<any>(Number(route.params["candidateId"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const candidateInterviewList = ref<CandidateInterviewModel[]>([]);
    const candidateInterviewResponse = ref<CandidateInterviewResponsesModel>({
      interviewQuestions: [],
    });

    const candidateInterviewLogs = ref<CandidateInterviewLogsModel[]>([]);

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    let checkCvAnalysisInterval;

    const startInterval = () => {
      checkCvAnalysisInterval = setInterval(() => {
        if (
          candidateId.value === candidate.value.id &&
          candidate.value &&
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ==
            CandidateCvAnalysisStatus.IN_PROGRESS
        ) {
          isCvAnalysisLoading.value = true;
          const candidateDetailModel: CandidateDetailModel = {
            id: candidateId.value,
          };

          interviewerController
            .candidateDetail(candidateDetailModel)
            .then((response) => {
              if (response.isSuccess && response.getValue().CvAnalysisScore) {
                isCvAnalysisLoading.value = false;
                clearInterval(checkCvAnalysisInterval);
                getCandidate();
              }
            })
            .catch((e) => {
              isCvAnalysisLoading.value = false;
              clearInterval(checkCvAnalysisInterval);
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        } else if (
          (candidate.value.CvAnalysisScore == undefined &&
            candidate.value.CvAnalysisStatus == undefined) ||
          (candidate.value.CvAnalysisScore &&
            candidate.value.CvAnalysisStatus !=
              CandidateCvAnalysisStatus.IN_PROGRESS)
        ) {
          clearInterval(checkCvAnalysisInterval);
        }
      }, 2000);
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateInterviewResponse = () => {
      candidateInterviewResponse.value = {
        interviewQuestions: [],
      };

      if (selectedInterviewId.value == 0) {
        return;
      }

      const candidateInterviewResponsesModel: CandidateInterviewResponsesModel =
        {
          interviewQuestions: [],
          interview: selectedInterviewId.value,
          candidate: candidateId.value,
        };

      interviewerController
        .candidateInterviewResponses(candidateInterviewResponsesModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(
              candidateInterviewResponse.value,
              response.getValue()
            );
          } else {
            candidateInterviewResponse.value = { interviewQuestions: [] };
            // router.push({ path: "/interviewer/jobposting-list" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateInterviewLogs = () => {
      candidateInterviewLogs.value = [];

      if (selectedInterviewId.value == 0) {
        return;
      }

      // DÜZENLENECEK

      let candidateInterviewLogModel: CandidateInterviewLogsModel = {
        interview: selectedInterviewId.value,
        candidate: candidateId.value,
        message: {},
      };

      interviewerController
        .candidateInterviewLogs(candidateInterviewLogModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(candidateInterviewLogs.value, response.getValue());
          } else {
            candidateInterviewLogs.value = [];
          }
          isCandidateInterviewDetailLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          isCandidateInterviewDetailLoading.value = false;
        });
    };

    const getCandidate = () => {
      isCandidateLoading.value = false;

      const candidateDetailModel: CandidateDetailModel = {
        id: candidateId.value,
      };

      isCandidateLoading.value = true;

      interviewerController
        .candidateDetail(candidateDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(candidate.value, response.getValue());
            if (candidate.value.jobPostingId != jobPostingId.value) {
              router.push({ name: "500" });
            }
            Object.assign(
              candidateInterviewList.value,
              response.getValue().interviews
            );
            isCandidateLoading.value = false;
            isCandidateInterviewDetailLoading.value = true;
            getCandidateInterviewResponse();
            getCandidateInterviewLogs();
          } else {
            router.push({ path: "/interviewer/jobposting-list" });
            showError(response.error);
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const showError = (error) => {
      const errorMessage = `${error.cause?.cause || ""} ${t(
        SWAL_MESSAGES.ERROR.ERROR_CODE
      )} ${error.code || ""} ${error.cause?.code || ""}`;
      swalNotification.error(
        errorMessage,
        t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      );
    };

    const updateCandidateDetails = async () => {
      try {
        const candidateDetailModel = { id: candidateId.value };
        const response = await interviewerController.candidateDetail(
          candidateDetailModel
        );

        if (response.isSuccess) {
          Object.assign(candidate.value, response.getValue());
          Object.assign(
            candidateInterviewList.value,
            response.getValue().interviews
          );
        } else {
          router.push({ path: "/interviewer/jobposting-list" });
          showError(response.error);
        }
      } catch (error) {
        showError(error);
      }
    };

    const pollCvAnalysisStatus = () => {
      const intervalId = setInterval(async () => {
        if (!candidate.value) return clearInterval(intervalId);

        if (
          candidateId.value === candidate.value.id &&
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.IN_PROGRESS &&
          candidateId.value == candidate.value.id
        ) {
          try {
            const candidateDetailModel = { id: candidateId.value };
            const response = await interviewerController.candidateDetail(
              candidateDetailModel
            );

            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(intervalId);
              Object.assign(candidate.value, response.getValue());
              Object.assign(
                candidateInterviewList.value,
                response.getValue().interviews
              );
            }
          } catch (error) {
            isCvAnalysisLoading.value = false;
            clearInterval(intervalId);
            showError(error);
          }
        } else if (
          candidate.value.CvAnalysisScore !== undefined &&
          (candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ===
              CandidateCvAnalysisStatus.FAILED)
        ) {
          clearInterval(intervalId);
        }
      }, 2000);
    };

    const reloadCVAnalysis = async () => {
      const candidateCvAnalysisModel = { candidate: candidateId.value };

      const swalText = jobPosting.value.hasCvAnalysis
        ? t("swalMessages.CANNOT_BE_UNDONE")
        : t("swalMessages.CANNOT_BE_UPDATE_JOBPOSTING");

      const confirmation = await Swal.fire({
        title: t("swalMessages.CANDIDATE_CV_ANALYSIS"),
        text: swalText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      });

      if (!confirmation.isConfirmed) return;

      try {
        const response = await interviewerController.candidateCvAnalysis(
          candidateCvAnalysisModel
        );

        if (!response.isSuccess) {
          return showError(response.error);
        }

        isCvAnalysisLoading.value = true;

        await Swal.fire({
          title: t("swalMessages.SUCCESSFUL"),
          text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CV_ANALYSIS),
          icon: "success",
        });

        await updateCandidateDetails();
        pollCvAnalysisStatus();
      } catch (error) {
        showError(error);
      }
    };

    watch(
      () => route.params["candidateId"],
      () => {
        if (route.params["candidateId"])
      {
          candidateId.value = Number(route.params["candidateId"]);
        getCandidate();
      }
      }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
      getCandidate();
      startInterval();
    });

    watch(
      () => candidate.value.id,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          clearInterval(checkCvAnalysisInterval);
          startInterval();
        }
      }
    );

    watch(
      () => selectedInterviewId.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          isCandidateInterviewDetailLoading.value = true;
          getCandidateInterviewResponse();
          getCandidateInterviewLogs();
        }
      }
    );

    return {
      InterviewCandidateStatus,
      isLoading,
      jobPostingId,
      features,
      today,
      startDate,
      endDate,
      jobPosting,
      jobPostingInterviewCount,
      JobPostingStatus,
      InterviewCandidateStatusClass,
      candidate,
      isCandidateLoading,
      isCvAnalysisLoading,
      reloadCVAnalysis,
      selectedInterviewId,
      getCandidateInterviewResponse,
      candidateInterviewResponse,
      candidateInterviewLogs,
      isCandidateInterviewDetailLoading,
    };
  },
});
