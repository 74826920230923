import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import ProductListService from "../service/ProductListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IProductListRepository } from "@/domain/product/product-list/repository/IProductListRepository";
import { ProductListRequestDto } from "@/domain/product/product-list/dto/ProductListRequestDto";
import { ProductListResponseDto } from "@/domain/product/product-list/dto/ProductListResponseDto";

const productListService = new ProductListService();

export class ProductListRepository implements IProductListRepository {
  async productList(
    request: ProductListRequestDto
  ): Promise<Result<ProductListResponseDto>> {
    try {
      return await productListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<ProductListResponseDto>(response.getValue());
          } else {
            return Result.fail<ProductListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<ProductListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
