import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { AddQuestionModel } from "@/domain/interviewer/update-interview/addQuestion/model/AddQuestionModel";
import { AddQuestionRequestDto } from "@/domain/interviewer/update-interview/addQuestion/dto/AddQuestionRequestDto";

export class AddQuestionMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AddQuestionModel> {
    try {
      const addQuestionModel: AddQuestionModel = {
        ok: dto.ok,
      };

      return Result.ok(addQuestionModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_INTERVIEW_QUESTION))
      );
    }
  }

  mapToDTO(domain: AddQuestionModel): Result<AddQuestionRequestDto> {
    try {

      const addQuestionRequest = new AddQuestionRequestDto(
        domain.interview,
        domain.questionText,
        domain.questionType,
        domain.interviewQuestionOptions,
      );

      return Result.ok(addQuestionRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_INTERVIEW_QUESTION))
      );
    }
  }
}
