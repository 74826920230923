import { BaseDomain } from "@/core/base/BaseDomain";

export class JobPostingDetailByInviteModel extends BaseDomain {
  key?: string;
  jobPostingTitle?: string;
  interviewTitle?: string;
  interviewQuestionCount?: number;

  constructor(
    key?: string,
    jobPostingTitle?: string,
    interviewTitle?: string,
    interviewQuestionCount?: number,
  ) {
    super();
    this.key = key;
    this.jobPostingTitle = jobPostingTitle;
    this.interviewTitle = interviewTitle;
    this.interviewQuestionCount = interviewQuestionCount;
  }
}
