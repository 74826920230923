<template>
  <div
    v-if="isLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>
  <div v-else class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-3">
      <!--begin::Contact group wrapper-->
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <select
            name="status"
            data-control="select2"
            data-hide-search="true"
            class="form-select form-select-md form-select-solid fw-bolder mb-4"
            @change="handleInterviewChange"
          >
            <option value="All">
              {{ $t("modals.interview.allInterviews") }}
            </option>
            <option
              v-for="(interview, index) in jobPosting.interviews"
              :key="index"
              :value="interview.id"
            >
              {{ interview.title }}
            </option>
          </select>
          <div class="card-title">
            <h2>{{ $t("modals.interview.interviewCount") }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <apexchart
              v-if="jobPostingInterviewCount.total != 0"
              class="statistics-widget-3-chart card-rounded-bottom"
              width="100%"
              height="300px"
              type="donut"
              :options="chartOptions"
              :series="series"
            ></apexchart>
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >{{ $t("modals.interview.totalInterviewCount") }}</a
              >
              <div class="badge badge-light-primary">
                {{ jobPostingInterviewCount.total }}
              </div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.completedInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[0] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.ongoingInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[1] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-danger text-hover-primary">{{
                $t("modals.interview.canceledInterview")
              }}</a>
              <div class="badge badge-light-danger">{{ series[2] }}</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
          <!--begin::Separator-->
          <div class="separator my-7"></div>
          <!--begin::Separator-->

          <div class="d-flex w-100 gap-3">
            <router-link
              to="/interviewer/interview-detail"
              class="
                btn btn-sm btn-primary btn-active-primary
                text-decoration-none
                w-100
                text-center
              "
            >
              {{ $t("common.button.review") }}
            </router-link>
          </div>

          <!-- <router-link to="/interviewer/choose-invite-type">
            <a class="btn btn-secondary w-100">
              <i class="ki-duotone ki-badge fs-2"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></i>
              Mülakat Detayları
            </a>
          </router-link> -->

          <!--begin::Add new contact-->
          <!-- <router-link to="/interviewer/choose-invite-type">
            <a class="btn btn-primary w-100">
              <i class="ki-duotone ki-badge fs-2"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></i>
              Mülakat Daveti Gönder
            </a>
          </router-link> -->
          <!--end::Add new contact-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->

      <div class="card card-flush mb-7">
        <span
          v-if="jobPosting.status"
          :class="JobPostingStatusBadge(jobPosting.status)"
        >
          {{
            jobPosting.status === JobPostingStatus.ACTIVE
              ? $t("common.active")
              : jobPosting.status === JobPostingStatus.PENDING
              ? $t("common.waiting")
              : jobPosting.status === JobPostingStatus.COMPLETED
              ? $t("common.completed")
              : $t("common.inactive")
          }}
        </span>
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>{{ jobPosting.title }}</h2>
          </div>
          <div
            v-if="
              jobPosting.status !== JobPostingStatus.COMPLETED &&
              (jobPosting.status == JobPostingStatus.PENDING ||
                (!jobPosting.hasCvAnalysis &&
                  !jobPosting.hasCompletedInterviewSession))
            "
          >
            <button
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots fs-3"></i>
            </button>
            <JobPostingDropdown :jobPostingId="jobPostingId" />
          </div>

          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-1">
          <div class="fw-bold text-gray-600 mb-3">
            {{ formatLocalizedDate(jobPosting.startDate) }} -
            {{ formatLocalizedDate(jobPosting.endDate) }}
          </div>

          <!--begin::Contact group-->
          <div class="fw-bold text-gray-600 mb-3">
            <a
              data-bs-toggle="modal"
              :data-bs-target="'#descriptionModal' + jobPosting.id"
              class="
                text-primary text-decoration-underline
                fw-bold
                cursor-pointer
              "
            >
              {{ $t("modals.interview.viewDetail") }}
            </a>
            <!-- <div
                    v-html="jobPosting.description"
                    class="description-text"
                  ></div> -->
          </div>

          <!--begin::Modal-->
          <div
            class="modal fade"
            :id="'descriptionModal' + jobPosting.id"
            tabindex="-1"
            aria-labelledby="descriptionModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="descriptionModalLabel">
                    {{ $t("modals.interview.jobPostingDesc") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body mh-500px overflow-auto">
                  <div v-html="jobPosting.description"></div>

                  <div class="separator my-4"></div>

                  <div
                    v-for="(
                      qualification, i
                    ) in jobPosting.jobPostingQualifications"
                    :key="i"
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    {{ qualification.text }}
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("common.button.close") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal-->

          <div class="fw-bold text-gray-600 mb-3">
            {{ $t("modals.interview.candidateCount") }}:
            {{ jobPosting.candidateCount }}
          </div>

          <div class="d-flex flex-column text-gray-600 pb-5">
            <div
              v-for="(qualification, i) in visibleRequirements(
                jobPosting.jobPostingQualifications
              )"
              :key="i"
              class="d-flex align-items-center py-2"
            >
              <span class="bullet bg-primary me-3"></span>
              {{ qualification.text }}
            </div>

            <div
              v-if="
                jobPosting.jobPostingQualifications &&
                jobPosting.jobPostingQualifications.length > 3
              "
              class="d-flex align-items-center py-2"
            >
              <span class="bullet bg-primary me-3"></span>
              <em
                >{{
                  hiddenRequirementCount(jobPosting.jobPostingQualifications)
                }}
                {{ $t("modals.interview.morePieces") }}</em
              >
            </div>
          </div>

          <!--begin::Contact group-->

          <div class="d-flex flex-wrap gap-2 mb-3" v-if="jobPosting.interviews">
            <!-- Görünen mülakat öğeleri -->
            <div
              v-for="(item, index) in visibleItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; min-width: 150px"
            >
              <router-link :to="'/interviewer/interview-detail/' + item.id">
                <span class="fs-6 fw-semibold text-muted lh-1"
                  >{{ item.title }}
                </span>
              </router-link>
            </div>

            <!-- +X butonu -->
            <div
              v-if="hiddenItemCount(jobPosting.interviews) > 0 && !showAll"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; cursor: pointer; min-width: 55px"
              @click="showAllInterviews"
            >
              <span class="fs-6 fw-semibold text-muted lh-1"
                >+{{ hiddenItemCount(jobPosting.interviews) }}</span
              >
            </div>

            <!-- Gizli mülakatlar gösterildiğinde -->
            <div
              v-if="showAll"
              v-for="(item, index) in hiddenItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; min-width: 150px"
            >
              <router-link :to="'/interviewer/interview-detail/' + item.id">
                <span class="fs-6 fw-semibold text-muted lh-1">{{
                  item.title
                }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
    </div>
    <!--end::Contact groups-->

    <!--begin::Search-->
    <div class="col-lg-6 col-xl-3">
      <!--begin::Contacts-->
      <div class="card card-flush" id="kt_contacts_list">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_contacts_list_header">
          <!--begin::Form-->
          <form
            class="d-flex align-items-center position-relative w-100 m-0"
            autocomplete="off"
          >
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid ps-13"
              name="search"
              value=""
              placeholder="Aday Ara"
            />
            <!--end::Input-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div
          v-if="isCandidatesLoading"
          style="
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-25px"
              src="/media/loading_icon.gif"
              style="width: 35px"
            />
          </div>
        </div>

        <div
          class="card-body pt-0"
          id="kt_contacts_list_body"
          v-else-if="candidateList.length > 0"
        >
          <table
            class="
              table table-row-dashed table-row-gray-300
              align-middle
              gs-0
              gy-4
            "
          >
            <thead>
              <tr class="fw-bolder text-muted text-center">
                <div
                  class="fw-bold fs-6 mt-3"
                  style="
                    cursor: pointer;
                    color: #000;
                    display: inline-flex;
                    align-items: center;
                  "
                  @click="toggleArrow"
                >
                  {{ $t("modals.interview.candidate.positionEligibility") }}
                  <!-- Dinamik olarak ok yönünü değiştiren SVG iconlar -->
                  <span
                    class="svg-icon svg-icon-3 svg-icon-danger"
                    v-if="!arrowDirection"
                    style="margin-left: 5px"
                  >
                    <inline-svg src="/media/icons/duotune/arrows/arr065.svg" />
                  </span>
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success"
                    v-else
                    style="margin-left: 5px"
                  >
                    <inline-svg src="/media/icons/duotune/arrows/arr066.svg" />
                  </span>
                </div>
              </tr>
            </thead>
          </table>

          <!--begin::List-->
          <div
            class="scroll-y me-n5 h-300px h-xl-auto"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header"
            data-kt-scroll-wrappers="#kt_content, #kt_contacts_list_body"
            data-kt-scroll-stretch="#kt_contacts_list, #kt_contacts_main"
            data-kt-scroll-offset="5px"
            style="max-height: 1250px"
          >
            <div
              v-if="candidateList.length > 0"
              v-for="(candidate, index) in candidateList"
              :key="index"
            >
              <!--begin::User-->
              <router-link
                :key="index"
                :to="
                  '/interviewer/jobposting-detail/' +
                  jobPostingId +
                  '/candidate/' +
                  candidate.candidate.candidateId
                "
              >
                <div
                  class="d-flex py-4 contact-item"
                  style="display: table; width: 100%"
                >
                  <!--begin::Details (Aday Bilgileri)-->
                  <div
                    class="d-flex align-items-center"
                    style="display: table-cell; width: 80%"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-40px symbol-circle">
                      <span
                        v-if="candidate.candidate.firstName"
                        class="symbol-label fs-6 ms-2 fw-bolder"
                        :class="
                          InterviewCandidateStatusClass(
                            candidate.candidate.candidateStatus
                          )
                        "
                        >{{ candidate.candidate.firstName[0] }}</span
                      >
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Details-->
                    <div class="ms-3">
                      <a
                        class="
                          fs-6
                          fw-bold
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >{{ candidate.candidate.fullName }}</a
                      >
                      <div class="fw-semibold fs-7 text-muted">
                        {{ candidate.candidate.email }}
                      </div>
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--begin::Puan (Puan Durumu)-->
                  <div
                    style="display: table-cell; width: 20%; text-align: right"
                    v-if="candidate.candidate.positionSuitability"
                  >
                    <span
                      :class="[
                        'badge',
                        positionSuitabilityClass(
                          candidate.candidate.positionSuitability
                        ),
                        'me-1',
                      ]"
                    >
                      {{ candidate.candidate.positionSuitability }}%
                    </span>
                  </div>
                  <!--end::Puan-->
                </div>
              </router-link>
              <!--end::User-->

              <!--begin::Separator-->
              <div class="separator separator-dashed d-none"></div>
              <!--end::Separator-->
            </div>
          </div>
          <!--end::List-->
        </div>
        <div class="text-center" v-else>
          <div class="card-body">
            <p class="fs-7 fw-bold mb-4">
              {{ $t("modals.interview.candidate.zeroCount") }}
            </p>

            <!-- Buton -->
            <router-link
              :to="'/interviewer/choose-invite-type/' + jobPosting.id"
            >
              <button class="btn btn-sm btn-primary btn-active-primary">
                {{ $t("modals.interview.inviteCandidate") }}
              </button>
            </router-link>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->
    </div>
    <!--end::Search-->

    <!--begin::Content-->
    <router-view></router-view>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { VueEditor } from "vue3-editor";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import JobPostingDropdown from "@/presentation/components/dropdowns/JobPostingDropdown.vue";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import {
  InterviewCandidateStatusClass,
  JobPostingStatusBadge,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "jobposting-detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
    JobPostingDropdown,
  },
  methods: {
    positionSuitabilityClass(value) {
      if (value >= 85) {
        return "badge-success";
      } else if (value >= 45) {
        return "badge-warning";
      } else {
        return "badge-danger";
      }
    },
  },
  computed: {},
  setup() {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const store = useStore();
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();

    const jobPostingId = ref<any>(Number(route.params["jobpostingId"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const candidateList = ref<CandidateListModel[]>([]);
    const originalCandidateList = ref<CandidateListModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(50);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const sortPositionSuitability = ref("positionSuitability:asc");

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const arrowDirection = ref<boolean>(false); // false = 'down', true = 'up'

    const toggleArrow = () => {
      sortPositionSuitability.value =
        sortPositionSuitability.value === "positionSuitability:asc"
          ? "positionSuitability:desc"
          : "positionSuitability:asc";
      arrowDirection.value = !arrowDirection.value;
      getCandidateList(1);
    };
    
    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = computed(() => {
      const completed = Number(jobPostingInterviewCount.value?.completed) ?? 0;
      const inProgress =
        Number(jobPostingInterviewCount.value?.inProgress) ?? 0;
      const failed = Number(jobPostingInterviewCount.value?.failed) ?? 0;

      return [completed, inProgress, failed];
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });


    const getJobPostingInterviewCounts = (interviewId) => {
      isLoading.value = true;
      const jobPostingInterviewCountModel: InterviewCountModel = {};
      if (!interviewId || interviewId === "All") {
        jobPostingInterviewCountModel.jobPostingId = jobPostingId.value;
      } else {
        jobPostingInterviewCountModel.interviewId = Number(interviewId);
      }

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: response.getValue().total,
              completed: response.getValue().completed,
              inProgress: response.getValue().inProgress,
              failed: response.getValue().failed,
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleInterviewChange = (event) => {
      const selectedInterviewId = event.target.value;
      getJobPostingInterviewCounts(selectedInterviewId);
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts(null);
      getCandidateList(1);
      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListModel = {
        candidate: {
          jobPostingId: jobPostingId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
        sort: sortPositionSuitability.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateList(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const goToCandidateDetail = (candidateId: number) => {
      router.push({
        path:
          "/interviewer/jobposting-detail/" +
          jobPostingId.value +
          "/candidate/" +
          candidateId,
      });
    };

    watch(
      () => store.getters.changedAnyJobPosting,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getJobPosting();
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      isLoading,
      isCandidatesLoading,
      jobPostingId,
      positionName,
      featureInput,
      features,
      positionDesc,
      chartOptions,
      series,
      arrowDirection,
      today,
      startDate,
      endDate,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      jobPostingInterviewCount,
      getJobPostingInterviewCounts,
      handleInterviewChange,
      JobPostingStatus,
      JobPostingStatusBadge,
      candidateList,
      goToCandidateDetail,
      InterviewCandidateStatusClass,
      toggleArrow,
    };
  },
});
</script>

<style>
.contact-item:hover {
  background-color: #f8f0f0; /* Gri renk */
  cursor: pointer; /* Tıklanabilir işaretçi */
}

/* İsteğe bağlı olarak hover'da font rengini değiştirme */
.contact-item:hover .fs-6 {
  color: #007bff; /* Örneğin, mavi renk */
}

.question-card {
  border: 1px solid #e9e9e9;
  padding: 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #fafafa;
}

.question-title {
  font-weight: bold;
  font-size: 1rem;
  color: #6c757d;
}

.question-answer {
  font-size: 0.875rem;
  color: #495057;
  margin-top: 10px;
}

/* Grid Layout */
.d-grid {
  display: grid;
  gap: 16px;
}

.grid-columns-4 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.interview-card {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  box-sizing: border-box;
  transition: none; /* Geçiş animasyonunu iptal et */
}

/* Sürükleme Sırasında Stil */
.dragging {
  opacity: 0.5;
}

.drag-over {
  outline: 2px dashed #007bff; /* Border yerine outline kullanıldı */
  background-color: #e2e6ea;
}

/* Kart içerisindeki içerik */
.interview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Sıra İbaresi */
.interview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
