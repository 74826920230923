import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import UpdateJobPostingStatusService from "../service/UpdateJobPostingStatusService";
import { IUpdateJobPostingStatusRepository } from "@/domain/interviewer/update-job-posting-status/repository/IUpdateJobPostingStatusRepository";
import { UpdateJobPostingStatusRequestDto } from "@/domain/interviewer/update-job-posting-status/dto/UpdateJobPostingStatusRequestDto";

const updateJobPostingStatusService = new UpdateJobPostingStatusService();

export class UpdateJobPostingStatusRepository implements IUpdateJobPostingStatusRepository {
  async updateJobPostingStatus(request: UpdateJobPostingStatusRequestDto): Promise<Result<any>> {
    try {
      return await updateJobPostingStatusService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.UPDATE_JOBPOSTING)));
    }
  }
}
