import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import PollListService from "../service/PollListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IPollListRepository } from "@/domain/pollster/poll-list/repository/IPollListRepository";
import { PollListRequestDto } from "@/domain/pollster/poll-list/dto/PollListRequestDto";
import { PollListResponseDto } from "@/domain/pollster/poll-list/dto/PollListResponseDto";

const pollListService = new PollListService();

export class PollListRepository implements IPollListRepository {
  async pollList(
    request: PollListRequestDto
  ): Promise<Result<PollListResponseDto>> {
    try {
      return await pollListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<PollListResponseDto>(response.getValue());
          } else {
            return Result.fail<PollListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<PollListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.POLL_LIST))
      );
    }
  }
}
