import { BaseDomain } from "@/core/base/BaseDomain";
import { SurveyQuestionsModel } from "./SurveyQuestionsModel";

export class CreatePollModel extends BaseDomain {
  surveyQuestions: SurveyQuestionsModel[];
  title?: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  ok?: boolean;
  isPrivate?: boolean

  constructor(
    surveyQuestions: SurveyQuestionsModel[],
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    ok?: boolean,
    isPrivate?: boolean
  ) {
    super();
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.surveyQuestions = surveyQuestions;
    this.ok = ok;
    this.isPrivate = isPrivate;
  }
}
