import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CandidateInterviewResponsesRequestDto } from "@/domain/interviewer/candidate-interview-responses/dto/CandidateInterviewResponsesRequestDto";
import { CandidateInterviewResponsesModel } from "@/domain/interviewer/candidate-interview-responses/model/CandidateInterviewResponsesModel";
import { CandidateInterviewResponsesResponseDto } from "@/domain/interviewer/candidate-interview-responses/dto/CandidateInterviewResponsesResponseDto";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";

export class CandidateInterviewResponsesMapper
  implements IMapper<BaseDomain, BaseDTO>
{
  mapToDomain(
    dto: CandidateInterviewResponsesResponseDto
  ): Result<CandidateInterviewResponsesModel> {
    try {
      if (dto.data) {
        const interviewQuestions: InterviewQuestionModel[] = [];

        dto.data.forEach((item) => {
          if (item.attributes) {
            const questionAttributes = item.attributes;
            const id = item.id;
            const questionText = questionAttributes.questionText;
            const questionType = questionAttributes.questionType;

            const interviewQuestionOptions: {
              id: number;
              optionText: string;
            }[] =
              questionAttributes.interviewQuestionOptions?.data
                .filter((option) => option.id !== undefined)
                .map((option) => ({
                  id: option.id as number,
                  optionText: option.attributes.optionText,
                })) || [];

            let interviewQuestionResponse: string | undefined = undefined;
            if (
              questionAttributes.interviewQuestionResponse.data?.attributes
                .responseText
            ) {
              interviewQuestionResponse =
                questionAttributes.interviewQuestionResponse.data?.attributes
                  .responseText;
            } else if (
              questionAttributes.interviewQuestionResponse.data?.attributes
                .singleChoice.data &&
              questionAttributes.interviewQuestionResponse.data?.attributes
                .singleChoice.data.attributes.optionText
            ) {
              interviewQuestionResponse =
                questionAttributes.interviewQuestionResponse.data?.attributes.singleChoice.data.attributes.optionText.toString();
            } else if (
              questionAttributes.interviewQuestionResponse.data?.attributes
                .multipleChoices &&
              questionAttributes.interviewQuestionResponse.data?.attributes
                .multipleChoices?.data.length > 0
            ) {
              interviewQuestionResponse =
                questionAttributes.interviewQuestionResponse.data?.attributes.multipleChoices.data
                  .map((choice) => choice.attributes.optionText)
                  .join(", ");
            }

            if (questionText && questionType) {
              interviewQuestions.push(
                new InterviewQuestionModel(
                  interviewQuestionOptions,
                  id,
                  questionText,
                  questionType,
                  interviewQuestionResponse
                )
              );
            }
          }
        });

        const candidateInterviewResponsesModel =
          new CandidateInterviewResponsesModel(interviewQuestions);

        return Result.ok(candidateInterviewResponsesModel);
      } else {
        const candidateInterviewResponsesModel: CandidateInterviewResponsesModel =
          {
            interviewQuestions: [],
          };
        return Result.ok(candidateInterviewResponsesModel);
      }
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_INTERVIEW_RESPONSES)
        )
      );
    }
  }

  mapToDTO(
    domain: CandidateInterviewResponsesModel
  ): Result<CandidateInterviewResponsesRequestDto> {
    try {
      const candidateCvAnalysisRequest =
        new CandidateInterviewResponsesRequestDto(
          domain.candidate,
          domain.interview
        );

      return Result.ok(candidateCvAnalysisRequest);
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_INTERVIEW_RESPONSES)
        )
      );
    }
  }
}
