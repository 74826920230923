
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { environment } from "../../../../../../environment";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { CandidateActionListModel } from "@/domain/interviewer/candidate-action-list/model/CandidateActionListModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { CandidateActionStatus } from "@/domain/constants/CandidateActionStatus";
import { CandidateInterviewModel } from "@/domain/interviewer/candidate-detail/model/CandidateInterviewModel";
import { CandidatesInterviewStatus } from "@/domain/constants/CandidatesInterviewStatus";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import {
  CandidateActionBadge,
  CandidatesInterviewStatusBadge,
  CandidatesInterviewStatusBorderColor,
  CandidatesInterviewStatusText,
  InterviewCandidateStatusClass,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatFullDateWithTime,
} from "@/presentation/helper/DateFormatter";
import { CandidateCvAnalysisStatus } from "@/domain/constants/CandidateCvAnalysisStatus";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "candidate-detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    CandidateDropdown,
  },
  data() {
    return {
      today: dateToIsoDate(new Date()), // YYYY-MM-DD formatında bugünün tarihi
    };
  },
  methods: {
    goToJobpostingDetail(jobpostingId, candidateId) {
      this.$router.push(
        "/interviewer/jobposting-detail/" +
          jobpostingId +
          "/candidate/" +
          candidateId
      );
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isCandidateLoading = ref<boolean>(false);
    const isCvAnalysisLoading = ref<boolean>(false);
    const isCandidateActionLoading = ref<boolean>(false);
      const isInvitingCandidateToInterview = ref<boolean>(false);
    const candidateId = ref<any>(Number(route.params["id"]));

    const startDate = ref("");
    const endDate = ref("");

    const jobPosting = ref<JobPostingDetailModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const candidate = ref<CandidateDetailModel>({});
    const candidateInvite = ref<InterviewInviteModel>({
      expirationDate: new Date(),
    });
    const revokeInterviewInviteModel= ref<RevokeInterviewInviteModel>({
    });

    const candidateActionList = ref<CandidateActionListModel[]>([]);
    const candidatesInterviewList = ref<CandidateInterviewModel[]>([]);
    const originalCandidateActionList = ref<CandidateActionListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      expirationDate: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
    });

    const checkCvAnalysisInterval = setInterval(() => {
      if (
        candidate.value &&
        candidate.value.CvAnalysisScore == null &&
        candidate.value.CvAnalysisStatus ==
          CandidateCvAnalysisStatus.IN_PROGRESS
      ) {
        isCvAnalysisLoading.value = true;
        const candidateDetailModel: CandidateDetailModel = {
          id: candidateId.value,
        };

        interviewerController
          .candidateDetail(candidateDetailModel)
          .then((response) => {
            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(checkCvAnalysisInterval);
              getCandidate();
            }
          })
          .catch((e) => {
            isCvAnalysisLoading.value = false;
            clearInterval(checkCvAnalysisInterval);
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      } else if (
        (candidate.value.CvAnalysisScore == undefined &&
          candidate.value.CvAnalysisStatus == undefined) ||
        (candidate.value.CvAnalysisScore &&
          (candidate.value.CvAnalysisStatus ==
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ==
              CandidateCvAnalysisStatus.FAILED))
      ) {
        clearInterval(checkCvAnalysisInterval);
      }
    }, 2000);

    const inviteCandidateToInterview = (interviewId?: number) => {
      candidateInvite.value.candidate = candidateId.value;
      candidateInvite.value.interview = interviewId;
      candidateInvite.value.locale = i18n.global.locale.value;

      if (isInvitingCandidateToInterview.value == true) {
        return;
      }

      isInvitingCandidateToInterview.value = true;

      Swal.fire({
        title: t("swalMessages.INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .interviewInvite(candidateInvite.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidate()
                  document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
              isInvitingCandidateToInterview.value = false;
              
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const getCandidate = () => {
      isCandidateLoading.value = false;

      const candidateDetailModel: CandidateDetailModel = {
        id: candidateId.value,
      };

      isCandidateLoading.value = true;

      interviewerController
        .candidateDetail(candidateDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(candidate.value, response.getValue());
            Object.assign(
              candidatesInterviewList.value,
              response.getValue().interviews
            );
            getCandidateActionList(1);
            getJobPosting(candidate.value.jobPostingId);
            isCandidateLoading.value = false;
          } else {
            router.push({ path: "/interviewer/jobposting-list" });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateActionList = (page) => {
      candidateActionList.value = [];
      originalCandidateActionList.value = [];

      isCandidateActionLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const candidateActionListModel: CandidateActionListModel = {
        candidateAction: {
          candidateId: candidateId.value,
        },
      };

      interviewerController
        .getCandidateActionList(candidateActionListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateAction) => {
              totalItems.value = candidateAction.pagination.total;
              currentPage.value = candidateAction.pagination.page;
              pageCount.value = candidateAction.pagination.pageCount;
              candidateActionList.value.push(candidateAction);
              originalCandidateActionList.value.push(candidateAction);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidateActionLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = (jobPostingId) => {
      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId,
      };

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const downloadCV = () => {
      let cvLink = candidate.value.cvUrl;
      const fullUrl = environment.baseUploadUrl + cvLink;
      const link = document.createElement("a");
      link.href = fullUrl;

      if (cvLink) {
        const fileName = fullUrl.split("/").pop();
        if (fileName) {
          link.download = fileName;
          link.click();
        } else {
          swalNotification.error(
            t("validators_error.requiredFileName"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      }
    };

    const showError = (error) => {
      const errorMessage = `${error.cause?.cause || ""} ${t(
        SWAL_MESSAGES.ERROR.ERROR_CODE
      )} ${error.code || ""} ${error.cause?.code || ""}`;
      swalNotification.error(
        errorMessage,
        t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      );
    };

    const updateCandidateDetails = async () => {
      try {
        const candidateDetailModel = { id: candidateId.value };
        const response = await interviewerController.candidateDetail(
          candidateDetailModel
        );

        if (response.isSuccess) {
          Object.assign(candidate.value, response.getValue());
          Object.assign(
            candidatesInterviewList.value,
            response.getValue().interviews
          );
        } else {
          router.push({ path: "/interviewer/jobposting-list" });
          showError(response.error);
        }
      } catch (error) {
        showError(error);
      }
    };

    const pollCvAnalysisStatus = () => {
      const intervalId = setInterval(async () => {
        if (!candidate.value) return clearInterval(intervalId);

        if (
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.IN_PROGRESS
        ) {
          try {
            const candidateDetailModel = { id: candidateId.value };
            const response = await interviewerController.candidateDetail(
              candidateDetailModel
            );

            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(intervalId);
              Object.assign(candidate.value, response.getValue());
              Object.assign(
                candidatesInterviewList.value,
                response.getValue().interviews
              );
            }
          } catch (error) {
            isCvAnalysisLoading.value = false;
            clearInterval(intervalId);
            showError(error);
          }
        } else if (
          candidate.value.CvAnalysisScore !== undefined &&
          (candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ===
              CandidateCvAnalysisStatus.FAILED)
        ) {
          clearInterval(intervalId);
        }
      }, 2000);
    };

    const reloadCVAnalysis = async () => {
      const candidateCvAnalysisModel = { candidate: candidateId.value };

      const swalText = jobPosting.value.hasCvAnalysis
        ? t("swalMessages.CANNOT_BE_UNDONE")
        : t("swalMessages.CANNOT_BE_UPDATE_JOBPOSTING");

      const confirmation = await Swal.fire({
        title: t("swalMessages.CANDIDATE_CV_ANALYSIS"),
        text: swalText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      });

      if (!confirmation.isConfirmed) return;

      try {
        const response = await interviewerController.candidateCvAnalysis(
          candidateCvAnalysisModel
        );

        if (!response.isSuccess) {
          return showError(response.error);
        }

        isCvAnalysisLoading.value = true;

        await Swal.fire({
          title: t("swalMessages.SUCCESSFUL"),
          text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CV_ANALYSIS),
          icon: "success",
        });

        await updateCandidateDetails();
        pollCvAnalysisStatus();
      } catch (error) {
        showError(error);
      }
    };

    const revokeInterviewInvite = (
      candidateId?: number,
      interviewId?: number
    ) => {

      revokeInterviewInviteModel.value.candidate = candidateId;
      revokeInterviewInviteModel.value.interview = interviewId;

      Swal.fire({
        title: t("swalMessages.REVOKE_INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .revokeInterviewInvite(revokeInterviewInviteModel.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_REVOKE_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidate()
                  document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    watch(
      () => store.getters.changedAnyCandidate,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getCandidate();
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getCandidate();
    });

    return {
      CandidateActionStatus,
      InterviewCandidateStatus,
      CandidatesInterviewStatus,
      inviteCandidateToInterview,
      revokeInterviewInvite,
      getCandidateActionList,
      isCandidateLoading,
      isCvAnalysisLoading,
      isCandidateActionLoading,
      isInvitingCandidateToInterview,
      candidateId,
      candidate,
      candidateActionList,
      candidatesInterviewList,
      downloadCV,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
      CandidateActionBadge,
      CandidatesInterviewStatusText,
      CandidatesInterviewStatusBadge,
      CandidatesInterviewStatusBorderColor,
      InterviewCandidateStatusClass,
      formatFullDateWithTime,
      form,
      candidateInvite,
      reloadCVAnalysis,
      jobPosting,
    };
  },
});
