
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import striptags from "striptags";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import { UpdateJobPostingStatusModel } from "@/domain/interviewer/update-job-posting-status/model/UpdateJobPostingStatusModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { useRouter } from "vue-router";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "jobposting-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    jobPostingId: Number,
  },
  setup(props) {
    const router = useRouter();
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const jobPosting = ref<JobPostingDetailModel>({});
    const isChanged = ref<boolean>(false);

    const interviews = ref();
    const interviewSequence = ref<InterviewSequenceModel[]>();

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      //       description: Yup.string()
      // .test(
      //   "is-valid-description",
      //   t("validators_error.required"),
      //   (value) => striptags(value || "").trim() !== ""
      // )
      // .required(t("validators_error.required"))
      // .label("description"),
      startDate: Yup.date()
        .required(t("validators_error.required"))
        .label("startDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("endDate")
        .test(
          "is-greater",
          t("validators_error.startDateBiggerEndDate"),
          function (value) {
            const { startDate } = this.parent;
            return startDate
              ? new Date(value as Date) >= new Date(startDate as Date)
              : true;
          }
        ),
      //     features: Yup.array()
      // .of(Yup.string().required(t("validators_error.required")))
      // .min(3, t("validators_error.minFeatures", { min: 3 }))
      // .label("Features"),
    });

    const getJobPostingList = (page) => {
      isChanged.value = false;

      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: props.jobPostingId,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
            if (jobPosting.value.interviews)
              interviews.value = jobPosting.value.interviews;

            interviewSequence.value = interviews.value.map((interview, idx) => {
              return new InterviewSequenceModel(interview.id, idx + 1);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const copyJobPosting = () => {
      Swal.fire({
        title: t("swalMessages.COPY_JOBPOSTING"),
        text: t("swalMessages.COPY_JOBPOSTING_DESC"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.SUCCESSFUL"),
            text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    };

    // Status değiştirme işlemi
    const changeStatus = (jobPostingId?: number, status?: boolean) => {
      const updateJobPostingStatusModel: UpdateJobPostingStatusModel = {
        id: jobPostingId,
        isActive: status,
      };

      const titleKey = status
        ? "swalMessages.MAKE_ACTIVE_JOBPOSTING"
        : "swalMessages.MAKE_PASSIVE_JOBPOSTING";
      const descriptionKey = status
        ? "swalMessages.MAKE_ACTIVE_JOBPOSTING_DESC"
        : "swalMessages.MAKE_PASSIVE_JOBPOSTING_DESC";

      Swal.fire({
        title: t(titleKey),
        text: t(descriptionKey),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .updateJobPostingStatus(updateJobPostingStatusModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(
                    status === true
                      ? SWAL_MESSAGES.SUCCESS.SUCCESS_MAKE_ACTIVE_JOBPOSTING
                      : SWAL_MESSAGES.SUCCESS.SUCCESS_MAKE_PASSIVE_JOBPOSTING
                  ),
                  icon: "success",
                }).then(() => {
                  store.dispatch(
                    "changedAnyJobPosting",
                    store.getters.changedAnyJobPosting + 1
                  );
                  // window.location.reload();
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    // Silme işlemi
    const deleteJobPosting = (jobPostingId?: number) => {
      const jobPostingDeleteModel: DeleteJobPostingModel = {
        id: jobPostingId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_JOBPOSTING"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteJobPosting(jobPostingDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
                  icon: "success",
                }).then(() => {
                  store.dispatch(
                    "changedAnyJobPosting",
                    store.getters.changedAnyJobPosting + 1
                  );
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPosting = () => {
      const errors: string[] = [];

      if (
        !jobPosting.value.description ||
        striptags(jobPosting.value.description.trim()) === ""
      ) {
        errors.push(t("validators_error.requiredDesc"));
      }
      if (errors.length > 0) {
        Swal.fire({
          icon: "error",
          title: t("validators_error.title"),
          html: errors.join("<br />"),
        });
        return;
      } else {
        const jobPostingUpdateModel: UpdateJobPostingModel = {
          id: props.jobPostingId,
          title: jobPosting.value.title,
          description: jobPosting.value.description,
          startDate: new Date(startDate.value),
          endDate: new Date(endDate.value),
        };

        if (interviewSequence.value) {
          jobPostingUpdateModel.interviews = interviewSequence.value;
        }

        interviewerController
          .updateJobPosting(jobPostingUpdateModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                title: t("swalMessages.SUCCESSFUL"),
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_JOBPOSTING),
                icon: "success",
              }).then(() => {
                window.location.reload();
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const addQualification = () => {
      if (
        jobPosting.value.jobPostingQualifications &&
        jobPosting.value.jobPostingQualifications.length >= 20
      ) {
        swalNotification.error(
          t("validators_error.max20Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } 

      const addQualificationModel: AddQualificationModel = {
        id: props.jobPostingId,
        text: featureInput.value,
      };

      interviewerController
        .addQualification(addQualificationModel)
        .then((response) => {
          if (response.isSuccess) {
            featureInput.value = "";
            getJobPostingList(1);
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeQualification = (qualificationId?: number) => {
      if (
        jobPosting.value.jobPostingQualifications &&
        jobPosting.value.jobPostingQualifications.length <= 3
      ) {
        swalNotification.error(
          t("validators_error.min3Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } 
        const removeQualificationModel: RemoveQualificationModel = {
          jobPostingId: props.jobPostingId,
          qualificationId: qualificationId,
        };

        interviewerController
          .removeQualification(removeQualificationModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                title: t("swalMessages.SUCCESSFUL"),
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_REMOVE_QUALIFICATION),
                icon: "success",
              }).then(() => {
                getJobPostingList(1);
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });

    };

    const editorOptions = ref({
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Başlık seçenekleri
          ["bold", "italic", "underline", "strike"], // Metin stilleri
          [{ list: "ordered" }, { list: "bullet" }], // Liste türleri
          [{ script: "sub" }, { script: "super" }], // Alt ve üst simgeler
          [{ indent: "-1" }, { indent: "+1" }], // Girintiler
          [{ direction: "rtl" }], // Metin yönü
          [{ color: [] }, { background: [] }], // Renkler
          [{ align: [] }], // Hizalama
          ["link"], // Bağlantı
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    });

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
        interviewSequence.value = interviews.value.map((interview, idx) => {
          return new InterviewSequenceModel(interview.id, idx + 1);
        });
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    onMounted(() => {
      getJobPostingList(1);
    });

    return {
      today,
      startDate,
      endDate,
      editorOptions,
      copyJobPosting,
      changeStatus,
      deleteJobPosting,
      updateJobPosting,
      jobPosting,
      addQualification,
      removeQualification,
      featureInput,
      form,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
});
