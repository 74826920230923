<template>
  <div
    v-if="isLoading"
    style="
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-35px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>

  <div class="row g-6 g-xl-9">
    <div
      class="col-sm-6 col-xl-4"
      v-if="moduleList.length > 0"
      v-for="(module) in moduleList"
      :key="module.id"
    >
      <div class="card h-100">
        <div class="card-body d-flex flex-center flex-column p-9">
          <div class="d-flex align-items-center fw-bold">
            <div class="fw-bolder mt-2" style="padding-left: 0px">
              <a href="#" style="color: black; font-size: 18px">{{
                module.title
              }}</a>
            </div>
          </div>
          <div class="fw-bold text-gray-400 mb-1">{{ module.category }}</div>
          <div class="mb-5 mt-auto fs-6 justify-content-center flex-grow-1">
            <!--begin::Label-->
            <div class="fw-bold text-gray-600 text-center mt-4 fs-7">
              {{ module.desc }}
            </div>
            <!--end::Label-->
          </div>

          <!--begin::Info-->
          <div class="d-flex flex-center flex-wrap mb-5">
                        <!--begin::Stats-->
                        <div
              class="
                border border-dashed
                rounded
                min-w-100px
                py-3
                px-4
                mx-3
                mb-3
              ">
              <div class="fs-6 fw-bolder text-gray-700">
                {{ module.date.toLocaleDateString("tr-TR") }}
              </div>
              <div class="fw-bold text-gray-400">Başlangıç Tarihi</div>
            </div>
            <!--end::Stats-->
            <!--begin::Stats-->
            <div
              class="
                border border-dashed
                rounded
                min-w-100px
                py-3
                px-4
                mx-3
                mb-3
              "
            >
              <div class="fs-6 fw-bolder text-gray-700">
                {{ module.modalCount }}
              </div>
              <div class="fw-bold text-gray-400">Modül Sayısı</div>
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Info-->

          <div class="button-group d-flex justify-content-center">
            <div class="btn btn-sm btn-primary me-2" style="color: white">
              {{ $t("common.button.review") }}
            </div>
            <div class="btn btn-sm btn-warning me-2" style="color: white">
              {{ $t("common.button.edit") }}
            </div>
            <div class="btn btn-sm btn-danger" style="color: white">
              {{ $t("common.button.delete") }}
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>

  <!--begin::Pagination-->
  <div
    class="d-flex flex-stack flex-wrap pt-10"
    v-if="moduleList.length != 0 && moduleList.length >= 5"
  >
    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
      <!--begin::Select wrapper-->
      <div class="m-0">
        <!--begin::Select-->
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm fw-bolder w-125px"
          v-model="pageSize"
        >
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span>{{ $t("common.table.pagination.desc2") }}</span>

        <!--end::Select-->
      </div>
      <!--end::Select wrapper-->
    </div>
    <!--end::Controls-->

    <div class="fs-6 fw-bold text-gray-700">
      {{ totalItems }} {{ $t("common.table.pagination.desc") }}
      {{ (currentPage - 1) * pageSize + 1 }} -
      {{ Math.min(currentPage * pageSize, totalItems) }}
      {{ $t("common.table.pagination.desc1") }}
    </div>

    <!--begin::Pages-->
    <ul class="pagination">
      <li class="page-item previous" :class="{ disabled: currentPage === 1 }">
        <a href="#" class="page-link"><i class="previous"></i></a>
      </li>

      <li
        v-for="page in pageCount"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a href="#" class="page-link">{{ page }}</a>
      </li>

      <li
        class="page-item next"
        :class="{ disabled: currentPage === pageCount }"
      >
        <a href="#" class="page-link"><i class="next"></i></a>
      </li>
    </ul>
    <!--end::Pages-->
  </div>
  <!--end::Pagination-->

  <div v-else-if="moduleList.length == 0" class="card-px text-center">
    <div class="text-center px-5 pb-7">
      <img
        src="/media/animal/module.jpg"
        class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
      />
    </div>

    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-bold">
      <span>{{ $t("modalList.notFound") }}</span>
    </p>
    <!--end::Description-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
  import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "companies",
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const moduleList = ref<any[]>([
      {
        id: 1,
        title: "Ogzatech A.Ş.",
        category: "Yazılım Geliştirme",
        desc: "Şirketin kendi projeleri haricinde isteğe özel projeler de geliştirilmektedir. Mobil/Web/Windows uygulamalarının yanı sıra akademik danışman kadrosuyla yapay zeka, görüntü işleme, optimizasyon gibi ARGE projeleri de yapmaktadır.",
        modalCount: 8,
        date: new Date(),
      },
      {
        id: 2,
        title: "Yıldız Teknik Teknopark",
        category: "Üniversite - Araştırma Geliştirme",
        desc: "Yıldız Teknik Üniversitesi, ülkemizin ar-ge faaliyetlerine katkıda bulunan 21. yüzyılın girişimci üniversite modeli doğrultusunda teknoloji geliştirme bölgesi açma faaliyetlerine başlamış ve 2003 yılında Yıldız Teknopark’ı kurmuştur.",
        modalCount: 5,
        date: new Date(),
      },
      {
        id: 3,
        title: "Baykar Teknoloji",
        category: "Savunma Sanayi",
        desc: "Türkiye merkezli bir makine imalat ve savunma şirketidir. Baykar Makina ismiyle 1986 yılında Özdemir Bayraktar tarafından otomotiv sanayine yerlileştirmeye tabi hassas motor, pompa ve dişli kutusu parçalarının imalatı konusunda hizmet etme amacıyla kurulmuştur.",
        modalCount: 4,
        date: new Date(),
      },
      {
        id: 4,
        title: "Benq Lightning",
        category: "Teknoloji",
        desc: "BenQ, çocukların göz sağlığı için tasarlanmış konforlu aydınlatma çözümleri sunmaktadır. Sıfır doğrudan parlama, geniş ve eşit aydınlatma, daha geniş masalar için kompakt ışık başlığı ve otomatik parlaklık ayarı gibi özellikleriyle öne çıkmaktadır.",
        modalCount: 3,
        date: new Date(),
      },
      {
        id: 5,
        title: "Spotify",
        category: "Teknoloji",
        desc: "Spotify, 2006 yılında İsveç'te kurulan bir ses akışı ve medya hizmetleri sağlayıcısıdır. Şirket, 180'den fazla ülkede 456 milyon aylık aktif kullanıcıya ve 205 milyon ücretli aboneye sahiptir",
        modalCount: 2,
        date: new Date(),
      },
      {
        id: 6,
        title: "Malitur",
        category: "Turizm",
        desc: "Malitur Turizm Ticaret Limited Şirketi, müşteri memnuniyetini sağlamak ve en güvenilir, en uygun ve en kaliteli tur operatörü olmak için çalışmalarına devam eden bir şirkettir",
        modalCount: 5,
        date: new Date(),
      },
    ]);
    onMounted(async () => {
      setCurrentPageTitle(t("modalList.title"));
    });

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      totalItems,
      moduleList,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
