import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { CandidateDetailResponseDto } from "@/domain/interviewer/candidate-detail/dto/CandidateDetailResponseDto";
import { CandidateDetailRequestDto } from "@/domain/interviewer/candidate-detail/dto/CandidateDetailRequestDto";
import { CandidateInterviewModel } from "@/domain/interviewer/candidate-detail/model/CandidateInterviewModel";

export class CandidateDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CandidateDetailResponseDto): Result<CandidateDetailModel> {
    try {
      if (dto.data) {
        const candidateInterviews: CandidateInterviewModel[] = [];
        if (
          dto.data.attributes.interviews &&
          dto.data.attributes.interviews.data
        ) {
          dto.data.attributes.interviews.data.forEach((interview) => {
            const id = interview.id;
            const title = interview.attributes.title;
            const description = interview.attributes.description;
            const sequence = interview.attributes.sequence;
            const interviewCandidateStatus =
              interview.attributes.interviewCandidateStatus;

            const candidateInterview = new CandidateInterviewModel(
              id,
              title,
              description,
              sequence,
              interviewCandidateStatus
            );
            candidateInterviews.push(candidateInterview);
          });
        }

        const candidateDetailModel: CandidateDetailModel = {
          id: dto.data.id,
          firstName: dto.data.attributes.firstName,
          lastName: dto.data.attributes.lastName,
          fullName: dto.data.attributes.fullName,
          email: dto.data.attributes.email,
          cv: dto.data.attributes.cv,
          candidateStatus: dto.data.attributes.candidateStatus,
          hasInterviewSession: dto.data.attributes.hasInterviewSession,
          interviews: candidateInterviews,
        };

        if (dto.data.attributes.jobPosting && dto.data.attributes.jobPosting.data.id) {
          candidateDetailModel.jobPostingId =
          dto.data.attributes.jobPosting.data.id;
          candidateDetailModel.jobPostingTitle =
          dto.data.attributes.jobPosting.data.attributes.title;
        }

        if (dto.data.attributes.cv && dto.data.attributes.cv.data) {
          candidateDetailModel.cvUrl =
          dto.data.attributes.cv.data.attributes.url;
        }

        if(dto.data.attributes.candidateCVAnalysis && dto.data.attributes.candidateCVAnalysis.data) {
          candidateDetailModel.CvAnalysisScore = dto.data.attributes.candidateCVAnalysis.data.attributes.analysisScore
        }
        else {
          candidateDetailModel.CvAnalysisScore = undefined
        }

        if(dto.data.attributes.candidateCVAnalysis && dto.data.attributes.candidateCVAnalysis.data) {
          candidateDetailModel.CvAnalysisStatus = dto.data.attributes.candidateCVAnalysis.data.attributes.analysisStatus
        }
        else {
          candidateDetailModel.CvAnalysisStatus = undefined
        }

        if(dto.data.attributes.positionSuitability ) {
          candidateDetailModel.positionSuitability = dto.data.attributes.positionSuitability
        }
        else {
          candidateDetailModel.positionSuitability = undefined
        }

        return Result.ok(candidateDetailModel);
      } else {
        const interviewDetailModel: CandidateDetailModel = {};
        return Result.ok(interviewDetailModel);
      }
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_DETAIL))
      );
    }
  }

  mapToDTO(domain: CandidateDetailModel): Result<CandidateDetailRequestDto> {
    try {
      const interviewRequest: CandidateDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(interviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_DETAIL))
      );
    }
  }
}
