import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CandidateCvAnalysisModel } from "@/domain/interviewer/candidate-cv-analysis/model/CandidateCvAnalysisModel";
import { CandidateCvAnalysisRequestDto } from "@/domain/interviewer/candidate-cv-analysis/dto/CandidateCvAnalysisRequestDto";

export class CandidateCvAnalysisMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<CandidateCvAnalysisModel> {
    try {
      const candidateCvAnalysisModel: CandidateCvAnalysisModel = {
        ok: dto.ok,
      };

      return Result.ok(candidateCvAnalysisModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_CV_ANALYSIS))
      );
    }
  }

  mapToDTO(domain: CandidateCvAnalysisModel): Result<CandidateCvAnalysisRequestDto> {
    try {
      const candidateCvAnalysisRequest = new CandidateCvAnalysisRequestDto(
        domain.candidate,
      );

      return Result.ok(candidateCvAnalysisRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_CV_ANALYSIS))
      );
    }
  }
}
