
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { GetMultipleCandidateTemplateMapper } from "../mapper/GetMultipleCandidateTemplateMapper";
import { GetMultipleCandidateTemplateRepository } from "../repository/GetMultipleCandidateTemplateRepository";
import { IGetMultipleCandidateTemplateUseCase } from "@/domain/interviewer/multiple-candidate-template/usecase/IGetMultipleCandidateTemplateUsecase";
import { IGetMultipleCandidateTemplateRepository } from "@/domain/interviewer/multiple-candidate-template/repository/IGetMultipleCandidateTemplateRepository";
import { GetMultipleCandidateTemplateModel } from "@/domain/interviewer/multiple-candidate-template/model/GetMultipleCandidateTemplateModel";

export class GetMultipleCandidateTemplateUsecase implements IGetMultipleCandidateTemplateUseCase {
  getMultipleCandidateTemplateRepository : IGetMultipleCandidateTemplateRepository = new GetMultipleCandidateTemplateRepository();
  getMultipleCandidateTemplateMapper = new GetMultipleCandidateTemplateMapper();

  constructor() {
  }

  async execute(): Promise<Result<GetMultipleCandidateTemplateModel>> {
    try {
        const response = await this.getMultipleCandidateTemplateRepository.getTemplate();

        if (response.isSuccess) {
          const domain = this.getMultipleCandidateTemplateMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<GetMultipleCandidateTemplateModel>(domain.getValue());
        } else {
          return Result.fail<GetMultipleCandidateTemplateModel>(response.getError());
        }
    } catch (error) {
      return Result.fail<GetMultipleCandidateTemplateModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.GET_MULTI_CANDIDATE_TEMPLATE))
      );
    }
  }
}
