<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Body-->
        <div class="card-body pt-5">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-2 mb-3">
              Sürüm Güncellemeleri
            </div>
            <!--end::Title-->
          </div>
          <div class="fs-2"><b>V1.1.56</b> - 24.01.2025</div>
          <span>  
            - İş ilanı aday detay ekranında tasarımsal ve akışsal iyileştirmeler sağlandı. 
            <br />
            - İş ilanı aday detay ekranına mülakat logları eklendi. 
            <br />
            - Modül detay ekranlarına kalan kullanım hakkı ve modülü son kullanma tarihi eklendi. 
            <br />
            - Aday detay ekranı tasarım hataları giderildi.
            <br />
            - Dil desteği eksikliği tespit edilen ekranlar güncellendi. 
            <br />
            - Adaya mülakat için birden fazla invite gönderebilme problemi düzeltildi. İşlemin tamamlanmasını bekleme kısmı için loading kısmı eklendi. 
            <br />
            - Otp mesajları, aday ekleme, adaya mülakat daveti gönderme, modül listesi, modül detayları kullanıcı mesaj ve email şablonlarının seçili dile göre gelmesi sağlandı.
            <br />
            - İş ilanı istatistikleri güncellendi, toplamın yanlış gösterilmesi hatası giderildi.
            <br />
            - Konsol mesajları temizlendi.
            <br />
            - Modül detay servisi bağlantısı düzeltildi.
            <br />
            - Mülakata başlangıç ekranında mülakatın geçerli olup olmama durumuna göre yönlendirme eklendi. 
            <br />
            - Mülakat yapma ekranı temel düzeyde çalışır hale getirildi. 
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
            <br />
            <br />
          <div class="fs-4"><b>V1.1.55</b> - 19.01.2025</div>
          <span>  
            - İş ilanı aday detay ekranına mülakatların detayları ve analizleri eklendi. 
            <br />
            - Mülakat analizi ve mülakat cevapları tasarımları güncellendi. 
            <br />
            - İş ilanı aday detay ekranında mülakat cevapları ve mülakat logları panelinde dropdown'a mülakatlar eklendi.  
            <br />
            - Yazılımsal akış düzeltmeleri ve iyileştirmeleri sağlandı. 
            <br />
            - İş ilanı aday detay ekranında mülakat cevapları ekranı tamamlandı.
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
            <br />
            <br />
          <div class="fs-4"><b>V1.1.54</b> - 18.01.2025</div>
          <span>  
            - Adaya davet linki gönderme kısmında hatalı mail olma durumuna göre hata yapısı eklendi. 
            <br />
            - Davet linki gönderilme işlemi başarısız olma durumunda çıkan alertin kapanmaması problemi giderildi.
            <br />
            - İş ilanları düzenleme menüsünün izinleri güncellendi.
            <br />
            - İş ilanı ekleme ve düzenleme işlemlerinde aranılan özelliklere maksimum 20 olabilecek şekilde validasyon eklendi.
            <br />
            - Mülakata başlama ekranında kullanıcının mülakata erişemediği noktada hata ekranına yönlendirme eklendi. 
            <br />
            - Yazılımsal akış düzeltmeleri ve iyileştirmeleri sağlandı. 
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
            <br />
            <br />
          <div class="fs-4"><b>V1.1.53</b> - 16.01.2025</div>
          <span>  
            - Mülakat detaylarında adaya davet gönderme ve gönderilen daveti geri iptal etme kısmındaki hatalar giderildi. 
            <br />
            - Aday detay ekranında adayın pasif ve elenmiş olması durumunda Mülakata Davet Etme butonu kaldırıldı. 
            <br />
            - Pasif durumundaki adayların profil circle badge rengi güncellendi. 
            <br />
            - Aday detay ekranına Daveti İptal Et butonu eklendi. 
            <br />
            - Mülakata başlama ekranı aktif hale getirildi.
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
            <br />
            <br />
          <div class="fs-4"><b>V1.1.52</b> - 15.01.2025</div>
          <span>  
            - İş ilanı bilgileri ve aday bilgileri güncellendikten/silindikten sonra verilerin tekrar güncellenmeme problemi giderildi. 
            <br />
            - İş ilanında daha önceden CV Analizi yapılıp yapılmadığı bilgisine göre ayar düzenlemesi yapıldı.
            <br />
            - Mülakat detay ekranı kullanılabilir hale getirildi. 
            <br />
            - Mülakat davetini iptal etme fonksiyonu eklendi. 
            <br />
            - Mülakat detay ekranındaki tasarımsal hatalar giderildi.
            <br />
            - Servisten cevap beklenirken eksik olan Loading spinner'lar eklendi. 
            <br />
            - Aday detay ekranında profil simgesi rengi adayın durumuna göre değişecek şekilde düzenlendi. 
            <br />
            - İş ilanı detay ekranında aday listesinde pozisyon uygunluğu değerine göre badge rengi düzenlemesi sağlandı. 
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
            <br />
            <br />
          <div class="fs-4"><b>V1.1.51</b> - 14.01.2025</div>
          <span>  
            - Aday eklerken CVsi yüklenememe problemi giderildi.
            <br />
            - Aday profillerinde yer alan circle renkleri status'a göre düzenlendi. 
            <br />
            - Aday listesi ekranına pozisyon uygunluğu değeri eklendi.
            <br />
            - Jobposting detail ekranında pozisyon uygunluğuna göre sıralama eklendi.
            <br />
            - Çoklu aday ekleme ekranında CV yükleme işlemi bitmeden Davet Et tuşuna basıldığında CV'yi göndermeme problemi düzeltildi. Validasyon eklenerek işlem bitmeden davet işlemi yapılamaması sağlandı.
            <br />
            - Aday silindikten sonra ilgili iş ilanının aday listesine yönlendirme eklendi.
            <br />
            - İş ilanı özellikleri silinirken 3'ten az olma durumu için validasyon eklendi.
            <br />
            - Genel ekranlar kontrol edilerek hatalı olan tarihlerin güncellenmesi sağlandı. (Footer & Versiyon Notları)
            <br />
            - İş ilanı detayı ekranında Aday listesi güncellenme aşamasında loading spinner eklendi. 
            <br />
            - Adayın pozisyon uygunluğu istatistiği mevcut olmama durumu için bilgilendirme eklendi ve listeler buna göre güncellendi.
          <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.50</b> - 13.01.2025</div>
          <span>  
            - İş ilanı detay ekranı üzerinde CV Analizi yaparken diğer adayların detaylarına geçildiğinde loading spinner dönmeye devam etmesi sorunu giderildi.
            <br />
            - Aday detay ekranı CV Analizi akış hataları düzeltildi.
            <br />
            - Alertlerde eksik olan dil desteği eklendi.
            <br />
            - Konsola kontrol amaçlı eklenen notların tamamı silindi.
            <br />
            - Aday CVsi yüklenememe problemi giderildi.
            <br />
            - Kod tarafında akış iyileştirmeleri sağlandı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.49</b> - 13.01.2025</div>
          <span>  
            - Mülakat ekranları güncellendi ve dil desteği eklendi. 
            <br />
            - Satın alınmamış olan modüller için erişim kapatıldı. 
            <br />
            - Mülakatör modülü için adaylara iş ilanına göre CV Analizi eklendi. 
            <br />
            - İş ilanı aday detay bölümünde tasarımsal iyileştirmeler yapıldı.
            <br />
            - Adayın pozisyon uygunluğu değeri eklendi. 
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.48</b> - 07.01.2025</div>
          <span>  
            - Statik veri içeren ekran yönlendirmeleri temizlendi. 
            <br />
            - İş ilanı detay ekranında yer alan aday detay panelinde ID validasyonu eklendi ve ilgili hata sayfasına yönlendirme sağlandı. 
            <br />
            - Adaylara mülakata davet linki gönderme aşamasına iş ilanı tarihlerinin bilgisi eklendi. 
            <br />
            - Landing Page'den Yönetim paneline geçerken oluşan font hatası giderildi.
            <br />
            - Landing Page'de tasarım iyileştirmesi sağlandı.
            <br />
            - Genel kontrol sağlanıp tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.47</b> - 06.01.2025</div>
          <span>  
            - Aday detay sayfasında action list ekranında satırlara tıklandığında adayın mülakat detaylarına yönlendirme sağlandı. 
            <br />
            - İş ilanı detay ekranlarında iş ilanı silindikten sonra oluşan yönlendirme hatası giderildi.
            <br />
            - Tamamlanan sayfalar arasında dil desteği güncellendi. 
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.46</b> - 06.01.2025</div>
          <span>  
            - Adayların kart görünümünde isme tıklanıldığında detay ekranına gitmeme problemi giderildi.
            <br />
            - Tamamlanan sayfalar arasında dil desteği güncellendi. 
            <br />
            - Akış kontrolü sağlanarak genel hatalar giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.45</b> - 03.01.2025</div>
          <span>  
            - Aday cv analiz etme servisi entegre edildi. 
            <br />
            - Adayın cvsinin analiz edilip edilmeme durumuna göre tekrar analiz edilmemesi iş akışı geliştirildi. 
            <br />
            - Bağlı olan herhangi bir adayın cv analizi yapılan iş ilanının güncellenememe durumu akışı geliştirildi.
            <br />
            - Jobposting durumuna göre cv analizi yapılırsa iş ilanının tekrar güncellenemeyecek olmasıyla ilgili bilgilendirici alert mesajı eklendi. 
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.44</b> - 03.01.2025</div>
          <span>  
            - İş ilanı detay ekranında bulunan aday listesi erişilebilir hale getirildi. 
            <br />
            - İş ilanı detay componentinde tasarımsal iyileştirme yapıldı.
            <br />
            - İş ilanı detay ekranına loading spinner eklendi. 
            <br />
            - CV Analizinin tetiklenmesi için buton eklendi. 
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.43</b> - 02.01.2025</div>
          <span>  
            - Aday detay ekranı ve davet etme ekranları güncellendi. 
            <br />
            - Aday davet ekranı fonksiyonel hale getirildi. 
            <br />
            - İş ilanı detay ekranı fonksiyonel hale getirildi. 
            <br />
            - Aday listesi ve detay ekranlarında bilgilerin yükleme esnasında spinner eklendi. 
            <br />
            - İş ilanı inceleme ekranında yer alan iş ilanı bilgileri ve istatistik bilgileri fonksiyonelleştirildi. 
            <br />
            - Genel tasarımsal hatalar giderildi ve iyileştirmeler yapıldı. 
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.42</b> - 30.12.2024</div>
          <span>
            - İş ilanı istatistik grafiği optimize edildi ve kullanılabilir duruma getirildi.
            <br />
            - Mülakat yapma ekranında aday bilgilerinin bulunduğu kısım güncellenerek mülakatın temel bilgileri eklendi. 
            <br />
            - Tamamlanmış iş ilanlarında düzenleme menüsünün gözükme problemi giderildi.
            <br /> 
            - Aday detay sayfası tasarımsal olarak güncellendi. 
            <br />
            - Çoklu silme özelliğinin inaktif olduğunu vurgulayan alert mesajı eklendi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.41</b> - 27.12.2024</div>
          <span>
            - Mülakat ve iş ilanı akışı yeni yetkilere göre güncellendi.
            <br />
            - Aday silme ve düzenleme işlemleri aktif hale getirildi.
            <br />
            - Mülakat detay sayfasına iş ilanının açıklaması eklendi.
            <br />
            - Mülakat düzenle kısmında soru ekleme ve çıkartma işlemlerinde listenin yenilenmemesi problemi giderildi.
            <br />
            - Mülakat düzenle kısmında sorular üzerinde düzenleme yaparken birden fazla alert mesajı çıkartma problemi giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.40</b> - 26.12.2024</div>
          <span>
            - Mülakat oluştur ekranı tasarımı güncellendi. 
            <br />
            - Mülakat oluşturma adımlarında validasyon kuralları eklendi. 
            <br />
            - Mülakat sıralama adımında yeni mülakatın başlık ve açıklamasının gözükmemesi problemi giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.39</b> - 25.12.2024</div>
          <span>
            - Geçersiz id verilen url'lerde hata ekranına yönlendirme eklendi. 
            <br />
            - İş ilanına aday davet ederken uygulanan mail validasyonu iyileştirildi. 
            <br />
            - İş ilanlarında title'ın uzun olma durumunda tasarımı bozma hatası giderildi.
            <br />
            - İş ilanlarında ilanı aktif ve pasif yapma işleminde işlemin gerçekleşmemesine sebep olan bug giderildi. 
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.38</b> - 25.12.2024</div>
          <span>
            - Mülakat silme fonksiyonu aktif hale getirildi.
            <br />
            - Mülakat düzenleme ekranı güncellendi, bazı kısımlara validasyonlar eklendi.
            <br /> 
            - Mülakat oluşturma sürecinde göze çarpan bazı hatalar giderildi. 
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.37</b> - 25.12.2024</div>
          <span>
            - İş ilanı detay servisi ekranlara bağlandı. 
            <br />
            - Mülakat düzenleme ve soru ekleme esnasında herhangi bir hata alma durumunda bilgilendirici uyarı mesajı eklendi.
            <br />
            - Mülakat düzenle ekranında soru silme işleminde sorunun ekranda kalması problemi giderildi.
            <br />
            - Mülakat düzenle ekranı tasarımsal hatalar giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.36</b> - 24.12.2024</div>
          <span>
            - İş ilanı oluşturulduktan sonra Mülakat Oluştur sayfasına yönlendirilme sağlandı. 
            <br />
            - İş ilanı listesi sadece kullanılabilir fonksiyonlar yer alacak şekilde güncellendi. 
            <br />
            - İş ilanını pasif ve aktif şekilde düzenleyebilme özelliği eklendi. 
            <br />
            - Mülakat düzenleme sayfası yeni akışa göre düzenlendi. 
            <br />
            - İlan detayında özelliklerin tamamının gözükmesi sağlandı. 
            <br />
            - Mülakat detay sayfasına detay bilgisi eklendi. 
            <br />
            - Mülakat düzenleme ekranında soru ekleme, silme, güncelleme ve soruya şık ekleyip silme fonksiyonları aktif hale getirildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.35</b> - 23.12.2024</div>
          <span>
            - İş İlanı güncellenirken ilanın aktif pasif ya da beklemede olup olmadığını dikkate almadan düzenleme kısmına geçebilme problemi düzeltildi. 
            <br />
            - Modül listesinde önceden satın alınmış modüllerin "Satın alınabilir" şeklinde gözükecek şekilde düzeltildi. 
            <br />
            - Aday listesinde üç nokta basınca tablonun içinde kalma ve tasarımı bozma durumu düzeltildi. 
            <br />
            - İş ilanları listesinde filtreler düzenlendi, tamamlanan iş ilanları eklendi.
            <br />
            - Mülakat düzenleme ekranı ayrı sayfaya alınarak tasarımsal iyileştirme sağlandı. 
            <br />
            - Mülakat oluştur ekranında ilan seçme sayfasında sadece aday davet edilebilen ilanların seçilebilmesi sağlandı. 
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.34</b> - 20.12.2024</div>
          <span>
            - İş İlanı Aday listesi kullanılabilir hale getirildi. 
            <br />
            - İş İlanına tekli aday davet etme ekranı kullanılabilir hale getirildi. 
            <br />
            - İş İlanına çoklu aday davet etme ekranı kullanılabilir hale getirildi. 
            <br />
            - Aday davet etme ekranları validasyonları tamamlandı.
            <br />
            - Bekleme durumunda olan iş ilanları aday davet edilebilir şekilde güncellendi. 
            <br />
            - İş ilanları detaylarının yüklendiği ekranlara status bilgisi eklendi.
            <br />
            - İş İlanına çoklu aday davet etme ekranı tasarımı geliştirildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.33</b> - 19.12.2024</div>
          <span>
            - Çoklu aday ekleme ekranı güncellendi.
            <br />
            - Chart istatistiklerinin yer aldığı sayfalarda çokça sayıda console
            hatasına neden olan problem giderildi.
            <br />
            - Çoklu aday daveti yapılan ekranda Şablonu indir butonuna tıklanıldığında sayfayı yenileyen hata giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.32</b> - 18.12.2024</div>
          <span>
            - Aday listesi ekranı güncellendi.
            <br />
            - Aday davet etme ekranları güncellendi.
            <br />
            - Mülakatör, modül listesine dahil edildi.
            <br />
            - Çoklu aday eklemesi için xlsx şablonu eklendi.
            <br />
            - Bootstrap DOM kaynaklı Modal hatası fixlendi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.31</b> - 18.12.2024</div>
          <span>
            - İş ilanı listeleme ekranında mülakatların listelenmesi eklendi.
            <br />
            - İş ilanı düzenleme ekranında mülakatlar sıralanabilir hale
            getirildi.
            <br />
            - Mülakat oluşturma adımları çalışır hale getirildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.30</b> - 17.12.2024</div>
          <span>
            - İş ilanı aday davet et menüsü eklendi.
            <br />
            - İş ilanı aday davet etme kısmında çoklu veya tekli aday davet etme
            seçenekleri eklendi.
            <br />
            - Mülakat detay ekranı, mülakat düzenleme ekranı güncellendi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.29</b> - 16.12.2024</div>
          <span>
            - İş ilanı adayları listeleme ekranı eklendi.
            <br />
            - İş ilanı özellik ekleme kısmında UX iyileştirmesi sağlandı.
            <br />
            - İş ilanı listeleme ekranında Aday Davet Et butonuna
            tıklanıldığında tepki vermeme hatası düzeltildi.
            <br />
            - İş ilanı detay menüsünde pasif olarak ayarlama eklendi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.28</b> - 13.12.2024</div>
          <span>
            - İş ilanı listeleme ekranında bekleyen ilanlar filtresi eklendi.
            <br />
            - İş ilanı oluşturma, listeleme, silme ve düzenleme bölümlerine dil
            desteği eklendi.
            <br />
            - İş ilanı düzenleme ekranında tab paneller arasında geçiş
            yapılmasını engelleyen hata giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.27</b> - 13.12.2024</div>
          <span>
            - İş ilanı oluşturma ekranında validasyonlar tamamlandı.
            <br />
            - İş ilanı güncellemesinde validasyonlar tamamlandı.
            <br />
            - İş ilanı güncelleme ekranında tarihlerin gelmeme problemi
            giderildi.
            <br />
            - İş ilanı güncelleme ekranında sadece tek iş ilanı üzerinden
            ilerleyebilme sorunu giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.26</b> - 13.12.2024</div>
          <span>
            - İş ilanı oluşturma ekranında aranılan özellikler kısmı buton
            olarak güncellendi.
            <br />
            - İş ilanı güncelleme penceresi tasarımı iyileştirildi.
            <br />
            - İş ilanı güncelleme ekranında aranılan özellikler düzenlenebilir
            şekilde ayarlandı.
            <br />
            - İş ilanı listeleme ekranında tarih aralıkları eklendi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.25</b> - 12.12.2024</div>
          <span>
            - İş ilanı oluşturma ekranında required olan inputlar düzenlendi.
            <br />
            - İş ilanı listeleme ekranında tasarımsal iyileştirme yapıldı.
            <br />
            - İş ilanı listeleme ekranında detaylar ayrı modal penceresinde
            gösterilecek şekilde düzenlendi.
            <br />
            - İş ilanı güncelleme modalı çalışır hale getirildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.24</b> - 11.12.2024</div>
          <span>
            - İş ilanı oluşturma ekranında eklenen özellikler silinebilir hale
            getirildi, pozisyon kaldırıldı.
            <br />
            - İş ilanı listeleme ekranı kullanılabilir duruma getirildi.
            <br />
            - İş ilanı silme fonksiyonu kullanılabilir duruma getirildi.
            <br />
            - İş ilanı akışında testler yapılarak tasarımsal hatalar giderildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.23</b> - 10.12.2024</div>
          <span>
            - Mülakat yapma ekranlarında tasarım hataları giderildi.
            <br />
            - iş ilanı ekranlarında tasarım hataları giderildi.
            <br />
            - iş ilanı oluşturma ekranı kullanılabilir hale getirildi.
            <br />
            - Ekranlarda genel UX iyileştirmesi yapıldı.
            <br />
            - İş ilanı oluşturma ekranında rich editör gereksinimlere göre
            düzeltildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.22</b> - 04.12.2024</div>
          <span>
            - Mülakatlar tüm ekranlarda sıralanabilir hale getirildi.
            <br />
            - Aday detay sayfası güncellendi.
            <br />
            - Ekranlarda genel UX güncellemesi yapıldı.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.21</b> - 03.12.2024</div>
          <span>
            - Mülakat detay ekranı oluşturuldu.
            <br />
            - Aday detay sayfası güncellendi.
            <br />
            - Mülakat düzenleme ekranında sorularda çoklu seçenek ekleyememe
            hatası giderildi.
            <br />
            - Mülakat oluşturma adımlarında soru ekleme bölümü güncellendi.
            <br />
            - İş ilanına aday davet etme ekranına mülakat listesi eklendi.
            <br />
            - İş ilanları sayfasında tasarımsal hatalar giderildi.
            <br />
            - İş ilanına tarih aralığı eklendi.
            <br />
            - Mülakat bilgilerinde tarih aralığı kısmı kaldırıldı.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.20</b> - 02.12.2024</div>
          <span>
            - Mülakata başlama ekranı güncellendi
            <br />
            - Menü güncellendi. Aday Davet Et menüsü kaldırıldı.
            <br />
            - Mülakat detay sayfasında eğer mülakat detayı bulamıyorsa Anketler
            sayfasına yönlendirme hatası giderildi.
            <br />
            - Açık Pozisyon kavramı modül genelinde "İş İlanı" olarak
            güncellendi.
            <br />
            - Açık Pozisyon listesinde filtreleme yöntemi ve tasarımı
            güncellendi.
            <br />
            - Gerekli durumlarda forbidden sayfasına yönlendirme yapılması
            sağlandı.
            <br />
            - Mülakat detay ekranı oluşturuldu.
            <br />
            - Aday ekleme ekranında cinsiyet seçeneği eklendi.
            <br />
            - Mülakat soruları ve bilgileri düzenlenebilir hale getirildi.
            <br />
            - Versiyon notları sayfasına menü üzerinden yönlendirme sağlandı.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.19</b> - 28.11.2024</div>
          <span>
            - Mülakata başlama ve sonuç ekranları oluşturuldu.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.18</b> - 26.11.2024</div>
          <span>
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
            <br />
            - Genel tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.17</b> - 22.11.2024</div>
          <span>
            - Mülakat ekran tasarımları güncellendi.
            <br />
            - Mülakatde birden fazla mülakat yapılabilmesi adına akış
            güncellemesi yapıldı.
            <br />
            - Modül detay sayfasındaki router problemi giderildi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.16</b> - 19.11.2024</div>
          <span>
            - Mülakat ekran tasarımları güncellendi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.15</b> - 15.11.2024</div>
          <span>
            - Mülakat ekran tasarımları güncellendi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.14</b> - 08.11.2024</div>
          <span>
            - Mülakat ekran tasarımları güncellendi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.13</b> - 01.11.2024</div>
          <span>
            - Mülakat İstatistik sayfaları güncellendi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.12</b> - 31.10.2024</div>
          <span>
            - Mülakat modülü entegre edildi.
            <br />
            - Mülakat iş akışı kontrol edilerek hata düzeltmeleri yapıldı.
            <br />
            - Landing page düzenlendi.
            <br />
            - Mülakat İstatistik sayfaları oluşturuldu.
            <br />
            - İstatistik sayfalarında tarih formatları güncellendi.
            <br />
            - Mülakat sayfaları routing düzenlemeleri yapıldı.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.11</b> - 21.10.2024</div>
          <span>
            - Mülakat ekranı düzeltildi.
            <br />
            - Mülakat oluştur ekranı eklendi.
            <br />
            - Mülakat listeleme ekranı eklendi.
            <br />
            - Adaya mülakat atama ekranı eklendi.
            <br />
            - Mülakat tarih düzenleme yetkisi eklendi.
            <br />
            - Geçmiş mülakatları görüntüleme ekranı eklendi.
            <br />
            - Test ve analizler sonucu bulunan tasarım hataları düzeltildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.10</b> - 04.10.2024</div>
          <span>
            - Anketör modülü istatistik ekranları eklendi.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.9</b> - 27.08.2024</div>
          <span>
            - Anketör modülü oluşturuldu.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.8</b> - 31.07.2024</div>
          <span>
            - Personel davet kabul ekranında sayfa yönlendirme akışı düzenlendi.
            <br />
            - Login ekranında tüm ülkelerin yüklenmesini engelleyen hata
            giderildi.
            <br />
            - Swal alertlerin açıldığında template üzerinde meydana gelen
            hatalar düzeltildi.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.7</b> - 30.07.2024</div>
          <span>
            - Rol routing yapısı düzeltildi.
            <br />
            - Personel davet etme özelliği aktif hale getirildi.
            <br />
            - Personel listesi ekranı düzeltildi.
            <br />
            - Davet kabul etme ekranı oluşturuldu.
            <br />
            - Menüde bulunan Aktif Modüller kategorisi üzerinde düzeltme
            yapıldı.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.6</b> - 19.07.2024</div>
          <span>
            - Landing page aktif hale getirildi.
            <br />
            - Create Company ekranı oluşturuldu.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.5</b> - 18.07.2024</div>
          <span>
            - Aktif modüller menüsü oluşturuldu.
            <br />
            - Satın alınan modüller servisi eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.4</b> - 17.07.2024</div>
          <span>
            - Dil dosyaları güncellendi.
            <br />
            - Otel asistanı modül sayfasına personel ekleme butonu eklendi.
            <br />
            - Kullanıcı rolu kapsamında tarayıcıda değiştirilme sonucu oluşan
            hata giderildi.
            <br />
            - Şirket listeleme ekranı eklendi.
            <br />
            - Modül listeleme ekranında mobil cihazlarda oluşan tasarım hatası
            giderildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.3</b> - 11.07.2024</div>
          <span>
            - Dashboard ekranında istatistik servisi entegre edildi.
            <br />
            - Modül detay ekranına dil desteği eklendi.
            <br />
            - Otel asistanı modülünde birden fazla konuşma geçmişi görüntüleme
            esnasında oluşan hata giderildi.
            <br />
            - Tenant değiştirme fonksiyonunda aynı tenanta tekrar geçiş
            yapabilmeyi sağlayan ve sayfayı refreshleyen hata düzeltildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.2</b> - 09.07.2024</div>
          <span>
            - Modül detay ekranına modülün yapay zeka modülünü seçme componenti
            eklendi.
            <br />
            - Modül detay ekranı tasarım düzeltmeleri yapıldı.
            <br />
            - Ai asistan modülü genel tasarım düzeltmeleri yapıldı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.1</b> - 05.07.2024</div>
          <span>
            - Yetkiler güncellendi.
            <br />
            - OTP kodu girme kısmını güncellendi, kontrollü giriş eklendi.
            <br />
            - Otel asistanı modülünde personel listesine row-click ekledi.
            <br />
            - Otel asistanı modülünde yeni sohbet oluşturulduğunda kullanıcının
            duygu analizinin ekranda gösterilmesi sağlandı.
            <br />
            - Profilim ekranına ülke kodu eklendi, validasyonlar düzeltildi.
            <br />
            - Personel silme fonksiyonu aktif hale getirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.0</b> - 04.07.2024</div>
          <span>
            - Proje ana yapısı ve akışı güncellendi.
            <br />
            - Otel asistanı modülü konuşma geçmişlerine duygu analizi eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.13</b> - 01.07.2024</div>
          <span>
            - Modüller sayfası ve modül detay sayfası güncellendi.
            <br />
            - Landing page güncellendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.12</b> - 27.06.2024</div>
          <span>
            - Modül listesi ekranı fonksiyonel ve modüler hale getirildi.
            <br />
            - Modül detay sayfası servise bağlanarak güncellendi.
            <br />
            - Landing page router problemi giderildi.
            <br />
            - Landing page güncellendi.
            <br />
            - Şirket değiştirme fonksiyonunda yaşanılan yetki problemi
            giderildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.11</b> - 25.06.2024</div>
          <span>
            - Kullanılmayan ve fonksiyonel olarak çalışmayan ekranlar menüden
            kaldırıldı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.10</b> - 12.06.2024</div>
          <span>
            - Landing Page eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.9</b> - 11.06.2024</div>
          <span>
            - Menülerin tamamı aktif hale getirildi.
            <br />
            - Change Tenant fonksiyonu eklendi.
            <br />
            - AI Otel Asistanı admin rolü alt menüleri eklendi.
            <br />
            - AI Otel Asistanı modülü personellerin session kayıt geçmişin
            görüntüleme ekranı oluşturuldu.
            <br />
            - Kullanıcı menüsünde telefon numarasına ülke kodu eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.8</b> - 31.05.2024</div>
          <span>
            - Site demo olarak hazırlanıp düzeltmeler yapıldı.
            <br />
            - AI Otelcilik Asistanında beğenmedim butonu eklenerek yeniden
            generate edilmesi sağlandı.
            <br />
            - Otelcilik Asistan modülünde arama fonksiyonu geliştirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.7</b> - 30.05.2024</div>
          <span>
            - Genel sayfa eklemesi ve düzeltmeleri yapıldı.
            <br />
            - Şirket oluştur sayfası eklendi.
            <br />
            - Şirket listeleme sayfası eklendi.
            <br />
            - Personel ekle ve personel listesi ekranları çalışır hale
            getirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.6</b> - 27.05.2024</div>
          <span>
            - Ogza rolündeki tüm modüller ekranı ve modül oluştur/düzenle ekranı
            eklendi.
            <br />
            - Chatgpt ekranı eklendi.
            <br />
            - İngilizce ve Türkçe Dil desteği eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.5</b> - 23.05.2024</div>
          <span>
            - Kullanıcı rolü anket ve mülakat modülleri giriş ekranı eklendi.
            <br />
            - Misafir rolü layoutu oluşturuldu. Mülakat ve anket ekranları
            eklendi.
            <br />
            - Menüde Ogza kategorisi alt menüleri oluşturuldu.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.4</b> - 20.05.2024</div>
          <span>
            - Kullanıcı menü kategorisine Mülakat eklendi.
            <br />
            - Kullanıcı anket arayüz tasarımı güncellendi ve mülakat ekranı
            oluşturuldu.
            <br />
            - Form ve validasyonlar güncellendi.
            <br />
            - Menü ikonları güncellendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.3</b> - 17.05.2024</div>
          <span>
            - Kullanıcı menü kategorisi oluşturuldu.
            <br />
            - Kullanıcı anket arayüz tasarımı eklendi.
            <br />
            - Admin rolüne ait Personeller, Personel Davet Et, Hesap Durumu,
            Hesap Türleri ekranları oluşturuldu.
            <br />
            - Kullanıcıdan giriş istenilen inputlarda validasyonlar tanımlandı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.2</b> - 16.05.2024</div>
          <span>
            - Mülakat ve Anketör için modül detay ekranları oluşturuldu.
            <br />
            - Menü düzenlendi, anketör alt menüleri eklendi.
            <br />
            - Anketör Oluştur ve Mülakat Oluştur ekranları eklendi.
            <br />
            - Tasarımsal düzenlemeler yapıldı.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.1</b> - 15.05.2024</div>
          <span>
            - Modül ekranları eklendi.
            <br />
            - Modül detay ekranı eklendi.
            <br />
            - Otelcilik modülü mesaj onay bildirimi kaldırıldı.
            <br />
            - Tasarımsal düzenlemeler yapıldı.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.0</b> - 14.05.2024</div>
          <span>
            - Website şablonu hazırlanmış olup giriş ekranları tamamlanmıştır.
            <br />
            - Kullanıcı profil düzenleme ekranları yenilenmiştir.
            <br />
            - Sürüm güncellemeleri ekranı oluşturulmuştur.
            <br />
            - Menü tasarlanmıştır.
            <br />
            - Şablon üzerinde renk, font ve tasarım güncellemeleri yapılmıştır.
            <br />
            - Otelcilik modülü ekran tasarımları tamamlanıp aktif hale
            getirilmiştir.
          </span>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";

export default defineComponent({
  name: "versions",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Versiyonlar");
    });

    return {};
  },
});
</script>
