import { BaseDomain } from "@/core/base/BaseDomain";

export class BuyProductModel extends BaseDomain {
  productPackageId?: number;
  name?: string;
  price?: number;
  currency?: string;
  description?: string;
  period?: number;
  quota?: number;

  constructor(productPackageId?: number,
    name?: string,
    price?: number,
    currency?: string,
    description?: string,
    period?: number,
    quota?: number,
  ) {
    super();
    this.productPackageId = productPackageId;
    this.name = name;
    this.price = price;
    this.currency = currency;
    this.description = description;
    this.period = period;
    this.quota = quota;
  }
}
