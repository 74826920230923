
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { DeleteCandidateRepository } from "../repository/DeleteCandidateRepository";
import { DeleteCandidateMapper } from "../mapper/DeleteCandidateMapper";
import { IDeleteCandidateUseCase } from "@/domain/interviewer/delete-candidate/usecase/IDeleteCandidateUseCase";
import { IDeleteCandidateRepository } from "@/domain/interviewer/delete-candidate/repository/IDeleteCandidateRepository";
import { DeleteCandidateModel } from "@/domain/interviewer/delete-candidate/model/DeleteCandidateModel";

export class DeleteCandidateUseCase implements IDeleteCandidateUseCase {
  deleteCandidateRepository : IDeleteCandidateRepository = new DeleteCandidateRepository();
  deleteCandidateMapper = new DeleteCandidateMapper();

  constructor() {
  }

  async execute(model: DeleteCandidateModel): Promise<Result<DeleteCandidateModel>> {
    try {
      const dto = this.deleteCandidateMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteCandidateModel>(dto.getError());
      } else {
        const response = await this.deleteCandidateRepository.deleteInterview(dto);

        if (response.isSuccess) {
          const domain = this.deleteCandidateMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteCandidateModel>(domain.getValue());
        } else {
          return Result.fail<DeleteCandidateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteCandidateModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.DELETE_CANDIDATE))
      );
    }
  }
}
