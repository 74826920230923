import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class CandidateListRequestDto extends BaseDTO {
  pagination: PaginationRequestDto;
  jobPostingId?: number;
  searchQuery?: string;
  candidateStatus?: string;
  sort?: string;

  constructor(
    pagination: PaginationRequestDto,
    jobPostingId?: number,
    searchQuery?: string,
    candidateStatus?: string,
    sort?: string
  ) {
    super();
    this.pagination = pagination;
    this.jobPostingId = jobPostingId;
    this.searchQuery = searchQuery;
    this.candidateStatus = candidateStatus;
    this.sort = sort;
  }

  toQueryString(): string {
    const queryParams: string[] = [];

    if (this.pagination) {
      queryParams.push(`pagination[page]=${this.pagination.pagination.page}`);
      queryParams.push(
        `pagination[pageSize]=${this.pagination.pagination.pageSize}`
      );
    }

    if (this.searchQuery) {
      queryParams.push(`searchQuery=${encodeURIComponent(this.searchQuery)}`);
    }

    if (this.candidateStatus) {
      queryParams.push(`candidateStatus=${this.candidateStatus}`);
    }

    if (this.sort) {
      queryParams.push(`sort=${encodeURIComponent(this.sort)}`);
    }

    return `?${queryParams.join("&")}`;
  }
}
