<template>
  <div
    v-if="isLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>

  <div v-else class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-4">
      <div class="card card-flush mb-7">
        <span
          v-if="jobPosting.status"
          :class="JobPostingStatusBadge(jobPosting.status)"
        >
          {{
            jobPosting.status === JobPostingStatus.ACTIVE
              ? $t("common.active")
              : jobPosting.status === JobPostingStatus.PENDING
              ? $t("common.waiting")
              : jobPosting.status === JobPostingStatus.COMPLETED
              ? $t("common.completed")
              : $t("common.inactive")
          }}
        </span>
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>{{ jobPosting.title }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-1">
          <!--begin::Permissions-->

          <div class="fw-bold text-gray-600 mb-3">
            {{ formatLocalizedDate(jobPosting.startDate) }} -
            {{ formatLocalizedDate(jobPosting.endDate) }}
          </div>

          <div class="fw-bold text-gray-600 mb-3">
            <a
              data-bs-toggle="modal"
              :data-bs-target="'#descriptionModal' + jobPosting.id"
              class="
                text-primary text-decoration-underline
                fw-bold
                cursor-pointer
              "
            >
              {{ $t("modals.interview.viewDetail") }}
            </a>
            <!-- <div
                    v-html="jobPosting.description"
                    class="description-text"
                  ></div> -->
          </div>

          <!--begin::Modal-->
          <div
            class="modal fade"
            :id="'descriptionModal' + jobPosting.id"
            tabindex="-1"
            aria-labelledby="descriptionModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="descriptionModalLabel">
                    {{ $t("modals.interview.jobPostingDesc") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body mh-500px overflow-auto">
                  <div v-html="jobPosting.description"></div>

                  <div class="separator my-4"></div>

                  <div
                    v-for="(
                      qualification, i
                    ) in jobPosting.jobPostingQualifications"
                    :key="i"
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    {{ qualification.text }}
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("common.button.close") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal-->

          <router-link :to="'/interviewer/jobposting-detail/' + jobPosting.id">
            <div class="fw-bold text-gray-600 mb-3">
              {{ $t("modals.interview.candidateCount") }}:
              {{ jobPosting.candidateCount }}
            </div>

            <div class="d-flex flex-column text-gray-600 pb-5">
              <div
                v-for="(qualification, i) in visibleRequirements(
                  jobPosting.jobPostingQualifications
                )"
                :key="i"
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                {{ qualification.text }}
              </div>

              <div
                v-if="
                  jobPosting.jobPostingQualifications &&
                  jobPosting.jobPostingQualifications.length > 3
                "
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                <em
                  >{{
                    hiddenRequirementCount(jobPosting.jobPostingQualifications)
                  }}
                  {{ $t("modals.interview.morePieces") }}</em
                >
              </div>
            </div>
          </router-link>
          <!--end::Permissions-->
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <router-link
              :to="'/interviewer/jobposting-detail/' + jobPosting.id"
              class="flex-grow-1"
            >
              <a class="btn btn-sm btn-primary btn-active-secondary w-100">
                {{ $t("common.button.review") }}
              </a>
            </router-link>
          </div>

          <div class="separator my-4"></div>

          <div class="d-flex flex-wrap gap-2 mb-3" v-if="jobPosting.interviews">
            <!-- Görünen mülakat öğeleri -->
            <div
              v-for="(item, index) in visibleItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; min-width: 150px"
            >
              <router-link :to="'/interviewer/interview-detail/' + item.id">
                <span class="fs-6 fw-semibold text-muted lh-1"
                  >{{ item.title }}
                </span>
              </router-link>
            </div>

            <!-- +X butonu -->
            <div
              v-if="hiddenItemCount(jobPosting.interviews) > 0 && !showAll"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; cursor: pointer; min-width: 55px"
              @click="showAllInterviews"
            >
              <span class="fs-6 fw-semibold text-muted lh-1"
                >+{{ hiddenItemCount(jobPosting.interviews) }}</span
              >
            </div>

            <!-- Gizli mülakatlar gösterildiğinde -->
            <div
              v-if="showAll"
              v-for="(item, index) in hiddenItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; min-width: 150px"
            >
              <router-link :to="'/interviewer/interview-detail/' + item.id">
                <span class="fs-6 fw-semibold text-muted lh-1">{{
                  item.title
                }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--begin::Contact group wrapper-->
      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>{{ $t("modals.interview.interviewCount") }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <apexchart
              v-if="jobPostingInterviewCount.total != 0"
              class="statistics-widget-3-chart card-rounded-bottom"
              width="100%"
              height="300px"
              type="donut"
              :options="chartOptions"
              :series="series"
            ></apexchart>
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >{{ $t("modals.interview.totalInterviewCount") }}</a
              >
              <div class="badge badge-light-primary">
                {{ jobPostingInterviewCount.total }}
              </div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.completedInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[0] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.ongoingInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[1] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-danger text-hover-primary">{{
                $t("modals.interview.canceledInterview")
              }}</a>
              <div class="badge badge-light-danger">{{ series[2] }}</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->
    </div>
    <!--end::Contact groups-->

    <!--begin::Content-->
    <div class="col-xl-8">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin::Contacts-->
        <div class="card card-flush h-lg-100" id="kt_contacts_main">
          <!--begin::Card header-->
          <div class="card-header pt-3" id="kt_chat_contacts_header">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>{{ $t("modals.interview.inviteCandidate") }}</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <Form
            class="form w-100"
            id="kt_login_signin_form"
            @submit="submitForm"
            :validation-schema="inviteCandidateForm"
          >
            <!--begin::Card body-->
            <div class="card-body pt-5">
              <!--begin::Aday Ekle-->
              <div class="fv-row mb-10">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-10">
                      <label class="form-label required">{{
                        $t("modals.interview.name")
                      }}</label>
                      <Field
                        ref="firstName"
                        name="firstName"
                        data-field="firstName"
                        v-model="inviteCandidateModel.firstName"
                        class="form-control form-control-lg form-control-solid"
                        @keydown.enter.prevent="focusNextInput('lastName')"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="firstName" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-10">
                      <label class="form-label required">{{
                        $t("modals.interview.surname")
                      }}</label>
                      <Field
                        ref="lastName"
                        name="lastName"
                        data-field="lastName"
                        v-model="inviteCandidateModel.lastName"
                        class="form-control form-control-lg form-control-solid"
                        @keydown.enter.prevent="focusNextInput('email')"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="lastName" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-10">
                    <label class="form-label required">{{
                      $t("modals.interview.email")
                    }}</label>
                    <Field
                      ref="email"
                      name="email"
                      data-field="email"
                      v-model="inviteCandidateModel.email"
                      class="form-control form-control-lg form-control-solid"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="form-label required">{{
                    $t("modals.interview.candidate.uploadCV")
                  }}</label>
                  <Field
                    type="file"
                    name="cv"
                    v-model="inviteCandidateModel.cv"
                    accept=".pdf, .doc, .docx"
                    class="form-control form-control-lg form-control-solid"
                    @change="validateFile"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="cv" />
                    </div>
                  </div>
                </div>
                <div class="text-gray-600 fw-bold fs-8 mb-10">
                  {{ $t("modals.interview.candidate.uploadCVDesc") }}
                </div>

                <!-- <div class="mb-10">
                <label class="form-label required">İş Tecrübesi</label>
                <Field
                  type="text"
                  as="textarea"
                  name="candidateExperience"
                  class="form-control form-control-lg form-control-solid"
                />
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">Bitirdiği Okul</label>
                    <Field
                      name="candidateDepartment"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">Bitirdiği Bölüm</label>
                    <Field
                      name="candidateProfession"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
              </div> -->
              </div>
              <!--end::Aday Ekle-->
              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"
                    >{{ $t("common.button.invite") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
            <!--end::Card body-->
          </Form>
        </div>
        <!--end::Contacts-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { AddCandidateModel } from "@/domain/interviewer/add-candidate/model/AddCandidateModel";
import Swal from "sweetalert2";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { JobPostingStatusBadge } from "@/presentation/helper/StatusBadge";
import { formatLocalizedDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "invite-candidate",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);
    const startDate = ref("");
    const endDate = ref("");

    const countryList = ref<CountryListModel[]>([]);

    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const inviteCandidateModel = ref<AddCandidateModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = computed(() => {
      const completed = Number(jobPostingInterviewCount.value?.completed) ?? 0;
      const inProgress =
        Number(jobPostingInterviewCount.value?.inProgress) ?? 0;
      const failed = Number(jobPostingInterviewCount.value?.failed) ?? 0;

      return [completed, inProgress, failed];
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });

    const validateFile = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }

        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }
      }
    };

    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailPattern.test(email);
    };

    const inviteCandidateForm = Yup.object().shape({
      firstName: Yup.string()
        .required(t("validators_error.required"))
        .label("firstName"),
      lastName: Yup.string()
        .required(t("validators_error.required"))
        .label("lastName"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("email"),
    });

    const submitForm = () => {
      if (!inviteCandidateModel.value.cv) {
        swalNotification.error(
          t("validators_error.requiredCV"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      } else if (!validateEmail(inviteCandidateModel.value.email)) {
        swalNotification.error(
          `Lütfen geçerli bir e-posta adresi giriniz. `,
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      } else {
        inviteCandidateModel.value.jobPosting = jobPostingId.value;

        interviewerController
          .addCandidate(inviteCandidateModel.value)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_CANDIDATE),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({
                  path: "/interviewer/candidate-list/" + jobPostingId.value,
                });
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            isLoading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const getJobPostingInterviewCounts = () => {
      const jobPostingInterviewCountModel: InterviewCountModel = {
        jobPostingId: jobPostingId.value,
      };

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: response.getValue().total,
              completed: response.getValue().completed,
              inProgress: response.getValue().inProgress,
              failed: response.getValue().failed,
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts();

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const focusNextInput = (fieldName) => {
      // `data-field` attribute ile element seçimi
      const nextInput = document.querySelector(
        `[data-field="${fieldName}"]`
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      JobPostingStatus,
      validateFile,
      focusNextInput,
      countryList,
      inviteCandidateForm,
      submitForm,
      inviteCandidateModel,
      isLoading,
      chartOptions,
      series,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      JobPostingStatusBadge,
      jobPostingInterviewCount,
    };
  },
});
</script>

<style>
.hover-effect {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
  background-color: #f8f9fa; /* Hafif gri renk */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.interview-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.interview-item:hover {
  background-color: #f0f0f0; /* Gri arka plan */
  color: #333; /* Metin rengi */
}

.interview-item.disabled:hover {
  background-color: #e0e0e0; /* Hoverda aynı gri kalır */
  color: #6c757d; /* Metin rengi de aynı kalır */
}
</style>
