<template>
  <!--begin::Wrapper-->
  <div class="w-lg-700px bg-white rounded shadow-sm p-10 p-lg-15 ">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      @submit="onSubmit"
      id="kt_accept_invite_reset_form"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t("acceptInvite.title") }}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{ $t("acceptInvite.desc") }}
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->

      <!--begin::Actions-->
      <div class="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          ref="submitButton"
          id="kt_password_reset_submit"
          class="btn btn-lg btn-primary fw-bolder me-4"
        >
          <span class="indicator-label"> {{ $t("acceptInvite.button") }} </span>
          <span class="indicator-progress">
            {{ $t("common.pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { AcceptInviteModel } from "@/domain/staff/invite-staff/accept/model/AcceptInviteModel";
import Swal from "sweetalert2";

export default defineComponent({
  name: "acceptInvite",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const staffController = store.state.ControllersModule.staffController;
    const swalNotification = new SwalNotification();
    let token: undefined | string = undefined;

    const submitButton = ref<HTMLElement | null>(null);
    const acceptInviteModel = ref<AcceptInviteModel>({});

    //Form submit function
    const onSubmit = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      acceptInviteModel.value = {
        token: token,
      };

      staffController
        .acceptInvite(acceptInviteModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ACCEPT_INVITE),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "dashboard" });
              });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      token = route.query.token?.toString();
    });

    return {
      onSubmit,
      submitButton,
      acceptInviteModel,
    };
  },
});
</script>
