<template>
  <div
    v-if="isLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>
  <div v-else class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card header-->
        <div
          class="card-header align-items-center d-flex justify-content-between"
        >
          <div class="card-title col-md-9 mt-2">
            <h2 class="mb-0 text-hover-primary mt-4">{{ interview.title }}</h2>
          </div>

          <div v-if="!interview.hasCompletedInterviewSession">
            <button
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots fs-3"></i>
            </button>
            <InterviewDropdown :interviewId="interviewId" />
          </div>

          <!-- <div class="col-md-2 d-flex justify-content-end align-items-center">
              <span
                class="
                  svg-icon svg-icon-2
                  btn btn-sm btn-bg-light btn-active-color-primary
                "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_update_role"
              >
                <inline-svg src="/media/icons/duotune/art/art005.svg" />
              </span>
            </div> -->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-1">
          <div class="fw-bold fs-7 text-gray-600 mb-5">
            {{ interview.description }}
          </div>

          <!-- <div class="badge badge-light-info d-inline">
            Ofisten Çalışan Fullstack Developer
            </div> -->
          <!--begin::Permissions-->
          <!--               <h2 contenteditable spellcheck="false">Ofisten Çalışan Fullstack Developer</h2> -->
          <!--end::Permissions-->
          <div class="separator my-4"></div>

          <div v-if="interview.jobPosting">
            <h4>{{ interview.jobPosting.title }}</h4>
            <p>
              <b
                >{{ formatLocalizedDate(interview.jobPosting.startDate) }} -
                {{ formatLocalizedDate(interview.jobPosting.endDate) }}</b
              >
            </p>

            <div class="fw-bold text-gray-600 mb-3">
              {{ $t("modals.interview.candidateCount") }}:
              {{ interview.jobPosting.candidateCount }}
            </div>

            <div class="fw-bold text-gray-600 mb-3">
              <a
                data-bs-toggle="modal"
                :data-bs-target="'#descriptionModal' + interview.jobPosting.id"
                class="
                  text-primary text-decoration-underline
                  fw-bold
                  cursor-pointer
                "
              >
                {{ $t("modals.interview.viewDetail") }}
              </a>
              <!-- <div
                    v-html="jobPosting.jobPosting.description"
                    class="description-text"
                  ></div> -->
            </div>

            <!--begin::Modal-->
            <div
              class="modal fade"
              :id="'descriptionModal' + interview.jobPosting.id"
              tabindex="-1"
              aria-labelledby="descriptionModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="descriptionModalLabel">
                      {{ $t("modals.interview.jobPostingDesc") }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body mh-500px overflow-auto">
                    <div v-html="interview.jobPosting.description"></div>

                    <div class="separator my-4"></div>

                    <div
                      v-for="(qualification, i) in interview.jobPosting
                        .jobPostingQualifications"
                      :key="i"
                      class="d-flex align-items-center py-2"
                    >
                      <span class="bullet bg-primary me-3"></span>
                      {{ qualification.text }}
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      {{ $t("common.button.close") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Modal-->

            <div class="d-flex flex-column text-gray-600 pb-5">
              <div
                v-for="(qualification, i) in visibleRequirements(
                  interview.jobPosting.jobPostingQualifications
                )"
                :key="i"
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                {{ qualification.text }}
              </div>

              <div
                v-if="
                  interview.jobPosting.jobPostingQualifications &&
                  interview.jobPosting.jobPostingQualifications.length > 3
                "
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                <em
                  >{{
                    hiddenRequirementCount(
                      interview.jobPosting.jobPostingQualifications
                    )
                  }}
                  {{ $t("modals.interview.morePieces") }}</em
                >
              </div>
            </div>
          </div>
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <!-- <div class="card-footer flex-wrap pt-0">
          <router-link to="/interviewer/choose-invite-type">
            <a class="btn btn-sm btn-primary btn-active-primary my-1 w-100">
              Aday Davet Et
            </a>
          </router-link>
        </div> -->

        <!--end::Card footer-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Sidebar-->

    <div
      class="flex-lg-row-fluid ms-lg-10"
      v-if="isCandidatesLoading"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-25px"
          src="/media/loading_icon.gif"
          style="width: 35px"
        />
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-lg-row-fluid ms-lg-10" v-else>
      <!--begin::Card-->
      <div class="card card-flush mb-6 mb-xl-9">
        <!--begin::Card header-->
        <div class="card-header pt-5">
          <!--begin::Card title-->
          <div class="card-title">
            <h2 class="d-flex align-items-center">
              {{ $t("modals.interview.jobPostingCandidates")
              }}<span
                v-if="interview.jobPosting"
                class="text-gray-600 fs-6 ms-1"
                >({{ interview.jobPosting.candidateCount }})</span
              >
            </h2>
          </div>
          <!--end::Card title-->

          <!--begin::Card toolbar-->
          <!-- <div class="card-toolbar">
            <div
              class="d-flex align-items-center position-relative my-1"
              data-kt-view-roles-table-toolbar="base"
            >
              <i class="ki-duotone ki-magnifier fs-1 position-absolute ms-6"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <input
                type="text"
                data-kt-roles-table-filter="search"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Aday ara"
              />
            </div>
          </div> -->
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div>
              <table
                class="
                  table
                  align-middle
                  table-row-dashed
                  fs-6
                  gy-5
                  mb-0
                  dataTable
                "
                id="kt_roles_view_table"
                style="width: 100%"
              >
                <thead>
                  <tr
                    class="
                      text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                  >
                    <th width="30%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.candidate.candidateInfo")
                      }}</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="30%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.lastprocessDate")
                      }}</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="20%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.status")
                      }}</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th width="20%">
                      <span class="dt-column-title">{{
                        $t("common.table.process")
                      }}</span
                      ><span class="dt-column-order"></span>
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(candidate, index) in candidateList" :key="index">
                    <td class="d-flex align-items-center ps-5">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <span
                          :class="
                            InterviewCandidateStatusClass(
                              candidate.candidate.candidateStatus
                            )
                          "
                          class="symbol-label fs-4 fw-bold"
                          >{{
                            candidate.candidate.firstName
                              ? candidate.candidate.firstName.charAt(0)
                              : ""
                          }}</span
                        >
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <router-link
                        :to="
                          '/interviewer/candidate-detail/' +
                          candidate.candidate.candidateId
                        "
                      >
                        <div class="d-flex flex-column">
                          <a class="text-gray-800 text-hover-primary mb-1">{{
                            candidate.candidate.fullName
                          }}</a>
                          <span class="text-gray-700">{{
                            candidate.candidate.email
                          }}</span>
                        </div>
                      </router-link>
                      <!--begin::User details-->
                    </td>
                    <td class="text-center">
                      {{
                        formatFullDateWithTime(candidate.candidate.lastActionAt)
                      }}
                    </td>
                    <td class="text-center">
                      <span
                        class="badge"
                        :class="
                          CandidateInterviewStatusClass(
                            candidate.candidate.candidateInterviewStatus
                          )
                        "
                      >
                        {{
                          getStatusLabel(
                            candidate.candidate.candidateInterviewStatus
                          )
                        }}
                      </span>
                    </td>
                    <td class="text-end">
                      <template
                        v-if="
                          candidate.candidate.candidateInterviewStatus ===
                            CandidateInterviewStatus.PENDING ||
                          candidate.candidate.candidateInterviewStatus ===
                            CandidateInterviewStatus.FAILED
                        "
                      >
                        <a
                          class="btn btn-sm btn-success my-1 w-100"
                          data-bs-toggle="modal"
                          :data-bs-target="'#interviewInvite' + index"
                          style="z-index: 2"
                        >
                          {{ $t("modals.interview.candidate.sendInvite") }}
                        </a>
                      </template>
                      <template
                        v-else-if="
                          candidate.candidate.candidateInterviewStatus ===
                          CandidateInterviewStatus.INVITED
                        "
                      >
                        <a
                          class="
                            btn btn-sm btn-danger btn-active-primary
                            my-1
                            w-100
                          "
                          @click="
                            revokeInterviewInvite(
                              candidate.candidate.candidateId,
                              interview.id
                            )
                          "
                        >
                          Daveti İptal Et
                        </a>
                      </template>
                      <template
                        v-else-if="
                          candidate.candidate.candidateInterviewStatus ===
                            CandidateInterviewStatus.COMPLETED ||
                          candidate.candidate.candidateInterviewStatus ===
                            CandidateInterviewStatus.PASSIVE ||
                          candidate.candidate.candidateInterviewStatus ===
                            CandidateInterviewStatus.ELIMINATED
                        "
                      >
                        <a
                          class="
                            btn btn-sm btn-primary btn-active-primary
                            my-1
                            w-100
                          "
                        >
                          <router-link
                            v-if="interview.jobPosting"
                            :to="
                              '/interviewer/jobposting-detail/' +
                              interview.jobPosting.id +
                              '/candidate/' +
                              candidate.candidate.candidateId
                            "
                            style="color: #fff"
                          >
                            {{ $t("common.button.review") }}
                          </router-link>
                        </a>
                      </template>
                    </td>
                    <div
                      class="modal fade"
                      :id="'interviewInvite' + index"
                      tabindex="-1"
                      aria-labelledby="interviewInviteLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="interviewInviteLabel">
                              {{
                                $t(
                                  "modals.interview.candidate.sendInterviewInvite"
                                )
                              }}
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>

                          <div class="modal-body mh-500px overflow-auto">
                            <Form
                              @submit="
                                inviteCandidateToInterview(
                                  candidate.candidate.candidateId,
                                  interview.id
                                )
                              "
                              :validation-schema="inviteInterviewForm"
                            >
                              <div
                                class="
                                  notice
                                  d-flex
                                  bg-light-warning
                                  rounded
                                  border-warning border border-dashed
                                  mb-10
                                  p-6
                                "
                              >
                                <div class="d-flex flex-stack flex-grow-1">
                                  <div
                                    class="fs-6 fw-bold"
                                    v-if="
                                      interview.jobPosting &&
                                      interview.jobPosting.startDate &&
                                      interview.jobPosting.endDate
                                    "
                                  >
                                    {{
                                      $t(
                                        "modals.interview.candidate.inviteCandidateInterviewJobpostingDate",
                                        {
                                          startDate: new Date(
                                            interview.jobPosting.startDate
                                          ).toLocaleString("tr-TR", {
                                            dateStyle: "short",
                                          }),
                                          endDate: new Date(
                                            interview.jobPosting.endDate
                                          ).toLocaleString("tr-TR", {
                                            dateStyle: "short",
                                          }),
                                        }
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>

                              <div class="fv-row">
                                <label
                                  class="
                                    d-flex
                                    align-items-center
                                    form-label
                                    mb-5
                                    required
                                  "
                                >
                                  {{
                                    $t(
                                      "modals.interview.candidate.expirationDate"
                                    )
                                  }}:</label
                                >
                                <div
                                  class="position-relative align-items-center"
                                >
                                  <Field
                                    class="
                                      form-control
                                      form-control-lg
                                      form-control-solid
                                    "
                                    type="date"
                                    :placeholder="
                                      $t('modals.interview.enterEndDate')
                                    "
                                    name="expirationDate"
                                    v-model="candidateInvite.expirationDate"
                                    :min="today"
                                  />
                                  <ErrorMessage
                                    name="expirationDate"
                                    class="
                                      fv-plugins-message-container
                                      invalid-feedback
                                    "
                                  ></ErrorMessage>
                                </div>
                              </div>

                              <div class="text-center pt-15">
                                <button
                                  type="reset"
                                  class="btn btn-light me-3"
                                  data-bs-dismiss="modal"
                                >
                                  {{ $t("common.button.giveUp") }}
                                </button>

                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  data-kt-roles-modal-action="submit"
                                  :disabled="isInvitingCandidateToInterview"
                                >
                                  <span v-if="!isInvitingCandidateToInterview">
                                    {{ $t("common.button.send") }}
                                  </span>
                                  <div
                                    v-else
                                    style="
                                      height: 30px;
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                    "
                                  >
                                    <img
                                      alt="Loading"
                                      src="/media/loading_icon.gif"
                                      style="width: 15px"
                                    />
                                    <span class="ms-2">{{
                                      $t("common.loading")
                                    }}</span>
                                  </div>
                                </button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
              <!--begin::Pagination-->
              <div
                class="d-flex flex-stack flex-wrap pt-10"
                v-if="candidateList.length != 0"
              >
                <!--begin::Controls-->
                <div class="d-flex flex-wrap my-1">
                  <!--begin::Select wrapper-->
                  <div class="m-0">
                    <!--begin::Select-->
                    <select
                      name="candidateStatus"
                      data-control="select2"
                      data-hide-search="true"
                      class="
                        form-select form-select-white form-select-sm
                        fw-bolder
                        w-125px
                      "
                      v-model="candidatePageSize"
                      @change="getCandidateList(1)"
                    >
                      <option value="5">5</option>
                      <option value="10" selected>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <span>{{ $t("common.table.pagination.desc2") }}</span>
                    <!-- Sayfa başı öğe sayısı -->

                    <!--end::Select-->
                  </div>
                  <!--end::Select wrapper-->
                </div>
                <!--end::Controls-->

                <div class="fs-6 fw-bold text-gray-700">
                  {{ candidateTotalItems }}
                  {{ $t("common.table.pagination.desc") }}
                  <!-- sonuç arasından -->
                  {{ (candidateCurrentPage - 1) * candidatePageSize + 1 }} -
                  {{
                    Math.min(
                      candidateCurrentPage * candidatePageSize,
                      candidateTotalItems
                    )
                  }}
                  {{ $t("common.table.pagination.desc1") }}
                  <!-- sonuç gösteriliyor. -->
                </div>

                <!--begin::Pages-->
                <ul class="pagination">
                  <li
                    class="page-item previous"
                    @click="getCandidateList(candidateCurrentPage - 1)"
                    :class="{ disabled: candidateCurrentPage === 1 }"
                  >
                    <a href="#" class="page-link"><i class="previous"></i></a>
                  </li>

                  <li
                    v-for="page in candidatePageCount"
                    :key="page"
                    class="page-item"
                    :class="{ active: candidateCurrentPage === page }"
                  >
                    <a
                      href="#"
                      class="page-link"
                      @click="getCandidateList(page)"
                      >{{ page }}</a
                    >
                  </li>

                  <li
                    class="page-item next"
                    @click="getCandidateList(candidateCurrentPage + 1)"
                    :class="{
                      disabled: candidateCurrentPage === candidatePageCount,
                    }"
                  >
                    <a href="#" class="page-link"><i class="next"></i></a>
                  </li>
                </ul>
                <!--end::Pages-->
              </div>
              <!--end::Pagination-->
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import InterviewDropdown from "@/presentation/components/dropdowns/InterviewDropdown.vue";
import { useRoute, useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import {
  dateToIsoDate,
  formatFullDateWithTime,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";
import { CandidateListByInterviewModel } from "@/domain/interviewer/candidate-list-by-interview/model/CandidateListByInterviewModel";
import {
  CandidateInterviewStatusClass,
  InterviewCandidateStatusClass,
} from "@/presentation/helper/StatusBadge";
import { CandidateInterviewStatus } from "@/domain/constants/CandidateInterviewStatus";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import Swal from "sweetalert2";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "NewInterviewDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    InterviewDropdown,
  },
  data() {
    return {
      today: dateToIsoDate(new Date()), // YYYY-MM-DD formatında bugünün tarihi
    };
  },
  methods: {
    getStatusLabel(status) {
      switch (status) {
        case CandidateInterviewStatus.COMPLETED:
          return "Tamamlandı";
        case CandidateInterviewStatus.EXPIRED:
          return "Süresi Doldu";
        case CandidateInterviewStatus.FAILED:
          return "Başarısız";
        case CandidateInterviewStatus.IN_PROGRESS:
          return "Devam Ediyor";
        case CandidateInterviewStatus.ELIMINATED:
          return "Elendi";
        case CandidateInterviewStatus.PASSIVE:
          return "Pasif";
        case CandidateInterviewStatus.INVITED:
          return "Davet Edildi";
        case CandidateInterviewStatus.PENDING:
          return "Davet Bekliyor";
        default:
          return "Bilinmiyor";
      }
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const isInvitingCandidateToInterview = ref<boolean>(false);
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const candidateInvite = ref<InterviewInviteModel>({
      expirationDate: new Date(),
    });

    const revokeInterviewInviteModel = ref<RevokeInterviewInviteModel>({});

    const interviewId = ref<any>(Number(route.params["id"]));
    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const interview = ref<InterviewDetailModel>({});
    const candidateList = ref<CandidateListByInterviewModel[]>([]);
    const originalCandidateList = ref<CandidateListByInterviewModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(10);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const inviteInterviewForm = Yup.object().shape({
      expirationDate: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? dateToIsoDate(new Date(oldDate)) : "";
      selectedInterviewId.value = id;
    };

    const getInterviewDetail = async () => {
      isLoading.value = true;

      const interviewDetailModel: InterviewDetailModel = {
        id: interviewId.value,
      };

      interviewerController
        .getInterviewDetail(interviewDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(interview.value, response.getValue());
            getCandidateList(1);
          } else {
            if (!interview.value.id) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      isCandidatesLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListByInterviewModel = {
        candidate: {
          interviewId: interviewId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateListByInterview(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const inviteCandidateToInterview = (
      candidateId?: number,
      interviewId?: number
    ) => {
      if (isInvitingCandidateToInterview.value == true) {
        return;
      }

      isInvitingCandidateToInterview.value = true;

      candidateInvite.value.candidate = candidateId;
      candidateInvite.value.interview = interviewId;
      candidateInvite.value.locale = i18n.global.locale.value;

      Swal.fire({
        title: t("swalMessages.INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .interviewInvite(candidateInvite.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidateList(1);
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
              isInvitingCandidateToInterview.value = false;
              document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const revokeInterviewInvite = (
      candidateId?: number,
      interviewId?: number
    ) => {
      revokeInterviewInviteModel.value.candidate = candidateId;
      revokeInterviewInviteModel.value.interview = interviewId;

      Swal.fire({
        title: t("swalMessages.REVOKE_INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .revokeInterviewInvite(revokeInterviewInviteModel.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(
                    SWAL_MESSAGES.SUCCESS.SUCCESS_REVOKE_INVITE_INTERVIEW
                  ),
                  icon: "success",
                }).then(() => {
                  getCandidateList(1);
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewDetail();
    });

    return {
      formatFullDateWithTime,
      CandidateInterviewStatus,
      CandidateInterviewStatusClass,
      InterviewCandidateStatusClass,
      formatLocalizedDate,
      url,
      form,
      inviteInterviewForm,
      candidateInvite,
      newEndDate,
      changeDateRef,
      selectInterview,
      isLoading,
      isCandidatesLoading,
      isInvitingCandidateToInterview,
      positionDesc,
      interviewId,
      interview,
      visibleRequirements,
      hiddenRequirementCount,
      inviteCandidateToInterview,
      revokeInterviewInvite,
      candidateList,
      candidateTotalItems,
      candidateCurrentPage,
      candidatePageCount,
      candidatePageSize,
      getCandidateList,
      originalCandidateList,
    };
  },
});
</script>
