import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import StatisticService from "../service/StatisticService";
import { IStatisticRepository } from "@/domain/statistic/repository/IStatisticRepository";
import { StatisticResponseDto } from "@/domain/statistic/dto/StatisticResponseDto";
import { REPOSITORY } from "@/application/constants/Repository";

const getStatisticService = new StatisticService();

export class StatisticRepository implements IStatisticRepository {
  async getStatistic(): Promise<Result<StatisticResponseDto>> {
    try {
      return await getStatisticService
        .get()
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<StatisticResponseDto>(response.getValue());
          } else {
            return Result.fail<StatisticResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.STATISTIC)));
    }
  }
}
