<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 class="fw-bolder d-flex align-items-center text-dark">
        {{ $t("modals.interview.createJobPostingWizard.chooseJobPosting") }}
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          :title="$t('modals.interview.createJobPostingWizard.defineJobPosting')"
        ></i>
      </h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.step1Desc") }}
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <!--begin::Label-->
      <label class="form-label mb-3">{{ $t("modals.interview.jobPosting") }}:</label>
      <!--end::Label-->

      <!--begin::Select-->
      <Field
        as="select"
        name="jobPostingId"
        v-model="jobPostingId"
        @change="sendJobPosting"
        class="
          form-select form-control form-select-md form-control-solid
          fw-bolder
          mb-1
        "
      >
        <option value="0" disabled selected>{{ $t("common.pleaseChoose") }}</option>
        <option
          v-for="job in jobPostingList"
          :key="job.jobPosting.jobPostingId"
          :value="job.jobPosting.jobPostingId"
        >
          {{ job.jobPosting.title }}
        </option>
      </Field>
      <ErrorMessage
        name="jobPostingId"
        class="fv-plugins-message-container invalid-feedback"
      />

      <!--end::Select-->
    </div>
    <!--end::Input group-->

    <div class="text-center my-4">
      <span class="fw-bold text-gray-900">{{ $t("common.or") }}</span>
    </div>

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <div class="text-center my-4">
        <router-link to="/interviewer/new-jobposting">
          <button type="button" class="btn btn-success">
            {{ $t("modals.interview.createJobPostingWizard.step1NewJobposting") }}
          </button></router-link
        >
      </div>
    </div>
    <!--end::Input group-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { useStore } from "vuex";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import SwalNotification from "@/presentation/plugins/SwalNotification";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
    VueEditor,
  },
  setup(props, { emit }) {
    const jobPostingId = ref(0);
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const { t } = useI18n();

    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const swalNotification = new SwalNotification();

    const getJobPostingList = async (page) => {
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interviews: [],
          status: "ACTIVE, PENDING, PASSIVE",
        },
        page: page,
        pageSize: pageSize.value,
      };

      interviewerController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              totalItems.value = jobPosting.pagination.total;
              currentPage.value = jobPosting.pagination.page;
              pageCount.value = jobPosting.pagination.pageCount;

              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const sendJobPosting = () => {
      // Emit selected job posting to parent component
      if (jobPostingId.value) {
        emit("updateJobPosting", jobPostingId.value);
      }
    };

    onMounted(() => {
      getJobPostingList(1);
    });

    return {
      jobPostingId,
      jobPostingList,
      sendJobPosting,
    };
  },
});
</script>
