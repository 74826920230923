import { BaseDomain } from "@/core/base/BaseDomain";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";

export class CandidateInterviewLogsModel extends BaseDomain {
  candidate?: number;
  interview?: number;
    message: {
      messageId?: number;
      owner?: string;
      text?: string;
      isLiked?: string;
      createdAt?: Date;
      voice?: VoiceResponse;
    };

  constructor(
    candidate?: number,
    interview?: number,
    messageId?: number,
    owner?: string,
    text?: string,
    isLiked?: string,
    createdAt?: Date,
  ) {
    super();
    this.candidate = candidate;
    this.interview = interview;
    this.message ={
    messageId: messageId,
    owner: owner,
    text: text,
    isLiked: isLiked,
    createdAt: createdAt,
    }
  }
}
