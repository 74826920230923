
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICreateInterviewUseCase } from "@/domain/interviewer/create-interview/usecase/ICreateInterviewUsecase";
import { ICreateInterviewRepository } from "@/domain/interviewer/create-interview/repository/ICreateInterviewRepository";
import { CreateInterviewMapper } from "../mapper/CreateInterviewMapper";
import { CreateInterviewRepository } from "../repository/CreateInterviewRepository";
import { CreateInterviewModel } from "@/domain/interviewer/create-interview/model/CreateInterviewModel";

export class CreateInterviewUsecase implements ICreateInterviewUseCase {
  createInterviewRepository : ICreateInterviewRepository = new CreateInterviewRepository();
  createInterviewMapper = new CreateInterviewMapper();

  constructor() {
  }

  async execute(model: CreateInterviewModel): Promise<Result<CreateInterviewModel>> {

    try {
      const dto = this.createInterviewMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CreateInterviewModel>(dto.getError());
      } else {
        const response = await this.createInterviewRepository.createInterview(dto);

        if (response.isSuccess) {
          const domain = this.createInterviewMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CreateInterviewModel>(domain.getValue());
        } else {
          return Result.fail<CreateInterviewModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CreateInterviewModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CREATE_INTERVIEW))
      );
    }
  }
}
