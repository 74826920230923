import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { InterviewDetailResponseDto } from "@/domain/interviewer/interview-detail/dto/InterviewDetailResponseDto";
import { IInterviewDetailRepository } from "@/domain/interviewer/interview-detail/repository/IInterviewDetailRepository";
import { InterviewDetailRequestDto } from "@/domain/interviewer/interview-detail/dto/InterviewDetailRequestDto";
import InterviewDetailService from "../service/InterviewDetailService";

const jobPostingDetailService = new InterviewDetailService();

export class InterviewDetailRepository implements IInterviewDetailRepository{
  async getInterviewDetail(request: InterviewDetailRequestDto): Promise<Result<InterviewDetailResponseDto>> {
    try {
      return await jobPostingDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<InterviewDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<InterviewDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.INTERVIEW_DETAIL)));
    }
  }
}
