import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { StatisticUsecase } from "@/application/statistic/usecase/StatisticUsecase";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";

export function createStatisticController() {
  const useCases = {
    getStatistic: {
      useCase: new StatisticUsecase(),
      model: StatisticModel,
    },
  };

  return new StatisticController(useCases);
}

export class StatisticController implements IController {
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
