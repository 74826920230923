import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IDeleteQuestionOptionService } from "@/domain/interviewer/update-interview/deleteQuestionOption/service/IDeleteQuestionOptionService";
import { DeleteQuestionOptionRequestDto } from "@/domain/interviewer/update-interview/deleteQuestionOption/dto/DeleteQuestionOptionRequestDto";

export default class DeleteQuestionOptionService implements IDeleteQuestionOptionService {
  async delete(
    request: DeleteQuestionOptionRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .delete<any>(API_ROUTE.INTERVIEW_QUESTION_OPTIONS + request.id)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_DELETE_QUESTION_OPTION)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.DELETE_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
