import { BaseDomain } from "@/core/base/BaseDomain";
import { InterviewQuestionModel } from "../../model/InterviewQuestionsModel";

export class CandidateInterviewResponsesModel extends BaseDomain {
  candidate?: number;
  interview?: number;
  interviewQuestions: InterviewQuestionModel[];

  constructor(
    interviewQuestions: InterviewQuestionModel[],
    candidate?: number,
    interview?: number,
  ) {
    super();
    this.interviewQuestions = interviewQuestions;
    this.candidate = candidate;
    this.interview = interview;
  }
}
