import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IDeleteStaffRepository } from "@/domain/staff/delete-staff/repository/IDeleteStaffRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeleteStaffService from "../service/DeleteStaffService";
import { DeleteStaffRequestDto } from "@/domain/staff/delete-staff/dto/DeleteStaffRequestDto";

const deleteStaffService = new DeleteStaffService();

export class DeleteStaffRepository implements IDeleteStaffRepository {
  async deleteStaff(request: DeleteStaffRequestDto): Promise<Result<any>> {
    try {
      return await deleteStaffService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.STAFF.DELETE_STAFF)));
    }
  }
}
