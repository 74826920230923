import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateInterviewModel } from "@/domain/interviewer/update-interview/model/UpdateInterviewModel";
import { UpdateInterviewRequestDto } from "@/domain/interviewer/update-interview/dto/UpdateInterviewRequestDto";

export class UpdateInterviewMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateInterviewModel> {
    try {
        const updateInterviewModel: UpdateInterviewModel = {
          ok: dto.ok,
        };
        return Result.ok(updateInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW))
      );
    }
  }

  mapToDTO(domain: UpdateInterviewModel): Result<UpdateInterviewRequestDto> {
    try {
      const updateInterview: UpdateInterviewRequestDto = {
        id: domain.id,
        data: {
          title: domain.title,
          description: domain.description,
        }
      };

      return Result.ok(updateInterview);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW))
      );
    }
  }
}
