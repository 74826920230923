
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDeleteInterviewUseCase } from "@/domain/interviewer/delete-interview/usecase/IDeleteInterviewUseCase";
import { DeleteInterviewRepository } from "../repository/DeleteInterviewRepository";
import { IDeleteInterviewRepository } from "@/domain/interviewer/delete-interview/repository/IDeleteInterviewRepository";
import { DeleteInterviewMapper } from "../mapper/DeleteInterviewMapper";
import { DeleteInterviewModel } from "@/domain/interviewer/delete-interview/model/DeleteInterviewModel";

export class DeleteInterviewUseCase implements IDeleteInterviewUseCase {
  deleteInterviewRepository : IDeleteInterviewRepository = new DeleteInterviewRepository();
  deleteInterviewMapper = new DeleteInterviewMapper();

  constructor() {
  }

  async execute(model: DeleteInterviewModel): Promise<Result<DeleteInterviewModel>> {
    try {
      const dto = this.deleteInterviewMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteInterviewModel>(dto.getError());
      } else {
        const response = await this.deleteInterviewRepository.deleteInterview(dto);

        if (response.isSuccess) {
          const domain = this.deleteInterviewMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteInterviewModel>(domain.getValue());
        } else {
          return Result.fail<DeleteInterviewModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteInterviewModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.DELETE_INTERVIEW))
      );
    }
  }
}
