import { BaseDTO } from "@/core/base/BaseDTO";

export class RevokeInterviewInviteRequestDto extends BaseDTO {
  data: {
    interview?: number;
    candidate?: number;
  }
  constructor(
    interview?: number,
    candidate?: number,
  ) {
    super();
    this.data = {
      interview,
      candidate,
    }
  }
}
