import { CandidateActionStatus } from "@/domain/constants/CandidateActionStatus";
import { CandidateInterviewStatus } from "@/domain/constants/CandidateInterviewStatus";
import { CandidatesInterviewStatus } from "@/domain/constants/CandidatesInterviewStatus";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";

export const JobPostingStatusBadge = (status): string => {
  switch (status) {
    case JobPostingStatus.ACTIVE:
      return "badge badge-light-success";
    case JobPostingStatus.PENDING:
      return "badge badge-light-warning";
    case JobPostingStatus.PASSIVE:
      return "badge badge-light-danger";
    case JobPostingStatus.COMPLETED:
      return "badge badge-light-info";
    default:
      return "badge badge-light-secondary";
  }
};

export const CandidateActionBadge = (status): string => {
  switch (status) {
    case CandidateActionStatus.INVITED:
      return "badge badge-warning";
    case CandidateActionStatus.COMPLETED:
      return "badge badge-success";
    case CandidateActionStatus.FAILED:
      return "badge badge-danger";
    default:
      return "badge badge-secondary";
  }
};

export const CandidatesInterviewStatusText = (status): string => {
  switch (status) {
    case CandidatesInterviewStatus.COMPLETED:
      return "Tamamlandı";
    case CandidatesInterviewStatus.PENDING_ACTION:
      return "Davet Gönder";
    case CandidatesInterviewStatus.FAILED:
      return "Başarısız";
    case CandidatesInterviewStatus.INVITED:
      return "Daveti İptal Et";
    default:
      return "Bilinmeyen Durum";
  }
};

export const CandidatesInterviewStatusBadge = (status): string => {
  switch (status) {
    case CandidatesInterviewStatus.COMPLETED:
      return "badge-light-success";
    case CandidatesInterviewStatus.PENDING_ACTION:
      return "badge-light-primary";
    case CandidatesInterviewStatus.FAILED:
      return "badge-light-danger";
    case CandidatesInterviewStatus.INVITED:
      return "badge-light-warning";
    default:
      return "badge-light-secondary";
  }
};

export const CandidatesInterviewStatusBorderColor = (status): string => {
  switch (status) {
    case CandidatesInterviewStatus.COMPLETED:
      return "border-success";
    case CandidatesInterviewStatus.PENDING_ACTION:
      return "border-primary";
    case CandidatesInterviewStatus.FAILED:
      return "border-danger";
    case CandidatesInterviewStatus.INVITED:
      return "border-warning";
    default:
      return "border-secondary";
  }
};

export const InterviewCandidateStatusClass = (status): string => {
  switch (status) {
    case InterviewCandidateStatus.ACTIVE:
      return "bg-light-success text-success";
    case InterviewCandidateStatus.PASSIVE:
      return "bg-light-warning text-warning";
    case InterviewCandidateStatus.ELIMINATED:
      return "bg-light-danger text-danger";
    default:
      return "bg-light-secondary text-secondary"; // Varsayılan bir renk
  }
};

export const CandidateInterviewStatusClass = (status): string => {
  switch (status) {
    case CandidateInterviewStatus.COMPLETED:
      return "badge-light-success";
    case CandidateInterviewStatus.EXPIRED:
      return "badge-light-info";
    case CandidateInterviewStatus.FAILED:
      return "badge-light-danger";
    case CandidateInterviewStatus.IN_PROGRESS:
      return "badge-light-warning";
    case CandidateInterviewStatus.ELIMINATED:
      return "badge-light-danger";
    case CandidateInterviewStatus.PASSIVE:
      return "badge-light-primary";
    case CandidateInterviewStatus.INVITED:
      return "badge-light-primary";
    case CandidateInterviewStatus.PENDING:
      return "badge-secondary";
    default:
      return "badge-secondary"; // Varsayılan bir renk
  }
};
