import { BaseDomain } from "@/core/base/BaseDomain";
import { EmotionAnalysisModel } from "@/domain/emotion-analysis/model/EmotionAnalysisModel";

export class CandidateInterviewModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  sequence?: number;
  interviewCandidateStatus?: string;
  emotionAnalysis?: EmotionAnalysisModel;
  interviewAnalysisScore?: number;

  constructor(
    id?: number,
    title?: string,
    description?: string,
    sequence?: number,
    interviewCandidateStatus?: string,
    emotionAnalysis?: EmotionAnalysisModel,
    interviewAnalysisScore?: number
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.sequence = sequence;
    this.interviewCandidateStatus = interviewCandidateStatus;
    this.emotionAnalysis = emotionAnalysis;
    this.interviewAnalysisScore = interviewAnalysisScore;
  }
}
