import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdatePollModel extends BaseDomain {
  id?: number;
  ok?: boolean;
  completed?: boolean;
  endDate?: Date;

  constructor(id?: number, ok?: boolean, completed?: boolean, endDate?: Date) {
    super();
    this.id = id;
    this.ok = ok;
    this.completed = completed;
    this.endDate = endDate;
  }
}
