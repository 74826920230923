import { BaseDTO } from "@/core/base/BaseDTO";

export class AddQuestionOptionRequestDto extends BaseDTO {
  data: {
    interviewQuestion?: number;
    optionText?: string;
  };
  constructor(interviewQuestion?: number, optionText?: string) {
    super();
    this.data = {
      interviewQuestion,
      optionText
    };
  }
}
