import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import SendMessageService from "../service/SendMessageService";
import { ISendMessageRepository } from "@/domain/hotel-management/send-message/repository/ISendMessageRepository";
import { SendMessageRequestDto } from "@/domain/hotel-management/send-message/dto/SendMessageRequestDto";
import { SendMessageResponseDto } from "@/domain/hotel-management/send-message/dto/SendMessageResponseDto";

const sendMessageService = new SendMessageService();

export class SendMessageRepository implements ISendMessageRepository {
  async sendMessage(
    request: SendMessageRequestDto
  ): Promise<Result<SendMessageResponseDto>> {
    try {
      return await sendMessageService
        .generate(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<SendMessageResponseDto>(response.getValue());
          } else {
            return Result.fail<SendMessageResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<SendMessageResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<SendMessageResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.SEND_MESSAGE))
      );
    }
  }
}
