<template>
  <!--begin::Card body-->
  <div
    v-if="isLoading"
    style="
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-50px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>
  <div
    v-else
    class="row mt-5"
    v-for="(log, index) in pollChatLogs"
    :key="index"
  >
    <MessageIn
      v-if="log.poll.owner == 'AI'"
      ref="messagesInRef"
      :text="log.poll.text"
      :id="log.poll.id"
      :voice="log.poll.voice"
    ></MessageIn>
    <MessageOut v-else ref="messagesOutRef" :text="log.poll.text"
    :voice="log.poll.voice"
    :video="log.poll.video"
    :id="log.poll.id"></MessageOut>
    
  </div>

  <!-- :emotionAnalysis="item.emotionAnalysis" -->

  <!--end::Card body-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";

export default defineComponent({
  name: "userPollDetailChatLog",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const pollId = ref<number>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: pollId.value,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollChatLogs();
    });

    return {
      pollChatLogs,
      isLoading,
    };
  },
});
</script>
