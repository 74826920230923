<template>
  <div
   class="d-flex justify-content-center align-items-center h-auto"
  >
    <div class="justify-content-center" style="margin-top: 0px; margin-top: 10%">
      <div class="card" style="width: 500px; height: 400px">
        <div
          class="card-body d-flex flex-column"
          style="display: flex; justify-content: center; align-items: center"
        >
          <!--begin::Indicator-->
          <div class="row">
            <div
              class="
                d-flex
                align-items-center
                flex-wrap
                mb-5
                mt-auto
                fs-6
                justify-content-center
                flex-grow-1
              "
            >
              <img
                alt="Logo"
                class="h-70px w-70px"
                src="/media/avatars/userAccount.png"
                style="width: 40px"
              />
            </div>
            <div
              class="
                d-flex
                align-items-center
                flex-wrap
                mb-5
                mt-auto
                fs-6
                justify-content-center
                flex-grow-1
              "
            >
              <div class="fw-bolder mt-2" style="padding-left: 0px">
                <a style="color: black; font-size: 24px">{{ $t("accountTypes.basic") }}</a>
                <!--
                <span class="badge bg-light text-gray-700 px-3 py-2 me-2">
                  Satın Alınabilir
                </span>
                -->
                <!--begin::Number-->
                <div
                  class="text-success text-center fw-bolder me-2"
                  style="font-size: 16px"
                >
                  {{ $t("accountTypes.yourActiveAccount") }}
                </div>
                <!--end::Number-->
              </div>
            </div>
          </div>
          <!--end::Indicator-->

          <!--begin::Row-->
          <div class="row">
            <!--begin::Tab content-->
            <div class="tab-content rounded h-100 p-10 pt-2 pb-2">
              <template v-for="(plan, index) in plans" :key="index">
                <!--begin::Tab Pane-->
                <div
                  v-if="!plan.custom"
                  :class="[plan.default && 'show active']"
                  class="tab-pane fade"
                  :id="`kt_upgrade_plan_${index}`"
                >
                  <!--begin::Body-->
                  <div class="pt-1">
                    <template v-for="(feature, i) in plan.features" :key="i">
                      <!--begin::Item-->
                      <div
                        :class="[i !== plan.features - 1 && 'mb-4']"
                        class="d-flex align-items-center"
                      >
                        <template v-if="feature.supported">
                          <span class="fw-bold fs-6 text-gray-700 flex-grow-1">
                            {{ feature.title }}
                          </span>

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <inline-svg
                              src="/media/icons/duotune/general/gen043.svg"
                            />
                          </span>
                        </template>
                        <template v-else>
                          <span class="fw-bold fs-6 text-gray-400 flex-grow-1">
                            {{ feature.title }}
                          </span>
                          <span class="svg-icon svg-icon-1">
                            <inline-svg
                              src="/media/icons/duotune/general/gen040.svg"
                            />
                          </span>
                        </template>
                      </div>
                      <!--end::Item-->
                    </template>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Tab Pane-->
              </template>
            </div>
            <!--end::Tab content-->
          </div>
          <!--end::Row-->

          <!--end::Button-->
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-types",
  components: {},
  setup() {
    const { t } = useI18n();

    const plans = [
      {
        default: true,
        custom: false,
        features: [
          {
            title: " AI Otel Asistanı'na erişilebilirlik",
            supported: true,
          },
          {
            title: " Anketör modülüne erişilebilirlik",
            supported: true,
          },
          {
            title: " Mobil ve web üzerinden kolay erişim",
            supported: true,
          },
          {
            title: "Yeni eklenen modülleri satın alma",
            supported: false,
          },
        ],
      },
    ];

    onMounted(() => {
      setCurrentPageTitle(t("accountTypes.title"));
    });

    return { plans };
  },
});
</script>
