import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { DeleteInterviewModel } from "@/domain/interviewer/delete-interview/model/DeleteInterviewModel";
import { DeleteInterviewRequestDto } from "@/domain/interviewer/delete-interview/dto/DeleteInterviewRequestDto";

export class DeleteInterviewMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteInterviewModel> {
    try {
        const deleteInterviewModel: DeleteInterviewModel = {
          ok: dto.ok,
        };
        return Result.ok(deleteInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW))
      );
    }
  }

  mapToDTO(domain: DeleteInterviewModel): Result<DeleteInterviewRequestDto> {
    try {
      const deleteInterviewRequest: DeleteInterviewRequestDto = {
        id: domain.id,
      };

      return Result.ok(deleteInterviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW))
      );
    }
  }
}
