<template>
  <div
    v-if="isLoading"
    style="
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-50px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>
  <div v-else>
    <div class="card mb-5 mb-xl-10 pb-9">

<!--begin::Card body-->
    <div class="row mt-5">
      <div class="col-md-6 d-flex justify-content-center">
        <!--begin::Details-->
        <a class="fs-5 fw-bolder text-gray-900 text-hover-primary">{{ $t("modals.pollster.question") }}</a>
        <!--end::Details-->
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <a class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
          >{{ $t("modals.pollster.userAnswer") }}</a
        >
      </div>
    </div>
    <div class="row mt-5" v-for="(item, index) in pollResponse" :key="index">
      <div class="col-md-6 d-flex justify-content-end" v-if="item">
        <ResponseIn ref="messagesInRef" :text="item.questionText"></ResponseIn>
      </div>
      <div class="col-md-6 d-flex justify-content-start">
        <ResponseOut v-if="item.surveyQuestionResponse"
          ref="messagesOutRef"
          :text="item.surveyQuestionResponse"
        ></ResponseOut>
        <ResponseOut v-else
          ref="messagesOutRef"
          text=""
        ></ResponseOut>
      </div>
    </div>
  </div> 
  </div>
  <!-- :emotionAnalysis="item.emotionAnalysis" -->

  <!--end::Form-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";

export default defineComponent({
  name: "userPollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const pollId = ref<any>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: pollId.value,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
    });

    return {
      pollResponse,
      isLoading
    };
  },
});
</script>
