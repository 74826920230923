import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { InviteUserResponseDto } from "@/domain/pollster/Invite-user/dto/InviteUserResponseDto";
import { InviteUserRequestDto } from "@/domain/pollster/Invite-user/dto/InviteUserRequestDto";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";

export class InviteUserMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: InviteUserResponseDto): Result<InviteUserModel> {
    try {
      const inviteUserModel: InviteUserModel = {
        id: dto.data.id,
      };

      return Result.ok(inviteUserModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.INVITE_USER))
      );
    }
  }

  mapToDTO(domain: InviteUserModel): Result<InviteUserRequestDto> {
    try {
      const inviteUserRequest = new InviteUserRequestDto(
        Number(domain.survey),
        domain.emails,
        domain.locale
      );

      return Result.ok(inviteUserRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.INVITE_USER))
      );
    }
  }
}
