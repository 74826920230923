export const CandidateInterviewStatus = {
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
  FAILED: "FAILED",
  IN_PROGRESS: "IN_PROGRESS",
  ELIMINATED: "ELIMINATED",
  PASSIVE: "PASSIVE",
  INVITED: "INVITED",
  PENDING: "PENDING",
};

