<template>
  <div class="row g-6">
    <div class="col-xl-4">
      <div class="card" style="width: 700px">
        <div class="card-body d-flex flex-column">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-2">
              {{ $t("modals.interview.startInterview.title") }}
            </div>
            <span class="text-muted fs-7 mb-2">{{
              $t("modals.interview.startInterview.desc")
            }}</span>
            <!--end::Title-->
          </div>
          <!--end::Heading-->

          <div
            v-if="isLoading"
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="text-center px-5 pt-10 pb-5">
              <img
                alt="Logo"
                class="max-h-5px"
                src="/media/loading_icon.gif"
                style="width: 50px"
              />
            </div>
          </div>

          <!--begin::Form-->
          <Form
            v-else
            id="kt_account_profile_details_form"
            class="form"
            @submit="submit"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-7">
                <!-- İlan Başlığı -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">
                        {{ $t("modals.interview.startInterview.jobPosting") }}
                      </div>
                      <h4 class="mt-2 text-dark">
                        {{ jobPostingDetail.jobPostingTitle }}
                      </h4>
                    </div>
                  </div>
                </div>

                <!-- Mülakat Türü -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">
                        {{
                          $t("modals.interview.startInterview.interviewType")
                        }}
                      </div>
                      <h4 class="mt-2 text-primary">
                        {{ jobPostingDetail.interviewTitle }}
                      </h4>
                    </div>
                  </div>
                </div>

                <!-- Soru Sayısı -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">
                        {{
                          $t("modals.interview.startInterview.questionCount")
                        }}
                      </div>
                      <h4 class="mt-2 text-dark">
                        {{ jobPostingDetail.interviewQuestionCount }}
                      </h4>
                    </div>
                  </div>
                </div>

                <!-- Süre -->
                <div class="col-md-6 mb-5">
  <div class="card border border-gray-300 shadow-sm rounded">
    <div class="card-body text-center">
      <div class="fw-bold text-gray-600">
        {{
          $t("modals.interview.startInterview.estimatedTime")
        }}
      </div>
      <h4 class="mt-2 text-dark">
        {{ estimatedTime() }}
      </h4>
    </div>
  </div>
</div>
              </div>

              <!-- Önemli Bilgiler ve Onaylar -->
              <div class="row">
                <div class="col-md-12">
                  <div class="fw-bold mb-3">
                    <h6>
                      {{
                        $t(
                          "modals.interview.startInterview.importantInfoAndAgreements"
                        )
                      }}
                    </h6>
                    <ul>
                      <li>{{ $t("modals.interview.startInterview.info1") }}</li>
                      <li>{{ $t("modals.interview.startInterview.info2") }}</li>
                      <li>{{ $t("modals.interview.startInterview.info3") }}</li>
                      <li>{{ $t("modals.interview.startInterview.info4") }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!--begin::KVKK Onay-->
              <div class="row mt-4 mb-7">
                <div class="col-md-12">
                  <div class="fv-row">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="kvkkConsent"
                        v-model="kvkkConsent"
                      />
                      <label class="form-check-label" for="kvkkConsent">
                        {{ $t("modals.interview.startInterview.kvkk") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::KVKK Onay-->

              <!--begin::Submit Button-->
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  class="btn btn-primary"
                  :disabled="!kvkkConsent"
                >
                  <span class="indicator-label">{{
                    $t("common.button.start")
                  }}</span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Submit Button-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { convertSecondsToTime } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "start-interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let key: undefined | string = undefined;
    const isLoading = ref<boolean>(false);

    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();

    const kvkkConsent = ref(false); // KVKK onay durumu

    const jobPostingDetail = ref<JobPostingDetailByInviteModel>({});

    const getJobPostingDetail = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailByInviteModel = {
        key: key,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetailByInvite(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPostingDetail.value, response.getValue());
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = (values: any) => {
      router.push({ name: ROUTE_PAGES.DO_INTERVIEW, query: { key: key } });
    };

    const estimatedTime = () => {
  // Soruların sayısına göre toplam süreyi hesapla
  if (jobPostingDetail.value?.interviewQuestionCount) {
    const totalSeconds = jobPostingDetail.value.interviewQuestionCount * 30;
    return convertSecondsToTime(totalSeconds); // TimeConverter ile formatla
  }
  return '0 dakika 0 saniye'; // Veri yoksa varsayılan değer
};

    onMounted(() => {
      key = route.query.key?.toString();
      setCurrentPageTitle(t("modals.interview.startInterview.title"));
      getJobPostingDetail();
    });

    return {
      submit,
      isLoading,
      kvkkConsent, // KVKK onay durumu
      jobPostingDetail,
      estimatedTime,
    };
  },
});
</script>
