<template>
  <div id="kt_app_content_container" class="app-container container-xxl">
    <div
      v-if="isCandidateLoading"
      style="
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-25px"
          src="/media/loading_icon.gif"
          style="width: 35px"
        />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-4">
        <div class="card mb-5 mb-xl-8">
          <div
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <!-- Title or any other content can go here -->
            <span class="fw-bold fs-4">{{
              $t("modals.interview.candidate.candidateInfo")
            }}</span>

            <!-- Three dots icon for dropdown -->
            <div class="text-center">
              <button
                class="
                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>
              <CandidateDropdown
                :candidateId="candidateId"
                :candidateStatus="candidate.candidateStatus"
                :hasInterviewSession="candidate.hasInterviewSession"
                :jobPostingId="candidate.jobPostingId"
              />
            </div>
          </div>

          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Summary-->
            <div class="d-flex flex-center flex-column mb-5">
              <!--begin::Avatar-->
              <div class="symbol symbol-100px symbol-circle mb-7">
                <!--begin:: Avatar -->
                <div class="symbol symbol-circle symbol-50px overflow-hidden">
                  <a v-if="candidate.candidateStatus">
                    <div
                      class="symbol-label fs-1 pt-1"
                      :class="
                        InterviewCandidateStatusClass(candidate.candidateStatus)
                      "
                      v-if="candidate.firstName"
                    >
                      {{ candidate.firstName.charAt(0) }}
                    </div>
                  </a>
                </div>
                <!--end::Avatar-->
              </div>
              <!--end::Avatar-->

              <!--begin::Ad-->
              <a
                href="#"
                class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
              >
                {{ candidate.fullName }}
              </a>
              <!--end::Ad-->

              <!--begin::Badge-->
              <span
                v-if="!candidate.candidateStatus"
                class="badge badge-light-success"
              >
                {{ $t("modals.interview.candidate.activeCandidate") }}
              </span>
              <div v-else>
                <span
                  v-if="
                    candidate.candidateStatus ===
                    InterviewCandidateStatus.ACTIVE
                  "
                  class="badge badge-light-success"
                >
                  {{ $t("modals.interview.candidate.activeCandidate") }}
                </span>
                <span
                  v-else-if="
                    candidate.candidateStatus ===
                    InterviewCandidateStatus.PASSIVE
                  "
                  class="badge badge-light-warning"
                >
                  {{ $t("modals.interview.candidate.deActiveCandidate") }}
                </span>
                <span
                  v-else-if="
                    candidate.candidateStatus ===
                    InterviewCandidateStatus.ELIMINATED
                  "
                  class="badge badge-light-danger"
                >
                  {{ $t("modals.interview.candidate.eliminated") }}
                </span>
              </div>
              <!--begin::Badge-->
              <!-- <a
                class="
                  btn btn-sm btn-secondary
                  mb-2 mb-md-0
                  me-md-2
                  mt-4
                  flex-grow-1
                "
              >
                Cv İndir
              </a> -->
            </div>

            <div class="d-flex flex-wrap flex-center">
              <!--begin::Stats-->
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded
                  py-3
                  px-3
                  mb-3
                "
              >
                <div
                  class="fs-4 fw-bold text-gray-700"
                  v-if="candidate.positionSuitability"
                >
                  <span class="w-75px"
                    >{{ candidate.positionSuitability }}%</span
                  >
                </div>
                <div class="fs-4 fw-bold text-gray-700" v-else>
                  <span class="w-75px">
                    {{ $t("modals.interview.candidate.noPositionSuitability") }}
                  </span>
                </div>
                <div class="fw-semibold text-muted">
                  {{ $t("modals.interview.candidate.positionEligibility") }}
                </div>
              </div>
              <!--end::Stats-->
              <!--begin::Stats-->
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded
                  py-3
                  px-3
                  mb-3
                  ms-3
                "
              >
                <div class="fs-4 fw-bold text-gray-700">
                  <div
                    v-if="
                      (candidate.CvAnalysisStatus == 'IN_PROGRESS' &&
                        isCvAnalysisLoading) ||
                      ((!candidate.CvAnalysisScore ||
                        candidate.CvAnalysisScore == null) &&
                        candidate.CvAnalysisStatus == 'IN_PROGRESS')
                    "
                    class="d-flex justify-content-center align-items-center"
                  >
                    <!-- Spinner'ı ortalamak için d-flex kullanıyoruz -->
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">{{ $t("common.loading") }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <span class="w-75px">{{ candidate.CvAnalysisScore }}%</span>
                    <button
                      v-if="!candidate.CvAnalysisScore"
                      class="btn btn-icon h-25px btn-sm ms-2"
                      @click="reloadCVAnalysis()"
                    >
                      <span
                        class="svg-icon svg-icon-sm svg-icon-1 svg-icon-primary"
                        title="İş İlanına Göre CV Analiz Et"
                      >
                        <inline-svg
                          src="/media/icons/duotune/abstract/abs037.svg"
                        />
                      </span>
                    </button>
                    <i class="ki-duotone ki-arrow-up fs-3 text-success">
                      <span class="path1"></span><span class="path2"></span>
                    </i>
                  </div>
                </div>
                <div class="fw-semibold text-muted">
                  {{ $t("modals.interview.candidate.cvAnalysis") }}
                </div>
              </div>

              <!--end::Stats-->
            </div>
            <!--end::Summary-->

            <!--begin::Details content-->
            <div id="kt_customer_view_details" class="collapse show">
              <div class="py-2 fs-6">
                <!--begin::Details item-->
                <div class="fw-bold mt-5">
                  {{ $t("modals.interview.candidate.email") }}
                </div>
                <div class="text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary">{{
                    candidate.email
                  }}</a>
                </div>
                <!--end::Details item-->
              </div>
            </div>

            <div class="separator separator-dashed my-3"></div>
            <!--begin::Details toggle-->

            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-content-between
                mt-2
              "
            >
              <a
                class="
                  btn btn-sm btn-primary
                  mb-2 mb-md-0
                  me-md-2
                  flex-grow-1
                  w-100
                "
                @click="downloadCV"
              >
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/files/fil009.svg" />
                </span>
                {{ $t("modals.interview.candidate.downloadCV") }}
              </a>

              <!-- <a
                class="
                  btn btn-sm btn-danger
                  mb-2 mb-md-0
                  me-md-2
                  flex-grow-1
                  w-100
                "
              >
                Adayı Sil
              </a> -->
            </div>
            <!--end::Details toggle-->

            <!--end::Details content-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
      <div class="col-md-8">
        <div class="card pt-4 mb-6 mb-xl-9">
          <!--begin::Card header-->
          <div class="card-header border-0">
            <!--begin::Card title-->
            <div class="card-title d-flex justify-content-between">
              <h2>{{ $t("modals.interview.candidate.appliedJobPosting") }}:</h2>
              <div class="badge badge-light-info d-inline fs-7 ms-3">
                {{ candidate.jobPostingTitle }}
              </div>
            </div>
            <!--end::Card title-->
            <!--begin::Position Status-->
            <!-- <div class="badge badge-light-success fs-6">
              Pozisyon Uygunluğu: Uygun
            </div> -->
            <!--end::Position Status-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div
            class="card-body pt-0 mb-5 mt-5"
            v-if="candidatesInterviewList.length > 0"
          >
            <!--begin::Mülakat Aşamaları-->
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
                gap-3
              "
            >
              <div
                v-for="(interview, index) in candidatesInterviewList"
                :key="interview.id"
                class="text-center flex-fill position-relative"
              >
                <!-- Icon -->
                <div class="m-2">
                  <i class="bi bi-arrow-right-circle fs-2 text-primary"></i>
                </div>

                <!-- Title -->
                <div class="badge badge-light fs-5 mb-3">
                  {{ interview.title }}
                  {{ interview.id }}
                </div>
                <!-- Status -->
                <div v-if="interview.interviewCandidateStatus ==
                    CandidatesInterviewStatus.INVITED">
                     <button
                     @click="revokeInterviewInvite(candidate.id, interview.id)"
                      class=" btn btn-sm btn-danger btn-active-primary"
                      style="z-index: 2"
                    >
                    Daveti İptal Et
                    </button>
                    </div>
                    <div v-else-if="interview.interviewCandidateStatus ==
                    CandidatesInterviewStatus.PENDING_ACTION">
                     <div
                    v-if="
                      candidate.candidateStatus ==
                        InterviewCandidateStatus.ACTIVE
                    "
                  >
                    <button
                      data-bs-toggle="modal"
                      :data-bs-target="'#interviewInvite' + index"
                      class="btn btn-sm btn-success"
                      style="z-index: 2"
                    >
                      {{ $t("modals.interview.candidate.sendInvite") }}
                    </button>
                  </div>
                  </div>
                  <div v-else>
                    <span
                    :class="[
                      'badge fs-7',
                      CandidatesInterviewStatusBadge(
                        interview.interviewCandidateStatus
                      ),
                    ]"
                  >
                    {{
                      CandidatesInterviewStatusText(
                        interview.interviewCandidateStatus
                      )
                    }}
                  </span>
                  </div>

                <!-- Border -->
                <div
                  class="
                    border-top
                    position-absolute
                    top-0
                    start-0
                    w-100
                    h-100
                    rounded-3
                    border-4
                  "
                  :class="
                    CandidatesInterviewStatusBorderColor(
                      interview.interviewCandidateStatus
                    )
                  "
                  style="border-radius: 15px; pointer-events: none"
                ></div>

                <!--end::Mülakat Aşamaları-->
                <div
                  class="modal fade"
                  :id="'interviewInvite' + index"
                  tabindex="-1"
                  aria-labelledby="interviewInviteLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="interviewInviteLabel">
                          {{
                            $t("modals.interview.candidate.sendInterviewInvite")
                          }}
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="modal-body mh-500px overflow-auto">
                        <Form
                          @submit="inviteCandidateToInterview(interview.id)"
                          :validation-schema="form"
                        >
                          <div
                            class="
                              notice
                              d-flex
                              bg-light-warning
                              rounded
                              border-warning border border-dashed
                              mb-10
                              p-6
                            "
                          >
                            <div class="d-flex flex-stack flex-grow-1">
                              <div
                                class="fs-6 fw-bold"
                                v-if="
                                  jobPosting.startDate && jobPosting.endDate
                                "
                              >
                                {{
                                  $t(
                                    "modals.interview.candidate.inviteCandidateInterviewJobpostingDate",
                                    {
                                      startDate: new Date(
                                        jobPosting.startDate
                                      ).toLocaleString("tr-TR", {
                                        dateStyle: "short",
                                      }),
                                      endDate: new Date(
                                        jobPosting.endDate
                                      ).toLocaleString("tr-TR", {
                                        dateStyle: "short",
                                      }),
                                    }
                                  )
                                }}
                              </div>
                            </div>
                          </div>

                          <div class="fv-row">
                            <label
                              class="
                                d-flex
                                align-items-center
                                form-label
                                mb-5
                                required
                              "
                            >
                              {{
                                $t("modals.interview.candidate.expirationDate")
                              }}:</label
                            >
                            <div class="position-relative align-items-center">
                              <Field
                                class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                "
                                type="date"
                                :placeholder="
                                  $t('modals.interview.enterEndDate')
                                "
                                name="expirationDate"
                                v-model="candidateInvite.expirationDate"
                                :min="today"
                              />
                              <ErrorMessage
                                name="expirationDate"
                                class="
                                  fv-plugins-message-container
                                  invalid-feedback
                                "
                              ></ErrorMessage>
                            </div>
                          </div>

                          <div class="text-center pt-15">
                            <button
                              type="reset"
                              class="btn btn-light me-3"
                              data-bs-dismiss="modal"
                            >
                              {{ $t("common.button.giveUp") }}
                            </button>

                            
                            <button
                                  type="submit"
                                  class="btn btn-primary"
                                  data-kt-roles-modal-action="submit"
                                  :disabled="isInvitingCandidateToInterview"
                                >
                                  <span v-if="!isInvitingCandidateToInterview">
                                    {{ $t("common.button.send") }}
                                  </span>
                                  <div
                                    v-else
                                    style="
                                      height: 30px;
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                    "
                                  >
                                    <img
                                      alt="Loading"
                                      src="/media/loading_icon.gif"
                                      style="width: 15px"
                                    />
                                    <span class="ms-2">{{
                                      $t("common.loading")
                                    }}</span>
                                  </div>
                                </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card-px text-center">
            <!--begin::Description-->
            <p class="text-gray-700 fs-6 fw-bold">
              <span> {{ $t("modals.interview.zeroCount") }}</span>
            </p>
            <div class="mb-10 fv-row">
              <div class="text-center my-4">
                <router-link to="/interviewer/new-interview">
                  <button type="button" class="btn btn-md btn-primary">
                    {{ $t("modals.interview.createNewInterview") }}
                  </button></router-link
                >
              </div>
            </div>

            <!--end::Description-->
          </div>

          <!--end::Card body-->
        </div>

        <div class="card pt-4 mb-6 mb-xl-9">
          <!--begin::Card header-->
          <div class="card-header border-0">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>{{ $t("modals.interview.latestMovements") }}</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-0 pb-5">
            <!--begin::Table-->
            <div
              id="kt_table_customers_payment_wrapper"
              class="dt-container dt-bootstrap5 dt-empty-footer"
            >
              <div
                v-if="isCandidateActionLoading"
                style="
                  height: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div class="text-center px-5 pb-7">
                  <img
                    alt="Logo"
                    class="max-h-25px"
                    src="/media/loading_icon.gif"
                    style="width: 35px"
                  />
                </div>
              </div>

              <div
                class="table-responsive"
                v-else-if="candidateActionList.length > 0"
              >
                <table class="table align-middle table-row-dashed gy-5">
                  <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                    <tr class="text-start text-muted text-uppercase gs-0">
                      <th class="min-w-100px">
                        {{ $t("modals.interview.interviewTitle") }}
                      </th>
                      <th class="min-w-100px">
                        {{ $t("modals.interview.status") }}
                      </th>
                      <th class="min-w-100px">
                        {{ $t("modals.interview.processDate") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="fs-6 fw-semibold text-gray-600">
                    <tr
                      class="clickable-row"
                      @click="
                        goToJobpostingDetail(
                          candidate.jobPostingId,
                          candidate.id
                        )
                      "
                      v-for="action in candidateActionList"
                    >
                      <td>
                        <a
                          href="#"
                          class="text-gray-600 text-hover-primary mb-1"
                          >{{ action.candidateAction.interviewTitle }}</a
                        >
                      </td>
                      <td>
                        <span
                          v-if="action.candidateAction.status"
                          :class="
                            CandidateActionBadge(action.candidateAction.status)
                          "
                        >
                          {{
                            action.candidateAction.status ===
                            CandidateActionStatus.INVITED
                              ? "Davet Gönderildi"
                              : action.candidateAction.status ===
                                CandidateActionStatus.COMPLETED
                              ? "Tamamlandı"
                              : action.candidateAction.status ===
                                CandidateActionStatus.FAILED
                              ? "Başarısız"
                              : "Pasif"
                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          formatFullDateWithTime(
                            action.candidateAction.updatedAt
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-else class="card-px text-center">
                <!--begin::Description-->
                <p class="text-gray-700 fs-6 fw-bold">
                  <span>{{ $t("modals.interview.noRecord") }}</span>
                </p>
                <!--end::Description-->
              </div>
            </div>

            <div
              class="d-flex flex-stack flex-wrap pt-10"
              v-if="candidateActionList.length != 0"
            >
              <!--begin::Controls-->
              <div class="d-flex flex-wrap my-1">
                <!--begin::Select wrapper-->
                <div class="m-0">
                  <!--begin::Select-->
                  <select
                    name="status"
                    data-control="select2"
                    data-hide-search="true"
                    class="
                      form-select form-select-white form-select-sm
                      fw-bolder
                      w-125px
                    "
                    v-model="pageSize"
                    @change="getCandidateActionList(1)"
                  >
                    <option value="5">5</option>
                    <option value="10" selected>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span>{{ $t("common.table.pagination.desc2") }}</span>
                  <!-- Sayfa başı öğe sayısı -->

                  <!--end::Select-->
                </div>
                <!--end::Select wrapper-->
              </div>
              <!--end::Controls-->

              <div class="fs-6 fw-bold text-gray-700">
                {{ totalItems }} {{ $t("common.table.pagination.desc") }}
                <!-- sonuç arasından -->
                {{ (currentPage - 1) * pageSize + 1 }} -
                {{ Math.min(currentPage * pageSize, totalItems) }}
                {{ $t("common.table.pagination.desc1") }}
                <!-- sonuç gösteriliyor. -->
              </div>

              <!--begin::Pages-->
              <ul class="pagination">
                <li
                  class="page-item previous"
                  @click="getCandidateActionList(currentPage - 1)"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <a href="#" class="page-link"><i class="previous"></i></a>
                </li>

                <li
                  v-for="page in pageCount"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="getCandidateActionList(page)"
                    >{{ page }}</a
                  >
                </li>

                <li
                  class="page-item next"
                  @click="getCandidateActionList(currentPage + 1)"
                  :class="{ disabled: currentPage === pageCount }"
                >
                  <a href="#" class="page-link"><i class="next"></i></a>
                </li>
              </ul>
              <!--end::Pages-->
            </div>

            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { environment } from "../../../../../../environment";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { CandidateActionListModel } from "@/domain/interviewer/candidate-action-list/model/CandidateActionListModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { CandidateActionStatus } from "@/domain/constants/CandidateActionStatus";
import { CandidateInterviewModel } from "@/domain/interviewer/candidate-detail/model/CandidateInterviewModel";
import { CandidatesInterviewStatus } from "@/domain/constants/CandidatesInterviewStatus";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import {
  CandidateActionBadge,
  CandidatesInterviewStatusBadge,
  CandidatesInterviewStatusBorderColor,
  CandidatesInterviewStatusText,
  InterviewCandidateStatusClass,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatFullDateWithTime,
} from "@/presentation/helper/DateFormatter";
import { CandidateCvAnalysisStatus } from "@/domain/constants/CandidateCvAnalysisStatus";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "candidate-detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    CandidateDropdown,
  },
  data() {
    return {
      today: dateToIsoDate(new Date()), // YYYY-MM-DD formatında bugünün tarihi
    };
  },
  methods: {
    goToJobpostingDetail(jobpostingId, candidateId) {
      this.$router.push(
        "/interviewer/jobposting-detail/" +
          jobpostingId +
          "/candidate/" +
          candidateId
      );
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isCandidateLoading = ref<boolean>(false);
    const isCvAnalysisLoading = ref<boolean>(false);
    const isCandidateActionLoading = ref<boolean>(false);
      const isInvitingCandidateToInterview = ref<boolean>(false);
    const candidateId = ref<any>(Number(route.params["id"]));

    const startDate = ref("");
    const endDate = ref("");

    const jobPosting = ref<JobPostingDetailModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const candidate = ref<CandidateDetailModel>({});
    const candidateInvite = ref<InterviewInviteModel>({
      expirationDate: new Date(),
    });
    const revokeInterviewInviteModel= ref<RevokeInterviewInviteModel>({
    });

    const candidateActionList = ref<CandidateActionListModel[]>([]);
    const candidatesInterviewList = ref<CandidateInterviewModel[]>([]);
    const originalCandidateActionList = ref<CandidateActionListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      expirationDate: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
    });

    const checkCvAnalysisInterval = setInterval(() => {
      if (
        candidate.value &&
        candidate.value.CvAnalysisScore == null &&
        candidate.value.CvAnalysisStatus ==
          CandidateCvAnalysisStatus.IN_PROGRESS
      ) {
        isCvAnalysisLoading.value = true;
        const candidateDetailModel: CandidateDetailModel = {
          id: candidateId.value,
        };

        interviewerController
          .candidateDetail(candidateDetailModel)
          .then((response) => {
            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(checkCvAnalysisInterval);
              getCandidate();
            }
          })
          .catch((e) => {
            isCvAnalysisLoading.value = false;
            clearInterval(checkCvAnalysisInterval);
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      } else if (
        (candidate.value.CvAnalysisScore == undefined &&
          candidate.value.CvAnalysisStatus == undefined) ||
        (candidate.value.CvAnalysisScore &&
          (candidate.value.CvAnalysisStatus ==
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ==
              CandidateCvAnalysisStatus.FAILED))
      ) {
        clearInterval(checkCvAnalysisInterval);
      }
    }, 2000);

    const inviteCandidateToInterview = (interviewId?: number) => {
      candidateInvite.value.candidate = candidateId.value;
      candidateInvite.value.interview = interviewId;
      candidateInvite.value.locale = i18n.global.locale.value;

      if (isInvitingCandidateToInterview.value == true) {
        return;
      }

      isInvitingCandidateToInterview.value = true;

      Swal.fire({
        title: t("swalMessages.INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .interviewInvite(candidateInvite.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidate()
                  document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
              isInvitingCandidateToInterview.value = false;
              
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const getCandidate = () => {
      isCandidateLoading.value = false;

      const candidateDetailModel: CandidateDetailModel = {
        id: candidateId.value,
      };

      isCandidateLoading.value = true;

      interviewerController
        .candidateDetail(candidateDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(candidate.value, response.getValue());
            Object.assign(
              candidatesInterviewList.value,
              response.getValue().interviews
            );
            getCandidateActionList(1);
            getJobPosting(candidate.value.jobPostingId);
            isCandidateLoading.value = false;
          } else {
            router.push({ path: "/interviewer/jobposting-list" });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateActionList = (page) => {
      candidateActionList.value = [];
      originalCandidateActionList.value = [];

      isCandidateActionLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const candidateActionListModel: CandidateActionListModel = {
        candidateAction: {
          candidateId: candidateId.value,
        },
      };

      interviewerController
        .getCandidateActionList(candidateActionListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateAction) => {
              totalItems.value = candidateAction.pagination.total;
              currentPage.value = candidateAction.pagination.page;
              pageCount.value = candidateAction.pagination.pageCount;
              candidateActionList.value.push(candidateAction);
              originalCandidateActionList.value.push(candidateAction);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidateActionLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = (jobPostingId) => {
      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId,
      };

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const downloadCV = () => {
      let cvLink = candidate.value.cvUrl;
      const fullUrl = environment.baseUploadUrl + cvLink;
      const link = document.createElement("a");
      link.href = fullUrl;

      if (cvLink) {
        const fileName = fullUrl.split("/").pop();
        if (fileName) {
          link.download = fileName;
          link.click();
        } else {
          swalNotification.error(
            t("validators_error.requiredFileName"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      }
    };

    const showError = (error) => {
      const errorMessage = `${error.cause?.cause || ""} ${t(
        SWAL_MESSAGES.ERROR.ERROR_CODE
      )} ${error.code || ""} ${error.cause?.code || ""}`;
      swalNotification.error(
        errorMessage,
        t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      );
    };

    const updateCandidateDetails = async () => {
      try {
        const candidateDetailModel = { id: candidateId.value };
        const response = await interviewerController.candidateDetail(
          candidateDetailModel
        );

        if (response.isSuccess) {
          Object.assign(candidate.value, response.getValue());
          Object.assign(
            candidatesInterviewList.value,
            response.getValue().interviews
          );
        } else {
          router.push({ path: "/interviewer/jobposting-list" });
          showError(response.error);
        }
      } catch (error) {
        showError(error);
      }
    };

    const pollCvAnalysisStatus = () => {
      const intervalId = setInterval(async () => {
        if (!candidate.value) return clearInterval(intervalId);

        if (
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.IN_PROGRESS
        ) {
          try {
            const candidateDetailModel = { id: candidateId.value };
            const response = await interviewerController.candidateDetail(
              candidateDetailModel
            );

            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(intervalId);
              Object.assign(candidate.value, response.getValue());
              Object.assign(
                candidatesInterviewList.value,
                response.getValue().interviews
              );
            }
          } catch (error) {
            isCvAnalysisLoading.value = false;
            clearInterval(intervalId);
            showError(error);
          }
        } else if (
          candidate.value.CvAnalysisScore !== undefined &&
          (candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ===
              CandidateCvAnalysisStatus.FAILED)
        ) {
          clearInterval(intervalId);
        }
      }, 2000);
    };

    const reloadCVAnalysis = async () => {
      const candidateCvAnalysisModel = { candidate: candidateId.value };

      const swalText = jobPosting.value.hasCvAnalysis
        ? t("swalMessages.CANNOT_BE_UNDONE")
        : t("swalMessages.CANNOT_BE_UPDATE_JOBPOSTING");

      const confirmation = await Swal.fire({
        title: t("swalMessages.CANDIDATE_CV_ANALYSIS"),
        text: swalText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      });

      if (!confirmation.isConfirmed) return;

      try {
        const response = await interviewerController.candidateCvAnalysis(
          candidateCvAnalysisModel
        );

        if (!response.isSuccess) {
          return showError(response.error);
        }

        isCvAnalysisLoading.value = true;

        await Swal.fire({
          title: t("swalMessages.SUCCESSFUL"),
          text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CV_ANALYSIS),
          icon: "success",
        });

        await updateCandidateDetails();
        pollCvAnalysisStatus();
      } catch (error) {
        showError(error);
      }
    };

    const revokeInterviewInvite = (
      candidateId?: number,
      interviewId?: number
    ) => {

      revokeInterviewInviteModel.value.candidate = candidateId;
      revokeInterviewInviteModel.value.interview = interviewId;

      Swal.fire({
        title: t("swalMessages.REVOKE_INVITE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .revokeInterviewInvite(revokeInterviewInviteModel.value)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_REVOKE_INVITE_INTERVIEW),
                  icon: "success",
                }).then(() => {
                  getCandidate()
                  document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    watch(
      () => store.getters.changedAnyCandidate,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getCandidate();
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getCandidate();
    });

    return {
      CandidateActionStatus,
      InterviewCandidateStatus,
      CandidatesInterviewStatus,
      inviteCandidateToInterview,
      revokeInterviewInvite,
      getCandidateActionList,
      isCandidateLoading,
      isCvAnalysisLoading,
      isCandidateActionLoading,
      isInvitingCandidateToInterview,
      candidateId,
      candidate,
      candidateActionList,
      candidatesInterviewList,
      downloadCV,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
      CandidateActionBadge,
      CandidatesInterviewStatusText,
      CandidatesInterviewStatusBadge,
      CandidatesInterviewStatusBorderColor,
      InterviewCandidateStatusClass,
      formatFullDateWithTime,
      form,
      candidateInvite,
      reloadCVAnalysis,
      jobPosting,
    };
  },
});
</script>

<style>
.clickable-row {
  cursor: pointer; /* Tıklanabilirlik özelliği */
  transition: background-color 0.3s ease; /* Arka plan renginin yumuşak geçişi */
}

.clickable-row:hover {
  background-color: #f0f0f0; /* Hover durumunda gri arka plan */
}

/* İstenirse satır metni de renklendirilebilir */
.clickable-row a {
  color: #333;
  text-decoration: none;
}

.clickable-row a:hover {
  color: #007bff; /* Linkin üzerine gelindiğinde renk değişikliği */
}
</style>
