
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UpdateInterviewRepository } from "../repository/UpdateInterviewRepository";
import { UpdateInterviewMapper } from "../mapper/UpdateInterviewMapper";
import { IUpdateInterviewUseCase } from "@/domain/interviewer/update-interview/usecase/IUpdateInterviewUseCase";
import { IUpdateInterviewRepository } from "@/domain/interviewer/update-interview/repository/IUpdateInterviewRepository";
import { UpdateInterviewModel } from "@/domain/interviewer/update-interview/model/UpdateInterviewModel";

export class UpdateInterviewUseCase implements IUpdateInterviewUseCase {
  updateInterviewRepository: IUpdateInterviewRepository = new UpdateInterviewRepository();
  updateInterviewMapper = new UpdateInterviewMapper();

  constructor() {}

  async execute(model: UpdateInterviewModel): Promise<Result<UpdateInterviewModel>> {
    try {
      const dto = this.updateInterviewMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateInterviewModel>(dto.getError());
      } else {
        const response = await this.updateInterviewRepository.updateInterview(dto);

        if (response.isSuccess) {
          const domain = this.updateInterviewMapper.mapToDomain(response.getValue());

          return Result.ok<UpdateInterviewModel>(domain.getValue());
        } else {
          return Result.fail<UpdateInterviewModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateInterviewModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.UPDATE_INTERVIEW))
      );
    }
  }
}
