
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { UpdateInterviewModel } from "@/domain/interviewer/update-interview/model/UpdateInterviewModel";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import { AddQuestionModel } from "@/domain/interviewer/update-interview/addQuestion/model/AddQuestionModel";
import { AddQuestionOptionModel } from "@/domain/interviewer/update-interview/addQuestionOption/model/AddQuestionOptionModel";
import { DeleteQuestionOptionModel } from "@/domain/interviewer/update-interview/deleteQuestionOption/model/DeleteQuestionOptionModel";
import { DeleteQuestionModel } from "@/domain/interviewer/update-interview/deleteQuestion/model/DeleteQuestionModel";
import { UpdateQuestionModel } from "@/domain/interviewer/update-interview/updateQuestion/model/UpdateQuestionModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";
// import { InterviewQuestionListModel } from "@/domain/interviewer/model/InterviewQuestionListModel";

export default defineComponent({
  name: "create-jobposting",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueEditor,
  },
  props: {
    interviewId: Number,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref<boolean>(false);

    const interviewId = ref<any>(Number(route.params["id"]));

    const swalNotification = new SwalNotification();

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const interviewUpdateModel = ref<UpdateInterviewModel>({
      id: props.interviewId,
    });

    const interview = ref<InterviewDetailModel>({});

    const newQuestion = ref<InterviewQuestionModel>({
      questionText: "",
      questionType: QuestionTypes.OPEN_ENDED,
      interviewQuestionOptions: [],
    });

    const questions = ref<InterviewQuestionModel[]>([]);

    const newOptionText = ref("");

    const addQuestion = () => {
      if (!newQuestion.value.questionText) {
        swalNotification.error(
          t("modals.interview.pleaseWriteQuestion"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else if (
        (newQuestion.value.questionType == QuestionTypes.SINGLE_CHOICE ||
          newQuestion.value.questionType == QuestionTypes.MULTIPLE_CHOICE) &&
        newQuestion.value.interviewQuestionOptions.length == 0
      ) {
        swalNotification.error(
          t("modals.interview.pleaseAddOptions"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else {
        const addQuestionModel: AddQuestionModel = {
          interview: interviewId.value,
          questionText: newQuestion.value.questionText,
          questionType: newQuestion.value.questionType,
          interviewQuestionOptions: newQuestion.value.interviewQuestionOptions,
        };

        interviewerController
          .addQuestion(addQuestionModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_QUESTION),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
              getInterviewDetail();
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            isLoading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });

        newQuestion.value = {
          questionText: "",
          questionType: QuestionTypes.OPEN_ENDED,
          interviewQuestionOptions: [],
        };
        newOptionText.value = "";
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.interviewQuestionOptions.push({
          id: 0,
          optionText: newOptionText.value.trim(),
        });
        newOptionText.value = "";
      }
    };

    const addOptionToQuestion = (questionId) => {
      const addQuestionOptionModel: AddQuestionOptionModel = {
        interviewQuestion: questionId,
        optionText: newOptionText.value,
      };

      interviewerController
        .addQuestionOption(addQuestionOptionModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_QUESTION_OPTION),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            getInterviewDetail();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      newOptionText.value = "";
    };

    const updateQuestion = (questionId, text) => {
      const updateQuestionModelModel: UpdateQuestionModel = {
        id: questionId,
        questionText: text,
      };

      interviewerController
        .updateQuestion(updateQuestionModelModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_QUESTION),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            getInterviewDetail();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      newOptionText.value = "";
    };

    const removeOption = (index: number) => {
      newQuestion.value.interviewQuestionOptions.splice(index, 1);
    };

    const removeExistingOption = (optionId) => {
      const deleteQuestionOptionModel: DeleteQuestionOptionModel = {
        id: optionId,
      };

      Swal.fire({
        title: t("swalMessages.ARE_YOU_SURE"),
        text: t("modals.interview.areYouSureAboutDeleteOption"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteQuestionOption(deleteQuestionOptionModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_QUESTION_OPTION),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                });
                getInterviewDetail();
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const removeQuestion = (questionId) => {
      const deleteQuestionModel: DeleteQuestionModel = {
        id: questionId,
      };
      Swal.fire({
        title: t("swalMessages.ARE_YOU_SURE"),
        text: t("modals.interview.areYouSureAboutDeleteQuestion"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteQuestion(deleteQuestionModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_QUESTION),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                });
                getInterviewDetail();
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const { t } = useI18n();
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      description: Yup.string()
        .required(t("validators_error.required"))
        .label("description"),
    });

    const getInterviewDetail = async () => {
      questions.value = [];
      isLoading.value = true;

      const interviewDetailModel: InterviewDetailModel = {
        id: interviewId.value,
      };

      interviewerController
        .getInterviewDetail(interviewDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(interview.value, response.getValue());

            if (interview.value.interviewQuestions)
              Object.assign(
                questions.value,
                interview.value.interviewQuestions
              );
          } else {
            if (!interview.value.id) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const onSubmit = () => {
      interviewUpdateModel.value = {
        id: interviewId.value,
        title: interview.value.title,
        description: interview.value.description,
      };

      interviewerController
        .updateInterview(interviewUpdateModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewDetail();
    });

    return {
      onSubmit,
      featureInput,
      features,
      interview,
      form,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      updateQuestion,
      addOption,
      addOptionToQuestion,
      removeOption,
      removeExistingOption,
      removeQuestion,
      interviewUpdateModel,
      isLoading,
      QuestionTypes,
    };
  },
});
