import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdateCandidateModel extends BaseDomain {
  id?: number;
  cv?: File;
  candidateStatus?: string;
  ok?: boolean;

  constructor(
    id?: number,
    cv?: File,
    candidateStatus?: string,
    ok?: boolean
  ) {
    super();
    this.id = id;
    this.cv = cv;
    this.candidateStatus = candidateStatus;
    this.ok = ok;
  }
}
