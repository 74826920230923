export class InterviewQuestionModel {
  id?: number;
  questionText?: string;
  questionType?: string;
  interviewQuestionOptions: { id: number; optionText: string }[];
  interviewQuestionResponse?: string;

  constructor(
    interviewQuestionOptions: { id: number; optionText: string }[],
    id?: number,
    questionText?: string,
    questionType?: string,
    interviewQuestionResponse?: string,
  ) {
    this.id = id;
    this.questionText = questionText;
    this.questionType = questionType;
    if (interviewQuestionOptions)
      this.interviewQuestionOptions = interviewQuestionOptions;
    else this.interviewQuestionOptions = [];
    this.interviewQuestionResponse = interviewQuestionResponse
  }
}
