
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { AddQuestionOptionMapper } from "../mapper/AddQuestionOptionMapper";
import { AddQuestionOptionRepository } from "../repository/AddQuestionOptionRepository";
import { IAddQuestionOptionUseCase } from "@/domain/interviewer/update-interview/addQuestionOption/usecase/IAddQuestionOptionUsecase";
import { IAddQuestionOptionRepository } from "@/domain/interviewer/update-interview/addQuestionOption/repository/IAddQuestionOptionRepository";
import { AddQuestionOptionModel } from "@/domain/interviewer/update-interview/addQuestionOption/model/AddQuestionOptionModel";

export class AddQuestionOptionUsecase implements IAddQuestionOptionUseCase {
  addQuestionOptionRepository : IAddQuestionOptionRepository = new AddQuestionOptionRepository();
  addQuestionOptionMapper = new AddQuestionOptionMapper();

  constructor() {
  }

  async execute(model: AddQuestionOptionModel): Promise<Result<AddQuestionOptionModel>> {
    try {
      const dto = this.addQuestionOptionMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<AddQuestionOptionModel>(dto.getError());
      } else {
        const response = await this.addQuestionOptionRepository.addQuestionOption(dto);

        if (response.isSuccess) {
          const domain = this.addQuestionOptionMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AddQuestionOptionModel>(domain.getValue());
        } else {
          return Result.fail<AddQuestionOptionModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AddQuestionOptionModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.ADD_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
