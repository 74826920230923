
import { defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-5",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    return {  };
  },
});
