import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "./modules/AuthModule";
import BodyModule from "./modules/BodyModule";
import BreadcrumbsModule from "./modules/BreadcrumbsModule";
import LayoutConfigModule from "./modules/LayoutConfigModule";
import ControllersModule from "./modules/ControllersModule";

config.rawError = true;

const store = createStore({
  modules: {
    ControllersModule,
    BodyModule,
    BreadcrumbsModule,
    LayoutConfigModule,
    AuthModule,
  },
  state: {
    phoneNumber: null,
    countryCode: null,
    expireTime: null,
    remainingAttempts: null,
    newChat: null,
    startNewSession: false,
    changeTenant: false,
    buyModuleCount: false,
    choosenJobPostingIdOnCreateInterviewPage: 0,
    changedAnyCandidate: 0,
    changedAnyJobPosting: 0,
  },
  mutations: {
    phoneNumber(state, phoneNumber: number) {
      state.phoneNumber = phoneNumber;
    },
    countryCode(state, countryCode: number) {
      state.countryCode = countryCode;
    },
    expireTime(state, expireTime: number) {
      state.expireTime = expireTime;
    },
    remainingAttempts(state, remainingAttempts: number) {
      state.remainingAttempts = remainingAttempts;
    },
    newChat(state, newChat: number) {
      state.newChat = newChat;
    },
    startNewSession(state, startNewSession: boolean) {
      state.startNewSession = startNewSession;
    },
    changeTenant(state, changeTenant: boolean) {
      state.changeTenant = changeTenant;
    },
    buyModuleCount(state, buyModuleCount: boolean) {
      state.buyModuleCount = buyModuleCount;
    },
    choosenJobPostingIdOnCreateInterviewPage(state, choosenJobPostingIdOnCreateInterviewPage: number) {
      state.choosenJobPostingIdOnCreateInterviewPage = choosenJobPostingIdOnCreateInterviewPage;
    },
    changedAnyCandidate(state, changedAnyCandidate: number) {
      state.changedAnyCandidate = changedAnyCandidate;
    },
    changedAnyJobPosting(state, changedAnyJobPosting: number) {
      state.changedAnyJobPosting = changedAnyJobPosting;
    },
  },
  actions: {
    phoneNumber({ commit }, phoneNumber: number) {
      commit("phoneNumber", phoneNumber);
    },
    countryCode({ commit }, countryCode: number) {
      commit("countryCode", countryCode);
    },
    expireTime({ commit }, expireTime: number) {
      commit("expireTime", expireTime);
    },
    remainingAttempts({ commit }, remainingAttempts: number) {
      commit("remainingAttempts", remainingAttempts);
    },
    newChat({ commit }, newChat: number) {
      commit("newChat", newChat);
    },
    startNewSession({ commit }, startNewSession: boolean) {
      commit("startNewSession", startNewSession);
    },
    changeTenant({ commit }, changeTenant: number) {
      commit("changeTenant", changeTenant);
    },
    buyModuleCount({ commit }, buyModuleCount: boolean) {
      commit("buyModuleCount", buyModuleCount);
    },
    choosenJobPostingIdOnCreateInterviewPage({ commit }, choosenJobPostingIdOnCreateInterviewPage: number) {
      commit("choosenJobPostingIdOnCreateInterviewPage", choosenJobPostingIdOnCreateInterviewPage);
    },
    changedAnyCandidate({ commit }, changedAnyCandidate: number) {
      commit("changedAnyCandidate", changedAnyCandidate);
    },
    changedAnyJobPosting({ commit }, changedAnyJobPosting: number) {
      commit("changedAnyJobPosting", changedAnyJobPosting);
    },
  },
  getters: {
    phoneNumber: (state) => state.phoneNumber,
    countryCode: (state) => state.countryCode,
    expireTime: (state) => state.expireTime,
    remainingAttempts: (state) => state.remainingAttempts,
    newChat: (state) => state.newChat,
    startNewSession: (state) => state.startNewSession,
    changeTenant: (state) => state.changeTenant,
    buyModuleCount: (state) => state.buyModuleCount,
    choosenJobPostingIdOnCreateInterviewPage: (state) => state.choosenJobPostingIdOnCreateInterviewPage,
    changedAnyCandidate: (state) => state.changedAnyCandidate,
    changedAnyJobPosting: (state) => state.changedAnyJobPosting,
  },
});

export default store;
