import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ICandidateListService } from "@/domain/interviewer/candidate-list/service/ICandidateListService";
import { CandidateListRequestDto } from "@/domain/interviewer/candidate-list/dto/CandidateListRequestDto";
import { CandidateListResponseDto } from "@/domain/interviewer/candidate-list/dto/CandidateListResponseDto";

export default class CandidateListService implements ICandidateListService {
  async getList(
    request: CandidateListRequestDto
  ): Promise<Result<CandidateListResponseDto>> {
    try {
      return await instance
        .get(
          API_ROUTE.CANDIDATE +
            API_ROUTE.JOB_POSTINGS +
            request.jobPostingId +
            request.toQueryString()
        )
        .then((response) => {
          return Result.ok<CandidateListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<CandidateListResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_CANDIDATE_LIST)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }
}
