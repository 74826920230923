import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IInterviewCountService } from "@/domain/interviewer/interview-counts/service/IInterviewCountService";
import { InterviewCountRequestDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountRequestDto";
import { InterviewCountResponseDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountResponseDto";

export default class InterviewCountService implements IInterviewCountService {
  async getCounts(
    request: InterviewCountRequestDto
  ): Promise<Result<InterviewCountResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.INTERVIEWS_COUNTS + request.toQueryString())
        .then((response) => {
          return Result.ok<InterviewCountResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<InterviewCountResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INTERVIEW_COUNTS)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.INTERVIEW_COUNTS))
      );
    }
  }
}