import { BaseDomain } from "@/core/base/BaseDomain";
import { ProductPackageModel } from "../../product-list/model/ProductPackageModel";
import { ProductFAQModel } from "../../product-list/model/ProductFAQModel";

export class GetProductModel extends BaseDomain {
  shortCode?: string;
  id?: number;
  name?: string;
  description?: string;
  productPackages: ProductPackageModel[];
  productFAQs?: ProductFAQModel[];
  locale?: string;

  constructor(
    productPackages: ProductPackageModel[],
    id?: number,
    shortCode?: string,
    name?: string,
    description?: string,
    locale?: string
  ) {
    super();
    this.productPackages = productPackages;
    this.id = id;
    this.name = name;
    this.shortCode = shortCode;
    this.description = description;
    this.locale = locale;
  }
}
