import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { FileUploadUsecase } from "@/application/file-upload/usecase/FileUploadUseCase";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";

export function createFileController() {
  const useCases = {
    fileUpload: {
      useCase: new FileUploadUsecase(),
      model: FileUploadModel,
    },
  };

  return new FileController(useCases);
}

export class FileController implements IController {
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response; 
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
