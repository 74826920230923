
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/presentation/store/enums/StoreEnums";
import Modal from "bootstrap/js/dist/modal";
import { COMPONENT_NAME } from "./presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.APP,
  watch: {
    $route() {
      this.closeAllModals();
    },
  },
  methods: {
    closeAllModals() {
      const modals = document.querySelectorAll(".modal.show");
      modals.forEach((modal) => {
        const modalInstance = Modal.getInstance(modal); 
        if (modalInstance) {
          modalInstance.hide();
        }
      });

  document.body.classList.remove('modal-open');
  document.body.style.overflow = ''; 

  const backdrops = document.querySelectorAll('.modal-backdrop');
  backdrops.forEach((backdrop) => {
    backdrop.remove();
  });
  
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/presentation/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
