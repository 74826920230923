
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import CandidateItem from "@/presentation/components/list-items/CandidateItem.vue";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import {
  InterviewCandidateStatusClass,
  JobPostingStatusBadge,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";
import { watch } from "vue";

export default defineComponent({
  name: "CandidateList",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    CandidateItem,
    CandidateDropdown,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const startDate = ref("");
    const endDate = ref("");

    const isJobPostingLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const viewMode = ref("list-view");
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const isAllCandidateChecked = ref(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});

    const candidateList = ref<CandidateListModel[]>([]);
    const originalCandidateList = ref<CandidateListModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(10);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const getJobPosting = () => {
      isJobPostingLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      isJobPostingLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
            getCandidateList(1);
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isJobPostingLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      isCandidatesLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListModel = {
        candidate: {
          jobPostingId: jobPostingId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateList(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectAll = () => {
      candidateList.value.forEach(
        (candidate) => (candidate.candidate.checked = true)
      );
    };

    const deselectAll = () => {
      candidateList.value.forEach(
        (candidate) => (candidate.candidate.checked = false)
      );
    };

    const deleteCandidate = (jobPostingId?: number) => {
      const jobPostingDeleteModel: DeleteJobPostingModel = {
        id: jobPostingId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_CANDIDATE"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        // Swal.fire({
        //   title: t("swalMessages.SUCCESSFUL"),
        //   text: "Aday başarıyla silindi!",
        //   icon: "success",
        // }).then(() => {
        //   window.location.reload();
        // });

        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.FAILED"),
            text: t("swalMessages.NOT_ACTIVE_YET"),
            icon: "error",
          });

          // interviewerController
          //   .deleteCandidate(jobPostingDeleteModel)
          //   .then((response) => {
          //     if (response.isSuccess) {
          //     } else {
          //       swalNotification.error(
          //         response.error.cause.cause +
          //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
          //           response.error.code +
          //           response.error.cause.code,
          //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          //       );
          //     }
          //   })
          //   .catch((e) => {
          //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          //   });
        }
      });
    };

    const handleCheckChange = () => {
      if (isAllCandidateChecked.value) {
        selectAll(); // Eğer işaretliyse `selectAll` çağrılır
      } else {
        deselectAll(); // Değilse `deselectAll` çağrılır
      }
    };

    const submitSelected = () => {
      const selectedCandidates = candidateList.value.filter(
        (candidate) => candidate.candidate.checked
      );
    };

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? dateToIsoDate(new Date(oldDate)) : "";
      selectedInterviewId.value = id;
    };

    const toggleView = (view) => {
      if (view === "list") {
        viewMode.value = "list-view";
      } else if (view === "card") {
        viewMode.value = "card-view";
      }
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    watch(
      () => store.getters.changedAnyCandidate,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getCandidateList(1);
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      JobPostingStatusBadge,
      InterviewCandidateStatusClass,
      formatLocalizedDate,
      JobPostingStatus,
      InterviewCandidateStatus,
      url,
      form,
      newEndDate,
      changeDateRef,
      selectInterview,
      candidateCurrentPage,
      candidatePageCount,
      candidatePageSize,
      isCandidatesLoading,
      isJobPostingLoading,
      candidateTotalItems,
      positionDesc,
      selectAll,
      submitSelected,
      handleCheckChange,
      isAllCandidateChecked,
      viewMode,
      toggleView,
      deleteCandidate,
      jobPosting,
      visibleRequirements,
      hiddenRequirementCount,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      showAll,
      getCandidateList,
      candidateList,
      originalCandidateList,
    };
  },
});
