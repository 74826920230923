
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICandidateActionListUseCase } from "@/domain/interviewer/candidate-action-list/usecase/ICandidateActionListUsecase";
import { CandidateActionListRepository } from "../repository/CandidateActionListRepository";
import { ICandidateActionListRepository } from "@/domain/interviewer/candidate-action-list/repository/ICandidateActionListRepository";
import { CandidateActionListMapper } from "../mapper/CandidateActionListMapper";
import { CandidateActionListModel } from "@/domain/interviewer/candidate-action-list/model/CandidateActionListModel";

export class CandidateActionListUseCase implements ICandidateActionListUseCase {
  candidateActionListRepository : ICandidateActionListRepository = new CandidateActionListRepository();
  candidateActionListMapper = new CandidateActionListMapper();

  constructor() {
  }

  async execute(model: CandidateActionListModel): Promise<Result<CandidateActionListModel>> {
    try {
      const dto = this.candidateActionListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<CandidateActionListModel>(dto.getError());
      } else {
        const response = await this.candidateActionListRepository.candidateActionList(dto);

        if (response.isSuccess) {
          const domain = this.candidateActionListMapper.mapToDomain(response.getValue());
          return Result.ok<CandidateActionListModel>(domain.getValue());
        } else {
          return Result.fail<CandidateActionListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateActionListModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }
}
