import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { CandidateListResponseDto } from "@/domain/interviewer/candidate-list/dto/CandidateListResponseDto";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import { CandidateListRequestDto } from "@/domain/interviewer/candidate-list/dto/CandidateListRequestDto";

const paginationMapper = new PaginationMapper();

export class CandidateListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CandidateListResponseDto): Result<CandidateListModel[]> {
    try {
      const candidateList: CandidateListModel[] = [];
      if (dto.data)
        if (dto.data.length > 0) {
          for (const item of dto.data) {
            const candidateListModel: CandidateListModel = {
              candidate: {
                candidateId: Number(item.id),
                firstName: item.attributes.firstName,
                lastName: item.attributes.lastName,
                fullName: item.attributes.fullName,
                email: item.attributes.email,
                candidateStatus: item.attributes.candidateStatus,
                checked: false,
                hasInterviewSession: item.attributes.hasInterviewSession,
              },
              page: 0,
              pageSize: 0,
              pagination: dto.meta?.pagination,
            };

            if (item.attributes.cv && item.attributes.cv.data) {
              candidateListModel.candidate.cvUrl =
                item.attributes.cv.data.attributes.url;
            }

            if (item.attributes.positionSuitability) {
              candidateListModel.candidate.positionSuitability =
                item.attributes.positionSuitability;
            } else {
              candidateListModel.candidate.positionSuitability = undefined;
            }

            candidateList.push(candidateListModel);
          }
          return Result.ok(candidateList);
        }
      return Result.ok(candidateList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }

  mapToDTO(domain: CandidateListModel): Result<CandidateListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const candidateListRequest = new CandidateListRequestDto(
        pagiRequest,
        domain.candidate.jobPostingId,
        domain.searchQuery,
        domain.candidate.candidateStatus,
        domain.sort
      );

      return Result.ok(candidateListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }
}
