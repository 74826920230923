import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { JobPostingDetailByInviteResponseDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteResponseDto";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import { JobPostingDetailByInviteRequestDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteRequestDto";

export class JobPostingDetailByInviteMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: JobPostingDetailByInviteResponseDto): Result<JobPostingDetailByInviteModel> {
    try {
      if (dto.data) {
        const jobpostingDetailModel: JobPostingDetailByInviteModel = {
          jobPostingTitle: dto.data.attributes.jobPostingTitle,
          interviewTitle: dto.data.attributes.interviewTitle,
          interviewQuestionCount: dto.data.attributes.interviewQuestionCount,
        };

        return Result.ok(jobpostingDetailModel);
      } else {
        const jobpostingDetailModel: JobPostingDetailByInviteModel = {};
        return Result.ok(jobpostingDetailModel);
      }
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_DETAIL_BY_INVITE)
        )
      );
    }
  }

  mapToDTO(domain: JobPostingDetailByInviteModel): Result<JobPostingDetailByInviteRequestDto> {
    try {
      const interviewRequest: JobPostingDetailByInviteRequestDto = {
        key: domain.key,
      };

      return Result.ok(interviewRequest);
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_DETAIL_BY_INVITE)
        )
      );
    }
  }
}
