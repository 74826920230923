
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { CreateJobPostingModel } from "@/domain/interviewer/create-job-posting/model/CreateJobPostingModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import striptags from "striptags";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "create-jobposting",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const today = dateToIsoDate(new Date())
    const endDate = ref("");
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);

    const swalNotification = new SwalNotification();

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const jobPosting = ref<CreateJobPostingModel>({});

    const { t } = useI18n();
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      //       description: Yup.string()
      // .test(
      //   "is-valid-description",
      //   t("validators_error.required"),
      //   (value) => striptags(value || "").trim() !== ""
      // )
      // .required(t("validators_error.required"))
      // .label("description"),
      startDate: Yup.date()
        .required(t("validators_error.required"))
        .label("startDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("endDate")
        .test(
          "is-greater",
          t("validators_error.startDateBiggerEndDate"),
          function (value) {
            const { startDate } = this.parent;
            return startDate
              ? new Date(value as Date) >= new Date(startDate as Date)
              : true;
          }
        ),
      //     features: Yup.array()
      // .of(Yup.string().required(t("validators_error.required")))
      // .min(3, t("validators_error.minFeatures", { min: 3 }))
      // .label("Features"),
    });

    const addQualification = () => {
      if (
        features.value &&
        features.value.length >= 20
      ) {
        swalNotification.error(
          t("validators_error.max20Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      }

      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = "";
      }
    };

    const removeFeature = (index) => {
      features.value.splice(index, 1);
    };

    const editorOptions = ref({
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Başlık seçenekleri
          ["bold", "italic", "underline", "strike"], // Metin stilleri
          [{ list: "ordered" }, { list: "bullet" }], // Liste türleri
          [{ script: "sub" }, { script: "super" }], // Alt ve üst simgeler
          [{ indent: "-1" }, { indent: "+1" }], // Girintiler
          [{ direction: "rtl" }], // Metin yönü
          [{ color: [] }, { background: [] }], // Renkler
          [{ align: [] }], // Hizalama
          ["link"], // Bağlantı
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    });

    const onSubmit = () => {
      const errors: string[] = [];

      if (
        !jobPosting.value.description ||
        striptags(jobPosting.value.description.trim()) === ""
      ) {
        errors.push(t("validators_error.requiredDesc"));
      }

      if (!features.value || features.value.length < 3) {
        errors.push(t("validators_error.requiredMin3Features", { min: 3 }));
      }

      if (errors.length > 0) {
        Swal.fire({
          icon: "error",
          title: t("validators_error.title"),
          html: errors.join("<br />"),
        });
        return;
      } else {
        submitButton.value?.setAttribute("data-kt-indicator", "on");
        loading.value = true;

        jobPosting.value.jobPostingQualifications = features.value;

        interviewerController
          .createJobPosting(jobPosting.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");

              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CREATE_JOBPOSTING),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "create-interview" });
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            loading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const show = () => {
      jobPosting.value.jobPostingQualifications = features.value;
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      onSubmit,
      featureInput,
      features,
      addQualification,
      removeFeature,
      today,
      endDate,
      jobPosting,
      form,
      show,
      editorOptions,
    };
  },
});
