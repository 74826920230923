import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class StatisticPollListModel extends BaseDomain {
  poll: {
    id?: number;
    title?: string;
    createdAt?: Date;
    endDate?: Date;
    sessionDuration?: any;
    surveyIsPrivate?: boolean;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  
  constructor(
    id?: number,
    title?: string,
    createdAt?: Date,
    endDate?: Date,
    sessionDuration?: any,
    surveyIsPrivate?: boolean,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.poll = {
      id,
      title,
      createdAt,
      endDate,
      sessionDuration,
      surveyIsPrivate
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
