import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import CandidateDetailService from "../service/CandidateDetailService";
import { ICandidateDetailRepository } from "@/domain/interviewer/candidate-detail/repository/ICandidateDetailRepository";
import { CandidateDetailRequestDto } from "@/domain/interviewer/candidate-detail/dto/CandidateDetailRequestDto";
import { CandidateDetailResponseDto } from "@/domain/interviewer/candidate-detail/dto/CandidateDetailResponseDto";

const jobPostingDetailService = new CandidateDetailService();

export class CandidateDetailRepository implements ICandidateDetailRepository{
  async getCandidateDetail(request: CandidateDetailRequestDto): Promise<Result<CandidateDetailResponseDto>> {
    try {
      return await jobPostingDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<CandidateDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<CandidateDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.CANDIDATE_DETAIL)));
    }
  }
}
