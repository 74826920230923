import { BaseDomain } from "@/core/base/BaseDomain";

export class JobPostingInterviewModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  sequence?: number;

  constructor(
    id?: number,
    title?: string,
    description?: string,
    sequence?: number,
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.sequence = sequence;
  }
}
