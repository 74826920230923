import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import { InterviewInviteRequestDto } from "@/domain/interviewer/interview-invite/dto/InterviewInviteRequestDto";

export class InterviewInviteMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<InterviewInviteModel> {
    try {
      const createInterviewModel: InterviewInviteModel = {
        ok: dto.ok,
      };

      return Result.ok(createInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }

  mapToDTO(domain: InterviewInviteModel): Result<InterviewInviteRequestDto> {
    try {
      const createInterviewRequest = new InterviewInviteRequestDto(
        domain.interview,
        domain.candidate,
        domain.expirationDate,
        domain.locale
      );

      return Result.ok(createInterviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }
}
