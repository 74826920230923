import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import CreatePollService from "../service/CreatePollService";
import { ICreatePollRepository } from "@/domain/pollster/create-poll/repository/ICreatePollRepository";
import { CreatePollRequestDto } from "@/domain/pollster/create-poll/dto/CreatePollRequestDto";

const createPollsterService = new CreatePollService();

export class CreatePollRepository implements ICreatePollRepository {
  async createPollster(
    request: CreatePollRequestDto
  ): Promise<Result<any>> {
    try {
      return await createPollsterService
        .create(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.CREATE_POLL))
      );
    }
  }
}
