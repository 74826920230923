import { ErrorLangMessage } from "@/presentation/helper/ErrorMessage";
import { BaseCause } from "../base/BaseCause";
import { BaseException } from "../base/BaseException";
import { ExceptionCode } from "./ExceptionCode";

export class ValidationException extends BaseException {
  constructor(cause: BaseCause) {
    //  const errorMessage = ErrorLangMessage.getErrorMessage(cause.cause);
    //  const formattedMessage = errorMessage?.replace("{number}", cause.cause.toString());
    super(ExceptionCode.validation, ExceptionCode.validation.toString(), cause);
  }
}
