import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { StaffListUseCase } from "@/application/staff/staff-list/usecase/StaffListUseCase";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";
import { DeleteStaffUseCase } from "@/application/staff/delete-staff/usecase/DeleteStaffUseCase";
import { DeleteStaffModel } from "@/domain/staff/delete-staff/model/DeleteStaffModel";
import { InviteStaffUseCase } from "@/application/staff/invite-staff/usecase/InviteStaffUseCase";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import { AcceptInviteUseCase } from "@/application/staff/invite-staff/accept/usecase/AcceptInviteUseCase";
import { AcceptInviteModel } from "@/domain/staff/invite-staff/accept/model/AcceptInviteModel";

export function createStaffController() {
  const useCases = {
    staffList: {
      useCase: new StaffListUseCase(),
      model: StaffListModel,
    },
    deleteStaff: {
      useCase: new DeleteStaffUseCase(),
      model: DeleteStaffModel,
    },
    inviteStaff: {
      useCase: new InviteStaffUseCase(),
      model: InviteStaffModel,
    },
    acceptInvite: {
      useCase: new AcceptInviteUseCase(),
      model: AcceptInviteModel,
    },
  };

  return new StaffController(useCases);
}

export class StaffController implements IController{
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}