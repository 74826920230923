import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IAddCandidateService } from "@/domain/interviewer/add-candidate/service/IAddCandidateService";
import { AddCandidateRequestDto } from "@/domain/interviewer/add-candidate/dto/AddCandidateRequestDto";

export default class AddCandidateService implements IAddCandidateService {
  async invite(
    request: AddCandidateRequestDto
  ): Promise<Result<any>> {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(request.data));
      if (request.cv[0]) { // tekil 
        formData.append("cv", request.cv[0]);
      }
      else if (request.cv) { // çoğul
        formData.append("cv", request.cv);
      }

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      return await instance
        .post(API_ROUTE.CANDIDATE, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }})
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_ADD_CANDIDATE)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.ADD_CANDIDATE))
      );
    }
  }
}
