import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import JobPostingDetailService from "../service/JobPostingDetailService";
import { IJobPostingDetailRepository } from "@/domain/interviewer/job-posting-detail/repository/IJobPostingDetailRepository";
import { JobPostingDetailRequestDto } from "@/domain/interviewer/job-posting-detail/dto/JobPostingDetailRequestDto";
import { JobPostingDetailResponseDto } from "@/domain/interviewer/job-posting-detail/dto/JobPostingDetailResponseDto";
const jobPostingDetailService = new JobPostingDetailService();

export class JobPostingDetailRepository implements IJobPostingDetailRepository {
  async getJobPostingDetail(request: JobPostingDetailRequestDto): Promise<Result<JobPostingDetailResponseDto>> {
    try {
      return await jobPostingDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<JobPostingDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<JobPostingDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.JOBPOSTING_DETAIL)));
    }
  }
}
