import { BaseDTO } from "@/core/base/BaseDTO";

export class AddQuestionRequestDto extends BaseDTO {
  data: {
    interview?: number;
    questionText?: string;
    questionType?: string;
    interviewQuestionOptions?: { optionText: string }[];
  }
  constructor(
    interview?: number,
    questionText?: string,
    questionType?: string,
    interviewQuestionOptions?: { optionText: string }[],
  ) {
    super();
    this.data = {
      interview,
      questionText,
      questionType,
      interviewQuestionOptions,
    }
  }
}
