export const USECASE = {
  LOGIN: "LoginUsecase",
  RESET_PASSWORD: "ResetPasswordUsecase",
  FORGOT_PASSWORD: "ForgotPasswordUsecase",
  GENERATE_OTP: "GenerateOTPUsecase",
  VERIFY_OTP: "VerifyOTPUsecase",
  COUNTRY_LIST: "CountryListUsecase",
  UPDATE_USER: "UpdateUserUsecase",
  ABOUT_USER: "AboutUserUsecase",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateUsecase",
  SEND_MESSAGE: "SendMessageUsecase",
  RETRY_ANSWER: "RetryAnswerUsecase",
  SESSION_LIST: "SessionListUsecase",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListUsecase",
  FILE_UPLOAD: "FileUploadUsecase",

  STAFF: {
    STAFF_LIST: "StaffListUsecase",
    ADD_STAFF: "AddStaffUsecase",
    DELETE_STAFF: "DeleteStaffUsecase",
    INVITE_STAFF: "InviteStaffUsecase",
    ACCEPT_INVITE: "AcceptInviteUsecase",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListUsecase",
    BUY_PRODUCT: "BuyProductUsecase",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingUsecase",
    GET_PRODUCT: "GetProductUsecase",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductUsecase",
  },
  STATISTIC: "StatisticUsecase",
  POLLSTER: {
    CREATE_POLL: "CreatePollUsecase",
    POLL_LIST: "PollListUsecase",
    STATISTIC: {
      POLL_LIST: "StatisticPollListUsecase",
      POLL_MESSAGE_LIST: "StatisticPollMessageListUsecase",
      POLL_RESPONSE_LIST: "StatisticPollResponseListUsecase",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailUsecase",
    },
    POLL_DETAIL: "PollDetailUsecase",
    DELETE_POLL: "DeletePollUsecase",
    UPDATE_POLL: "UpdatePollUsecase",
    INVITE_USER: "InviteUserUsecase",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingUsecase",
    CREATE_JOBPOSTING: "CreateJobPostingUsecase",
    JOBPOSTING_LIST: "JobPostingListUsecase",
    INTERVIEW_LIST: "InterviewListUsecase",
    CANDIDATE_LIST: "CandidateListUsecase",
    CANDIDATE_ACTION_LIST: "CandidateActionListUsecase",
    JOBPOSTING_DETAIL: "JobPostingDetailUsecase",
    INTERVIEW_DETAIL: "InterviewDetailUsecase",
    CANDIDATE_DETAIL: "CandidateDetailUsecase",
    DELETE_JOBPOSTING: "DeleteJobPostingUsecase",
    DELETE_INTERVIEW: "DeleteInterviewUsecase",
    UPDATE_JOBPOSTING: "UpdateJobPostingUsecase",
    UPDATE_JOBPOSTING_STATUS: "UpdateJobPostingStatusUsecase",
    UPDATE_INTERVIEW: "UpdateInterviewUsecase",
    ADD_CANDIDATE: "AddCandidateUsecase",
    DELETE_CANDIDATE: "DeleteCandidateUsecase",
    UPDATE_CANDIDATE: "UpdateCandidateUsecase",
    GET_MULTI_CANDIDATE_TEMPLATE: "GetMultipleCandidateTemplateUsecase",
    ADD_QUALIFICATION: "AddQualificationUsecase",
    REMOVE_QUALIFICATION: "RemoveQualificationUsecase",
    ADD_INTERVIEW_QUESTION: "AddQuestionUsecase",
    ADD_INTERVIEW_QUESTION_OPTION: "AddQuestionOptionUsecase",
    UPDATE_INTERVIEW_QUESTION: "UpdateQuestionUsecase",
    DELETE_INTERVIEW_QUESTION: "DeleteQuestionUsecase",
    DELETE_INTERVIEW_QUESTION_OPTION: "DeleteQuestionOptionUsecase",
    INTERVIEW_COUNTS: "InterviewCountUsecase",
    INTERVIEW_INVITE: "InterviewInviteUsecase",
    REVOKE_INTERVIEW_INVITE: "RevokeInterviewInviteUsecase",
    CANDIDATE_LIST_BY_INTERVIEW: "CandidateListByInterviewUsecase",
    JOBPOSTING_DETAIL_BY_INVITE: "JobPostingDetailByInviteKeyUsecase",
    CANDIDATE_CV_ANALYSIS: "CandidateCvAnalysisUsecase",
    CANDIDATE_INTERVIEW_RESPONSES: "CandidateInterviewResponsesUsecase",
    CANDIDATE_INTERVIEW_LOGS: "CandidateInterviewLogUsecase",
  }
};
