
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "create-modal",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();

    //Create form validation object
    const form = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
      date: Yup.date()
        .required(t("validators_error.required"))
        .max(
          new Date(2006, 11, 31),
          t("validators_error.min18age", { date: "31/12/2006" })
        )
        .label("Date"),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
    });

    onMounted(() => {
      setCurrentPageTitle(t("ogza.createModal.title"));
    });

    return {
      form,
    };
  },
});
