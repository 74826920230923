import { BaseDomain } from "@/core/base/BaseDomain";

export class RevokeInterviewInviteModel extends BaseDomain {
  interview?: number;
  candidate?: number;
  ok?: boolean;

  constructor(
    interview?: number,
    candidate?: number,
    ok?: boolean,
  ) {
    super();
    this.interview = interview;
    this.candidate = candidate;
    this.ok = ok;
  }
}
