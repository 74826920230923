<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">    {{ $t("modals.interview.createJobPostingWizard.createInterview") }}</h2>
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.defineQuestions") }}
      </div>
    </div>

    <!-- Mülakat Başlığı -->
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">{{ $t("modals.interview.interviewTitle") }}</label>
      <Field
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="interviewTitle"
        v-model="interviewTitle"
        @change="updateTitleDescription"
        :placeholder="$t('modals.interview.titlePlaceholder')"
      />
      <ErrorMessage
        name="interviewTitle"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <div class="mb-10 fv-row">
      <label class="form-label mb-3">{{ $t("modals.interview.description") }}</label>
      <Field
      as="textarea"
        type="text"
        class="form-control form-control-lg form-control-solid"
       name="interviewDesc"
        v-model="interviewDesc"
                @change="updateTitleDescription"
         :placeholder="$t('modals.interview.descriptionPlaceholder')"
      />
      <ErrorMessage
        name="interviewDesc"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <!-- Mülakat Türü Seç -->
    <!-- <div class="mb-0 fv-row">
      <label class="d-flex align-items-center form-label mb-5">
        Mülakat Türü Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
        ></i>
      </label>

      <div class="mb-0">
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/finance/fin001.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Özel Mülakat</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Tamamen özelleştirilmiş sorular ile mülakat oluşturun.</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="1"
              @change="showCustomQuestionInput = true"
            />
          </span>
        </label>

        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/graphs/gra006.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Star Metodolojisi</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Star metodolojisi ile adayınızdan kendini tanıtmasını ve
                mesleğe bakış açısını anlatmasını isteyin</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="2"
              @change="showCustomQuestionInput = false"
            />
          </span>
        </label>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const interviewTitle = ref("");
    const interviewDesc = ref("");
    const showCustomQuestionInput = ref(false);
    
    const updateTitleDescription = () => {
      emit("updateTitleDescription", {
        title: interviewTitle.value,
        description: interviewDesc.value,
      });
    };

    return {
      showCustomQuestionInput,
      updateTitleDescription,
      interviewTitle,
      interviewDesc
    };
  },
});
</script>

<style scoped>
.list-group-item {
  border: none;
  padding: 10px 15px;
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 4px;
}
</style>
