export default {
  project: "Job Assistant",
  year: "2025",
  common: {
    pleaseWait: "Please wait...",
    requiredInputs: "Fields marked with an asterisk are required.",
    process: "Process",
    loading: "Loading...",
    pleaseChoose: "Please choose...",
    loaded: "Loaded",
    or: "OR",
    layout: {
      contact: "Contact Us",
      profile: "My Profile",
      language: "Language",
      signOut: "Sign Out",
    },
    button: {
      create: "Create",
      add: "Add",
      start: "Start",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      verify: "Verify",
      ok: "OK",
      continue: "Continue",
      send: "Send",
      install: "Install",
      delete: "Delete",
      yes: "Yes",
      no: "No",
      invite: "Invite",
      sendInvite: "Send Invite",
      close: "Close",
      review: "Review",
      copy: "Copy",
      update: "Update",
      setPassive: "Set as Passive",
      setActive: "Set as Active",
      giveUp: "Give Up",
      back: "Back",
    },
    table: {
      process: "Process",
      notProcess: "Cannot be processed as it is the default",
      pagination: {
        desc: " showing from ",
        desc1: " results.",
        desc2: "items per page",
      },
    },
    times: {
      minuteAgo: "minute ago",
      hoursAgo: "hour ago",
      dayAgo: "day ago",
      monthAgo: "month ago",
    },
    dontLiked: "Don't Liked",
    continues: "Continues",
    active: "Active",
    inactive: "Inactive",
    waiting: "Pending",
    completed: "Completed",
    ended: "Ended",
    general: "General",
    special: "Special",
  },
  asideMenu: {
    modules: {
      header: "Modules",
      activeModules: "Active Modules",
      notFoundActiveModule: "No active module found.",
      hotelManagement: {
        title: "AI Hotel Assistant",
        assistant: "AI Assistant",
        history: "Conversation Archives",
        staffList: {
          title: "Staff List",
          desc: "On this page, you can view the staff registered to your company and review past AI assistant conversation records.",
        },
      },
      pollster: {
        title: "Pollster",
        polls: "Poll List",
        userAssignment: "Assign Poll",
        userPolls: "User Polls",
        myPolls: "My Polls",
        create: "Create Poll",
        emailAddresses: "Email Addresses",
      },
      interviewer: {
        title: "Interview",
        create: "Create Interview",
        createJobPosting: "Create Job Posting",
        historyList: "History List",
        list: "Interview List",
        jobPostingList: "Job Posting List",
        userAssignment: "Send Interview Invitation",
        candidateList: "Candidate List",
      },
    },
    admin: {
      header: "Admin",
      modules: "All Modules",
      accountTypes: "Account Types",
      accountStatus: "Account Status",
      staff: "Staff List",
      inviteStaff: "Add Staff",
    },
    ogza: {
      header: "Ogzatech Management",
      modules: {
        title: "Modules",
        allModules: "All Modules",
        createModul: "Create Module",
      },
      companies: "Registered Companies",
      createCompany: "Create Company",
      chatgptSettings: "ChatGpt Settings",
    },
    user: "User",
  },
  landingPage: {
    menu: {
      home: "Home",
      assistant: "Work Assistant",
      login: "Login",
    },
    headerBanner: {
      title: "Simplify Your Business Process with AI!",
      desc: "Elevate your business processes with AI! Meet our smart automation solutions that increase productivity, save time, and reduce costs. Lighten your workload, focus on important tasks, and multiply your success.",
    },
    container: {
      mainTitle: "AI Business Assistant",
      mainHeader: "AI-Powered Business Assistant",
      mainDesc:
        "Simplify your work significantly with our AI-powered assistant modules. Provide faster and more efficient service to your staff and customers quickly. These modules optimize your business processes, automate repetitive tasks, respond to customer requests instantly, and help you make strategic decisions with data analysis.",
      title: "AI Hotel Assistant",
      desc: "Evaluate customer reviews for your hotel with AI-supported sentiment analysis for a near-realistic assessment.",
      title2: "AI-Powered Pollster",
      desc2:
        "Conduct internal and external surveys efficiently and quickly with the help of AI. Determine and record the accuracy of responses with sentiment analysis.",
      title3: "Live Interview with AI",
      desc3:
        "Make your recruitment processes more efficient with AI. Conduct live interviews with candidates, analyze their performance, and identify the most suitable candidates. Thus, make faster and more accurate hiring decisions.",
    },
  },
  login: {
    title: "Login",
    desc: "Please enter your phone number to log in.",
    desc2: "Please enter your account information to log in.",
    email: "Email",
    enterEmail: "Please enter your email address...",
    password: "Password",
    enterPassword: "Please enter your password...",
    phone: "Phone Number",
    country: "Country",
    forgotPassword: "Forgot Password",
    button: "Login",
  },
  verifyOTP: {
    title: "SMS Verification",
    desc: "Please enter the verification code sent to your phone via SMS.",
    code: "Verification Code",
    enterCode: "Enter verification code...",
  },
  profileSet: {
    title: "Account Information",
    title2: "Create Profile",
    desc: "Please update your account information...",
    desc2: "Please create your account...",
    name: "Name",
    enterName: "Enter your name...",
    surname: "Surname",
    enterSurname: "Enter your surname...",
    email: "Email",
    enterEmail: "Enter your email address...",
    company: "Company",
    enterCompany: "Enter company name...",
    button: "Save and Login",
  },
  acceptInvite: {
    title: "Accept Invitation",
    desc: "Click the button below to accept the invitation.",
    button: "Accept",
  },
  companies: {
    createCompany: "Create New Company",
    createCompanyDesc:
      "Use AI modules in your own company and streamline your workflow.",
  },
  createCompany: {
    title: "Create Company",
    desc: "Please enter your company information...",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    companyDesc: "Company Description",
    enterCompanyDesc: "Describe your company...",
    sector: "Sector",
    enterSector: "Enter sector...",
  },
  dashboard: {
    title: "Dashboard",
    chart: {
      title: "Total Transaction Count",
      desc: "Graph of total transactions for the last 7 days",
    },
    sections: {
      totalProcessCount: "Total Record Count",
      modalCount: "Module Count",
      staffCount: "Staff Count",
      todaysprocessCount: "Today's Transaction Count",
    },
    lastProcess: {
      title: "Latest Records",
      type: "RECORD TYPE",
      nameSurname: "NAME SURNAME",
      email: "EMAIL",
      time: "COMPLETION TIME",
    },
  },
  profile: {
    title: "Profile",
    personelInfo: "Personal Information",
    name: "Name",
    enterName: "Enter your name",
    surname: "Surname",
    enterSurname: "Enter your surname",
    company: "Company",
    enterCompany: "Enter company name",
    phone: "Phone",
    enterPhone: "Enter your phone number...",
    email: "Email",
    enterEmail: "Enter your email address...",
  },
  modals: {
    detail: {
      pageTitle: "Module Details",
      current: "Current",
      modalDescription: "Module Description",
      packages: "Packages",
      numberOfUses: "Number of Uses",
      dayCount: "Number of Days",
      purchased: "Purchased",
      notPurchased: "Purchasable",
      free: "Free",
      FAQS: "Frequently Asked Questions",
      settings: "Module Settings",
      activeAiModel: "Active AI Model",
      details: {
        title: "Details",
        update: "Update",
        techSupport: "Technical Support",
        licence: "License",
        installation: "Installation",
        lifeLong: "Lifetime",
        full: "Full",
        oneClick: "One-Click",
      },
    },
    hotel: {
      title: "AI Hotel Assistant",
      search: "Search...",
      startNewSession: "Start New Chat",
      newSession: "New Chat",
      sessionCount: "Remaining Session Count",
      zeroSession: "No chat history found.",
      enterMessage: "Your message...",
      emptyMessage: "Please do not send empty messages.",
      staffChatHistory: "You are currently viewing the staff chat history.",
      staffHistories: "Staff Chat Histories",
      staffHistoriesDesc:
        "Please select the chat history you would like to review...",
      newChatDesc:
        "Welcome to the hotel assistant! Please send your topic of discussion or customer review related to your hotel. Our AI will provide realistic responses with its exceptional capabilities to ensure customer satisfaction.",
      closedSession:
        "This chat session has been completed. Please click on the 'Start New Chat' button on the left menu to begin a new chat.",
      staffList: {
        pageTitle: "Staff Members",
        title: "Staff List",
        desc: "You can view the staff registered to your company and review past AI assistant module chat records on this page.",
        inviteStaff: {
          title: "Add Staff",
          createStaff: "Create Staff Record",
          name: "Staff Name",
          enterName: "Enter name...",
          surname: "Staff Surname",
          enterSurname: "Enter surname...",
          email: "Staff Email",
          enterEmail: "Enter email address...",
          phone: "Staff Phone",
          enterPhone: "Enter phone number...",
          birthDate: "Birth Date",
          enterBirthDate: "Enter birth date...",
          department: "Department",
          enterDepartment: "Enter department...",
          position: "Position",
          enterPosition: "Enter position...",
        },
        name: "Name",
        surname: "Surname",
        phone: "Phone Number",
        email: "Email",
        department: "Department",
        mission: "Mission",
        sessionCount: "Chat Count",
        zeroCount: "No staff records found.",
      },
    },
    pollster: {
      title: "Pollster",
      poll: "Poll",
      createPollster: "Create Poll",
      pollDetails: "Poll Details",
      userAssignment: "User Poll Assignment",
      createLink: "Create Link",
      staffPoll: "Staff Poll",
      singlePollType: "Single Poll Type",
      singlePollTypeDesc:
        "Single polls are created to be sent as one-time use for each individual user.",
      addQuestion: "Add Question",
      openEnded: "Open-Ended",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      responseOptions: "Response Options",
      updateEndDate: "Update End Date",
      updateEndDateDesc:
        "Please enter a new date to update the poll's end date.",
      addOption: "Add Option",
      startPoll: "Start Poll",
      emailPoll: "Registered Email Poll",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      enterEmailDesc:
        "NOTE: To enter multiple email addresses, please separate them with a comma.",
      selectPoll: "Select Poll...",
      pollDesc: "Poll Description",
      link: "Poll Link",
      type: "Poll Type",
      staff: "Staff",
      sessionCode: "Session Code",
      enterSessionCode: "Enter Session Code...",
      pollsHistorys: "Polls History",
      polls: "Polls",
      pollsDesc:
        "You can view the polls you've predefined in the Pollster app on this page.",
      userPolls: {
        title: "User Polls",
        desc: "You can view the polls conducted by users in the Pollster app on this page.",
        poll: "Poll",
        status: "Status",
        duration: "Duration",
        pollType: "Poll Type",
        endDate: "End Date",
        detail: "Detail",
      },
      userPollDetail: {
        header: "User Poll Details",
        tabs: {
          one: "Poll Information",
          two: "User Responses",
          three: "Conversation Logs",
        },
      },
      isStaff: "STAFF?",
      nameSurname: "NAME SURNAME",
      date: "Completion Time",
      pollTitle: "Poll Title",
      enterTitle: "Enter title...",
      desc: "Description",
      enterDesc: "Enter description...",
      startDate: "Start Date",
      enterStartDate: "Select date...",
      endDate: "End Date",
      enterEndDate: "Select date...",
      duration: "Duration",
      percentComplete: "Percent Complete",
      status: "Status",
      questionCount: "Question Count",
      sessionCount: "Session Count",
      question: "Question",
      userAnswer: "User's Answer",
      enterQuestion: "Enter the question...",
      questionType: "Question Type",
      enterQuestionType: "Select question type...",
      emails: {
        title: "Email Addresses",
        desc: "You can manage the registered email addresses in the Pollster app on this page.",
        createNewEmail: "Add New Email",
        createEmailTitle: "Add Email",
        createEmailDesc:
          "Enter the email address you want to add to the module",
        email: "Email",
        enterEmail: "Enter email address...",
        pollCount: "Poll Count",
      },
      firstAiMessage:
        "Hello, I’m Pelin. Please answer the questions I’m going to ask honestly. All your feedback will be recorded and evaluated to improve our products.",
      pushToTalk: "Press to talk...",
      aiPollster: "AI Pollster",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Duration",
      zeroCount: "No polls found. Please add a new poll.",
    },
    interview: {
      title: "Interviewer",
      createJobPosting: "Create Job Posting",
      createJobPostingWizard: {
        chooseJobPosting: "Select Job Posting",
        chooseJobPostingDesc: "Choose a job posting",
        defineJobPosting:
          "Define the position for which you want to create an interview.",
        step1Desc:
          "Select the job posting you want to create an interview for or create a new one.",
        step1NewJobposting: "Create New Posting",
        createInterview: "Create Interview",
        createInterviewDesc: "Define the type of interview and create it",
        defineInterview:
          "Please fill in the details of the interview you want to create.",
        setQuestions: "Define Questions",
        setQuestionsDesc: "Create and edit interview questions",
        defineQuestions:
          "To create the interview, please add questions and customize them according to the position.",
        min3Questions:
          "Please enter at least 3 questions to create the interview.",
        addQuestionToInterview: "Add Question to Interview",
        addQuestion: "Add New Question",
        addThisQuestion: "Add This Question",
        addedQuestions: "Added Questions",
        sortInterviews: "Sort Interviews",
        step4Desc:
          "Set the priority order for interviews based on the selected job posting.",
        sortInterviewsDesc: "Set priority order",
        createdInterview: "Interview Created!",
        successCreatedInterview: "The interview was successfully created.",
        step5Desc:
          "Congratulations! The interview has been successfully created. After sending invitations to the candidates you want to interview, you can check the results through the system once they complete the interview.",
        seeAllJobpostings: "Click here to view all job postings.",
        createdInterviewDesc: "Start conducting the interview",
      },
      notFoundJobPosting: "Job Posting not found.",
      createJobPostingToolTip: "Create a job posting for the open position",
      interview: "Interview",
      deleteInterview: "Delete Interview",
      allInterviews: "All Interviews",
      sortInterviews: "Sort Interviews",
      sortInterviewsDesc: "Set the priority order of interviews.",
      interviewCount: "Interview Count",
      totalInterviewCount: "Total Interviews",
      completedInterview: "Completed",
      ongoingInterview: "Ongoing",
      canceledInterview: "Canceled",
      updateInterview: "Update Interview",
      updateQuestions: "Update Questions",
      interviewQuestions: "Interview Questions",
      interviewDetails: "Interview Details",
      updateInterviewToolTip:
        "Please fill in the details below to update the interview.",
      jobPosting: "Job Posting",
      deleteJobPosting: "Delete Job Posting",
      editJobPosting: "Edit Job Posting",
      jobPostingDetails: "Job Posting Details",
      jobPostingCandidates: "Candidates Applied for Job Posting",
      jobPostings: "Job Postings",
      allJobPostings: "All Job Postings",
      activeJobPostings: "Active Job Postings",
      inactiveJobPostings: "Inactive Job Postings",
      pendingJobPostings: "Pending Job Postings",
      completedJobPostings: "Completed Job Postings",
      candidateList: "Candidate List",
      candidateCount: "Candidate Count",
      editCandidate: "Edit Candidate",
      morePieces: "more pieces...",
      inviteCandidate: "Invite Candidate",
      jobPostingTitle: "Job Posting Title",
      enterJobPostingTitle: "Enter title...",
      createInterview: "Create Interview",
      jobPostingDesc: "Job Posting Description",
      viewDetail: "View Details",
      qualifications: "Qualifications",
      forAddQualification: "Type to add an option and press Enter",
      pleaseWriteQuestion: "Please write a question.",
      pleaseAddOptions: "Please add options.",
      min3Qualification: "Please enter at least 3 qualifications.",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      link: "Interview Link",
      interviewsHistorys: "Interview Histories",
      nameSurname: "NAME SURNAME",
      date: "Completion Date",
      status: "Status",
      questionCount: "Question Count",
      sessionCount: "Session Count",
      interviewTitle: "Interview Title",
      titlePlaceholder:
        "E.g.: Technical Evaluation Interview / First Interview Stage",
      enterInterviewTitle: "Enter interview title...",
      position: "Position",
      enterPosition: "Enter position...",
      description: "Description",
      descriptionPlaceholder:
        "E.g.: This interview evaluates candidates' technical skills and experience for the software development position.",
      enterDescription: "Enter description...",
      interviewDetail: "Interview Detail",
      startDate: "Start Date",
      enterStartDate: "Select a date...",
      endDate: "End Date",
      enterEndDate: "Select a date...",
      addQuestion: "Add Question",
      question: "Question",
      writeQuestionPressEnter: "Write your question and press Enter",
      questionType: "Question Type",
      enterQuestion: "Write the question...",
      enterQuestionType: "Select question type...",
      openEnded: "Open-Ended",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      responseOptions: "Response Options",
      addOption: "Add Option",
      deleteQuestion: "Delete Question",
      areYouSureAboutDeleteOption:
        "Are you sure you want to delete this option?",
      areYouSureAboutDeleteQuestion:
        "Are you sure you want to delete this question?",
      zeroQuestion: "No questions added yet.",
      startInterview: {
        title: "Start Interview",
        jobPosting: "Applied Job Posting",
        desc: "Dear candidate, please read the following before starting the interview.",
        longDesc:
          "Dear candidate, thank you for applying to our job posting. Please ensure that you review the interview details and confirm that it corresponds to the position you applied for before starting the interview.",
        interviewType: "Interview Type",
        questionCount: "Number of Questions",
        estimatedTime: "Estimated Time",
        importantInfoAndAgreements: "Important Information and Agreements",
        info1:
          "You must not switch the window during the interview. Otherwise, this may affect your score.",
        info2:
          "The interview will be conducted with audio and video. Please ensure your camera and microphone are working.",
        info3:
          "The questions during the interview may be open-ended, multiple-choice, or single-option. All questions are expected to be answered correctly.",
        info4:
          "Audio and video recordings will be taken during the interview. All data will be recorded.",
        kvkk: "I give my explicit consent for the processing of my personal data. This consent means that I agree to the collection and use of my data throughout the interview process.",
      },
      finishInterview: "Finish Interview",
      interviewResults: {
        title: "Interview Results",
        thankYouMessage: "Thank you for participating in the interview.",
        resultsHeader: "Thank You!",
        followUpMessage:
          "You can track the interview result and updates via email.",
        answeredQuestions: "Answered Questions",
        timeElapsed: "Elapsed Time",
        remainingTime: "Remaining Time",
        backToHome: "Back to Home",
      },
      addCandidate: {
        title: "Create Interview Invitation",
        interview: "Interview",
        selectInterview: "Select an interview...",
        name: "Name",
        enterName: "Enter name...",
        surname: "Surname",
        enterSurname: "Enter surname...",
        email: "Email",
        enterEmail: "Enter email...",
      },
      userInterviewDetail: {
        header: "User Interview Details",
        tabs: {
          one: "Interview Information",
          two: "User Responses",
          three: "Conversation Logs",
        },
      },
      candidate: {
        candidateInfo: "Candidate Information",
        name: "First Name",
        surname: "Last Name",
        email: "Email",
        cv: "CV",
        candidateStatus: "Candidate Status",
        activeCandidate: "Active Candidate",
        deActiveCandidate: "Inactive Candidate",
        eliminated: "Eliminated",
        eliminatedCandidate: "Eliminated",
        uploadCV: "Upload CV",
        uploadCVDesc: "Only pdf, doc, and docx files (max 2mb)",
        deleteCandidate: "Delete Candidate",
        positionEligibility: "Position Eligibility",
        cvAnalysis: "CV Analysis",
        downloadCV: "Download CV",
        sendInvite: "Send Invite",
        sendInterviewInvite: "Send Interview Invite",
        appliedJobPosting: "Applied Job Posting",
        expirationDate: "Expiration Date",
        jobPostingCandidateList: "Job Posting Candidate List",
        candidateSelected: "Candidate Selected",
        deleteCandidates: "Delete Candidates",
        inviteCandidate: "Invite Candidate",
        downloadTemplateDesc:
          "To add multiple candidates, first download the template below. After filling in the candidate information, upload the template again to add the candidates to the system.",
        downloadTemplate: "Download Template",
        uploadCandidateList: "Upload Candidate List",
        addMultiCandidates: "Add Multiple Candidates",
        selectedFile: "Selected File",
        fileByte: "Size",
        fileValidity: "File Validity",
        addMultiCandidatesDesc:
          "Add candidates in bulk using an excel or csv file.",
        addSingleCandidate: "Add Single Candidate",
        addSingleCandidateDesc:
          "Enter the candidate's information and invite them to the job posting.",
        inviteCandidateInterviewJobpostingDate:
          "The validity period of this job posting is between {startDate} and {endDate}.",
        zeroCount: "No candidates found.",
        pleaseAddCandidate: "Please add a new candidate.",
        noPositionSuitability: "Not available",
      },
      firstAiMessage:
        "Hello, I am the AI interviewer. I’m here to get to know you. Please feel comfortable and answer my questions naturally.",
      pushToTalk: "Hold and speak...",
      percentComplete: "Interview Completion Percentage: ",
      aiInterviewer: "AI Interviewer",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Duration",
      zeroCount: "No interviews found. Please add a new interview.",
      createNewInterview: "Create New Interview",
      latestMovements: "Latest Movements",
      processDate: "Process Date",
      lastprocessDate: "Last Process Date",
      noRecord: "No records found.",
    },
  },
  modalList: {
    title: "Module List",
    allModules: "All",
    myPurchases: "My Purchases",
    freeOnes: "Free Ones",
    availableForPurchase: "Available for Purchase",
    purchased: "Purchased",
    notFound: "Module not found.",
  },
  ogza: {
    staffList: {
      pageTitle: "Staff",
      title: "Staff List",
      desc: "You can view the staff registered in your company and review their past records on this page.",
      inviteStaff: {
        title: "Add Staff",
        createStaff: "Create Staff Record",
        name: "Staff Name",
        enterName: "Enter name...",
        surname: "Staff Surname",
        enterSurname: "Enter surname...",
        email: "Staff Email",
        enterEmail: "Enter email address...",
        phone: "Staff Phone",
        enterPhone: "Enter phone number...",
        birthDate: "Birth Date",
        enterBirthDate: "Enter birth date...",
        department: "Department",
        enterDepartment: "Enter department...",
        position: "Position",
        enterPosition: "Enter position...",
      },
      name: "Name",
      surname: "Surname",
      email: "Email",
      phone: "Phone",
      department: "Department",
      mission: "Position",
      status: "Status",
      zeroCount: "No staff records found.",
    },
    chatGptSettings: {
      title: "ChatGPT Settings",
      activeVersion: "Active ChatGPT Version",
      selectVersion: "Select Version...",
      apiKey: "API Key",
      enterApiKey: "Enter API Key...",
    },
    createModal: {
      title: "Create Module",
      modalIcon: "Module Icon",
      modalIconDesc: "Allowed file types: png, jpg, jpeg..",
      modalName: "Module Name",
      enterModalName: "Enter module name...",
      modalDesc: "Module Description",
      enterModalDesc: "Enter module description...",
      price: "Price",
      enterPrice: "Enter price...",
      detailedDesc: "Detailed Description",
      enterDetailedDesc: "Enter description...",
      question1: "Question 1",
      question2: "Question 2",
      enterQuestion: "Enter question...",
      answer: "Answer",
      enterAnswer: "Enter answer...",
      adminPropmt: "Module Admin Prompt",
      enterPrompt: "Enter prompt...",
      userSessionLimit: "User Session Limit",
      enterLimit: "Enter limit...",
      details: "Details",
      speakOutLoud: "Speak Out Loud",
      videoCall: "Video Call",
      guestUser: "Can guest users access?",
    },
    createCompany: {
      title: "Create Company",
      companyName: "Company Name",
      enterCompanyName: "Enter company name...",
      companyCategory: "Business Category",
      enterCompanyCategory: "Enter category...",
      managerName: "Manager's Name",
      enterManagerName: "Enter manager's name...",
      managerSurname: "Manager's Surname",
      enterManagerSurname: "Enter manager's surname...",
      email: "Email Address",
      enterEmail: "Enter email address...",
      country: "Country",
      phone: "Phone Number",
      enterPhone: "Enter phone number...",
      department: "Department",
      enterDepartment: "Enter department...",
      accountType: "Account Type",
      selectAccountType: "Select account type...",
    },
  },
  accountStatus: {
    pageTitle: "Account Status",
    title: "Account Information",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    email: "Email",
    enterEmail: "Enter email address...",
    accountType: "Account Type",
    activeModalCount: "Active Module Count",
    processCountofPeriod: "Transaction Count of Period",
    purchaseHistory: "Purchase History",
    receivedModule: "Received Module",
    price: "Price",
    processDate: "Transaction Date",
  },
  accountTypes: {
    title: "Account Types",
    basic: "Basic Account",
    yourActiveAccount: "Your Active Account",
  },
  error404: {
    nothing: "Looks like there's nothing here",
    return: "Return to Homepage",
  },
  error500: {
    title: "System Error",
    desc: "You are not authorized to access this page!",
    return: "Return to Homepage",
  },
  swalMessages: {
    SUCCESSFUL: "Action Successful!",
    VALIDATED_FILE: "File successfully validated.",
    NOT_ACTIVE_YET: "This action is not active yet, please try again later.",
    FAILED: "Action Failed!",
    SESSION_TIME_OUT:
      "Session expired. Please log in again with your user credentials.",
    SESSION_CLOSED:
      "The conversation is completed. To start a new one, please click the 'Start New Conversation' button in the left menu.",

    COPY_JOBPOSTING: "Do you want to copy this job posting?",
    COPY_JOBPOSTING_DESC:
      "Do you want to copy this job posting exactly and create a new one?",
    DELETE_CANDIDATE: "Are you sure you want to delete this candidate?",
    INVITE_INTERVIEW:
      "Are you sure you want to send this interview link to the candidate?",
    MAKE_PASSIVE_JOBPOSTING: "Do you want to make this job posting passive?",
    MAKE_PASSIVE_JOBPOSTING_DESC:
      "If you confirm this action, this job posting will no longer be accessible by candidates.",
    CANDIDATE_CV_ANALYSIS:
      "Are you sure you want to analyze this candidate's CV?",
    CANDIDATE_CV_ANALYSIS_DESC:
      "This action will prevent the job posting from being edited.",
    MAKE_ACTIVE_JOBPOSTING: "Do you want to make this job posting active?",
    MAKE_ACTIVE_JOBPOSTING_DESC:
      "If you confirm this action, this job posting will be accessible by candidates.",
    DELETE_JOBPOSTING: "Are you sure you want to delete this job posting?",
    DELETE_INTERVIEW: "Are you sure you want to delete this interview?",
    CANNOT_BE_UNDONE: "This action cannot be undone.",
    CANNOT_BE_UPDATE_JOBPOSTING:
      "This action will make the job posting uneditable.",

    CONFIRM_BUTTON_TEXT: "OK",
    API_ERROR_MESSAGE:
      "Technical error. Unable to connect to the server, please try again.",

    UNKNOWN_ERROR_MESSAGE: "An unknown error occurred.",
    ARE_YOU_SURE: "Are you sure?",
    SURE_DELETE_STAFF: "Are you sure you want to delete this staff member?",
    SURE_DELETE_POLL: "Are you sure you want to delete this poll?",
    SURE_DELETE_INTERVIEW: "Are you sure you want to delete this interview?",

    TIME_ERROR: "Time has expired. Please log in again.",

    ERROR: {
      ERROR_CODE: " Error code: ",
      ERROR_GENERATE: "Verification code generation failed! Please try again.",
      ERROR_VERIFY: "Verification failed! Please try again.",
      ERROR_VERIFY_REMAINING_ATTEMPTS:
        "You've exceeded your attempt limit. Please try again later.",
      ERROR_UPDATE_USER:
        "User information could not be updated. Please try again.",
      ERROR_CHANGE_TENANT_USER: "Technical error! Failed to switch tenant.",
      ERROR_ABOUT_USER: "Technical error! Failed to load personal information.",
      ERROR_ADD_STAFF: "Technical error! Failed to register staff.",
      ERROR_DELETE_STAFF: "Technical error! Failed to delete staff.",
      ERROR_STAFF_LIST: "Technical error! Failed to retrieve staff list.",
      ERROR_INVITE_STAFF: "Technical error! Failed to invite staff.",
      ERROR_POLL_LIST: "Technical error! Failed to retrieve poll list.",
      ERROR_ACCEPT_INVITE:
        "Invalid invitation link, please contact your administrator.",
      ERROR_PRODUCT_LIST: "Technical error! Failed to retrieve module list.",
      ERROR_BUY_PRODUCT:
        "Transaction failed! Could not purchase the module. Please contact the administrator.",
      ERROR_UPDATE_PRODUCT_MODEL_SETTING:
        "Transaction failed! Could not update module model.",
      ERROR_DELETE_POLL: "Technical error! Failed to delete poll.",
      ERROR_CREATE_POLL: "Technical error! Failed to create poll.",
      ERROR_UPDATE_POLL: "Technical error! Failed to update poll.",
      ERROR_INVITE_USER: "Technical error! Failed to invite users to the poll.",
      ERROR_FILE_UPLOAD: "Technical error! Failed to upload file.",
      ERROR_POLL_DETAIL: "Technical error! Failed to display poll details.",
      ERROR_POLL_STATISTICS_LIST: "Technical error! Failed to list past polls.",
      ERROR_POLL_STATISTICS_MESSAGE_LIST:
        "Technical error! Failed to display poll log messages.",
      ERROR_POLL_STATISTICS_SESSION_DETAIL:
        "Technical error! Failed to display poll details and statistics.",
      ERROR_POLL_STATISTICS_RESPONSE_LIST:
        "Technical error! Failed to display poll responses.",
      ERROR_INTERVIEW_LIST: "Technical error! Failed to list interviews.",
      ERROR_JOBPOSTING_LIST: "Technical error! Failed to list jobpostings.",
      ERROR_CANDIDATE_LIST: "Technical error! Failed to list candidates.",
      ERROR_GET_MULTI_CANDIDATE_TEMPLATE:
        "Technical error! Failed to load template example. Please try again later.",
      ERROR_INTERVIEW_DETAIL:
        "Technical error! Failed to display interview details.",
      ERROR_INTERVIEW_COUNTS:
        "Technical error! Failed to display interview counts.",
      ERROR_JOBPOSTING_DETAIL:
        "Technical error! Failed to display job posting details.",
      ERROR_CANDIDATE_DETAIL:
        "Technical error! Failed to display candidate details.",
      ERROR_INTERVIEW_INVITE:
        "Technical error! Failed to send interview link to the candidate.",
      ERROR_INVITED_INVALID_EMAIL:
        "Invalid email! Interview link sending failed.",
      ERROR_DELETE_INTERVIEW: "Technical error! Failed to delete interview.",
      ERROR_DELETE_JOBPOSTING: "Technical error! Failed to delete jobposting.",
      ERROR_DELETE_CANDIDATE: "Technical error! Failed to delete candidate.",
      ERROR_CREATE_INTERVIEW: "Technical error! Failed to create interview.",
      ERROR_CREATE_JOBPOSTING: "Technical error! Failed to create jobposting.",
      ERROR_ADD_CANDIDATE:
        "Technical error! Failed to invite candidate to the interview.",
      ERROR_UPDATE_INTERVIEW: "Technical error! Failed to update interview.",
      ERROR_UPDATE_JOBPOSTING: "Technical error! Failed to update jobposting.",
      ERROR_UPDATE_CANDIDATE: "Technical error! Failed to update candidate.",
      ERROR_REMOVE_QUALIFICATION:
        "Technical error! Failed to delete qualification.",
      ERROR_ADD_QUALIFICATION: "Technical error! Failed to add qualification.",
      ERROR_ADD_QUESTION: "Technical error! Failed to add the question.",
      ERROR_ADD_QUESTION_OPTION:
        "Technical error! Failed to add the question option.",
      ERROR_DELETE_QUESTION: "Technical error! Failed to delete the question.",
      ERROR_DELETE_QUESTION_OPTION:
        "Technical error! Failed to delete the question option.",
      ERROR_UPDATE_QUESTION: "Technical error! Failed to update the question.",
      ERROR_SEND_MESSAGE:
        "You cannot start a new session because your limit has been reached. Please upgrade your account.",

      ERROR_GET_SESSION_LIST:
        "Technical error, failed to retrieve conversation history information!",
      ERROR_RETRY_ANSWER:
        "You cannot generate an answer because your limit has been reached. Please upgrade your account.",
      ERROR_GET_STATISTICS_LIST: "Technical error! Failed to load statistics.",
      ERROR_CV_ANALYSIS: "Technical error! Failed to analyze CV.",
    },
    SUCCESS: {
      SUCCESS_UPDATE_USER: "User information updated successfully!",
      SUCCESS_DELETE_STAFF: "Staff member deleted successfully!",
      SUCCESS_INVITE_STAFF: "Staff invited successfully!",
      SUCCESS_ACCEPT_INVITE: "Access granted successfully.",
      SUCCESS_BUY_PRODUCT:
        "Congratulations, you successfully purchased the module!",
      SUCCESS_UPDATE_PRODUCT_MODEL_SETTING:
        "Module model updated successfully.",
      SUCCESS_DELETE_POLL: "Poll deleted successfully!",
      SUCCESS_CREATE_POLL: "Poll created successfully!",
      SUCCESS_UPDATE_POLL: "Poll updated successfully!",
      SUCCESS_INVITE_USER: "Poll invitation link sent successfully!",
      SUCCESS_INVITE_USERS: "Poll invitation links sent successfully!",
      SUCCESS_FILE_UPLOAD: "File uploaded successfully!",
      SUCCESS_CREATE_INTERVIEW: "Interview successfully created!",
      SUCCESS_UPDATE_INTERVIEW: "Interview successfully updated!",
      SUCCESS_DELETE_INTERVIEW: "Interview successfully deleted!",
      SUCCESS_CREATE_JOBPOSTING: "Job posting successfully created!",
      SUCCESS_UPDATE_JOBPOSTING: "Job posting successfully updated!",
      SUCCESS_DELETE_JOBPOSTING: "Job posting successfully deleted!",
      SUCCESS_COPY_JOBPOSTING: "Job posting successfully copied!",
      SUCCESS_MAKE_PASSIVE_JOBPOSTING:
        "Job posting successfully set to passive.",
      SUCCESS_MAKE_ACTIVE_JOBPOSTING: "Job posting successfully set to active.",
      SUCCESS_ADD_CANDIDATE: "Candidate successfully invited to the interview.",
      SUCCESS_UPDATE_CANDIDATE: "Candidate successfully updated!",
      SUCCESS_INVITE_INTERVIEW:
        "Interview link successfully sent to the candidate!",
      SUCCESS_DELETE_CANDIDATE: "Candidate successfully deleted!",
      SUCCESS_CV_ANALYSIS: "Candidate's CV analysis successfully completed!",
      SUCCESS_REMOVE_QUALIFICATION:
        "Required qualification successfully removed!",
      SUCCESS_ADD_QUALIFICATION: "Qualification successfully added!",
      SUCCESS_ADD_QUESTION: "New question successfully added!",
      SUCCESS_ADD_QUESTION_OPTION: "Question option successfully added!",
      SUCCESS_DELETE_QUESTION: "Question successfully deleted!",
      SUCCESS_DELETE_QUESTION_OPTION: "Question option successfully deleted!",
      SUCCESS_UPDATE_QUESTION: "Question successfully updated!",
      SUCCESS_SEND_MESSAGE:
        "Congratulations, the message was sent successfully.",
      SUCCESS_RETRY_ANSWER: "Congratulations, the answer was regenerated.",
      SUCCESS_GENERATE: "Verification code sent successfully!",
      SUCCESS_VERIFY: "Verification successful!",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Please enter your email address.",
      invalid: "Please enter a valid email address.",
    },
    password: {
      required: "Please enter your password.",
      min: "Password must be at least 6 characters long.",
      match: "Passwords must match.",
    },
    identity: {
      required: "Please enter your identity number.",
      length: "Identity number must consist of 11 digits.",
    },
    credential: {
      required: "Please enter your email address and password.",
      credential: "Invalid email address or password.",
    },
  },
  validators_error: {
    title: "Error!",
    required: "Please do not leave it blank.",
    typeError: "Please enter data of the appropriate type.",
    email: "Please enter a valid email address.",
    sameEmails: "You have already entered this email address.",
    minlength: "Cannot be less than ",
    maxlength: "Cannot be more than ",
    zeroValue: "The value cannot be less than 0.",
    phoneNumberFull: "Please enter your full phone number.",
    requiredCode: "Please do not leave the verification code blank.",
    requiredDesc: "Please do not leave the description blank.",
    requiredMin3Features: "Please enter at least 3 features.",
    codeInvalid: "Please enter a 6-digit verification code.",
    min18age: "Cannot be under 18 years of age.",
    startDateBiggerEndDate:
      "The end date cannot be earlier than the start date.",
    max2MBFileByte: "The file size cannot exceed 2MB.",
    requiredCV: "Please upload a CV.",
    requiredFullXlsx: "Please upload a filled xlsx file.",
    requiredTemplateXlsx:
      "Invalid file format! Please fill out and upload the xlsx template.",
    max5MBFileByte: "The file size cannot exceed 5MB.",
    invalidFileType: "Invalid file format.",
    onlyPDFandDOCX: "Only PDF or DOCX files can be uploaded.",
    requiredFileName: "File name could not be retrieved. Please try again.",
    addCvCandidate: "Please add the CV for the candidate in position {index}.",
    min3Qualification: "The number of qualifications cannot be less than 3.",
max20Qualification: "The number of features cannot exceed 20.",
    pleaseWaitForCV: "Please wait for the CV file upload to complete.",
    addRequiredEmailCandidate:
      "Please enter a valid email address for the candidate in position {index}.",
    existCandidateEmail:
      "Please provide a different email address for the candidate in position {index} (Email already exists).",
    requiredCandidateInfo:
      "Please fill out all the information correctly for the candidate in position {index}.",
  },
};
