export const REPOSITORY = {
  LOGIN: "LoginRepository",
  RESET_PASSWORD: "ResetPasswordRepository",
  FORGOT_PASSWORD: "ForgotPasswordRepository",
  GENERATE_OTP: "GenerateOTPRepository",
  VERIFY_OTP: "VerifyOTPRepository",
  COUNTRY_LIST: "CountryListRepository",
  UPDATE_USER: "UpdateUserRepository",
  ABOUT_USER: "AboutUserRepository",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateRepository",
  SEND_MESSAGE: "SendMessageRepository",
  RETRY_ANSWER: "RetryAnswerRepository",
  SESSION_LIST: "SessionListRepository",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListRepository",
  FILE_UPLOAD: "FileUploadRepository",

  STAFF: {
    STAFF_LIST: "StaffListRepository",
    ADD_STAFF: "AddStaffRepository",
    DELETE_STAFF: "DeleteStaffRepository",
    INVITE_STAFF: "InviteStaffRepository",
    ACCEPT_INVITE: "AcceptInviteRepository",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListRepository",
    BUY_PRODUCT: "BuyProductRepository",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingRepository",
    GET_PRODUCT: "GetProductRepository",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductRepository",
  },
  STATISTIC: "StatisticRepository",
  POLLSTER: {
    CREATE_POLL: "CreatePollRepository",
    POLL_LIST: "PollListRepository",
    STATISTIC: {
      POLL_LIST: "StatisticPollListRepository",
      POLL_MESSAGE_LIST: "StatisticPollMessageListRepository",
      POLL_RESPONSE_LIST: "StatisticPollResponseListRepository",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailRepository",
    },
    POLL_DETAIL: "PollDetailRepository",
    DELETE_POLL: "DeletePollRepository",
    UPDATE_POLL: "UpdatePollRepository",
    INVITE_USER: "InviteUserRepository",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingRepository",
    CREATE_JOBPOSTING: "CreateJobPostingRepository",
    JOBPOSTING_LIST: "JobPostingListRepository",
    INTERVIEW_LIST: "InterviewListRepository",
    CANDIDATE_LIST: "CandidateListRepository",
    CANDIDATE_ACTION_LIST: "CandidateActionListRepository",
    JOBPOSTING_DETAIL: "JobPostingDetailRepository",
    INTERVIEW_DETAIL: "InterviewDetailRepository",
    CANDIDATE_DETAIL: "CandidateDetailRepository",
    DELETE_JOBPOSTING: "DeleteJobPostingRepository",
    DELETE_INTERVIEW: "DeleteInterviewRepository",
    UPDATE_JOBPOSTING: "UpdateJobPostingRepository",
    UPDATE_JOBPOSTING_STATUS: "UpdateJobPostingStatusRepository",
    UPDATE_INTERVIEW: "UpdateInterviewRepository",
    ADD_CANDIDATE: "AddCandidateRepository",
    DELETE_CANDIDATE: "DeleteCandidateRepository",
    UPDATE_CANDIDATE: "UpdateCandidateRepository",
    GET_MULTI_CANDIDATE_TEMPLATE: "GetMultipleCandidateTemplateRepository",
    ADD_QUALIFICATION: "AddQualificationRepository",
    REMOVE_QUALIFICATION: "RemoveQualificationRepository",
    ADD_INTERVIEW_QUESTION: "AddQuestionRepository",
    ADD_INTERVIEW_QUESTION_OPTION: "AddQuestionOptionRepository",
    UPDATE_INTERVIEW_QUESTION: "UpdateQuestionRepository",
    DELETE_INTERVIEW_QUESTION: "DeleteQuestionRepository",
    DELETE_INTERVIEW_QUESTION_OPTION: "DeleteQuestionOptionRepository",
    INTERVIEW_COUNTS: "InterviewCountRepository",
    INTERVIEW_INVITE: "InterviewInviteRepository",
    REVOKE_INTERVIEW_INVITE: "RevokeInterviewInviteRepository",
    CANDIDATE_LIST_BY_INTERVIEW: "CandidateListByInterviewRepository",
    JOBPOSTING_DETAIL_BY_INVITE: "JobPostingDetailByInviteKeyRepository",
    CANDIDATE_CV_ANALYSIS: "CandidateCvAnalysisRepository",
    CANDIDATE_INTERVIEW_RESPONSES: "CandidateInterviewResponsesRepository",
    CANDIDATE_INTERVIEW_LOGS: "CandidateInterviewLogRepository",
  }
};
