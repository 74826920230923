import { BaseDomain } from "@/core/base/BaseDomain";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";

export class CreateInterviewModel extends BaseDomain {
  jobPosting?: number;
  title?: string;
  description?: string;
  interviewQuestions?: InterviewQuestionModel[];
  interviewSequences?: InterviewSequenceModel[];
  ok?: boolean;

  constructor(
    jobPosting?: number,
    title?: string,
    description?: string,
    interviewQuestions?: InterviewQuestionModel[],
    interviewSequences?: InterviewSequenceModel[],
    ok?: boolean,
  ) {
    super();
    this.jobPosting = jobPosting;
    this.title = title;
    this.description = description;
    this.interviewQuestions = interviewQuestions;
    this.interviewSequences = interviewSequences;
    this.ok = ok;
  }
}
