import { GetProductRepository } from "../repository/GetProductRepository";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { IGetProductUseCase } from "@/domain/product/get-product/usecase/IGetProductUseCase";
import { GetProductMapper } from "../mapper/GetProductMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGetProductRepository } from "@/domain/product/get-product/repository/IGetProductRepository";

export class GetProductUseCase implements IGetProductUseCase {
  getProductRepository : IGetProductRepository = new GetProductRepository();
  getProductMapper = new GetProductMapper();

  constructor() {
  }

  async execute(model: GetProductModel): Promise<Result<GetProductModel>> {
    try {
      console.log(model)
      const dto = this.getProductMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<GetProductModel>(dto.getError());
      } else {
        const response = await this.getProductRepository.getProduct(dto);

        if (response.isSuccess) {
          const domain = this.getProductMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<GetProductModel>(domain.getValue());
        } else {
          return Result.fail<GetProductModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<GetProductModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.GET_PRODUCT))
      );
    }
  }
}
