import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import CandidateListByInterviewService from "../service/CandidateListByInterviewService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICandidateListByInterviewRepository } from "@/domain/interviewer/candidate-list-by-interview/repository/ICandidateListByInterviewRepository";
import { CandidateListByInterviewResponseDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewResponseDto";
import { CandidateListByInterviewRequestDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewRequestDto";

const candidateListService = new CandidateListByInterviewService();

export class CandidateListByInterviewRepository implements ICandidateListByInterviewRepository {
  async candidateList(
    request: CandidateListByInterviewRequestDto
  ): Promise<Result<CandidateListByInterviewResponseDto>> {
    try {
      return await candidateListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<CandidateListByInterviewResponseDto>(response.getValue());
          } else {
            return Result.fail<CandidateListByInterviewResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<CandidateListByInterviewResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.INTERVIEW.CANDIDATE_LIST_BY_INTERVIEW)
        )
      );
    }
  }
}
