import { BaseDTO } from "@/core/base/BaseDTO";

export class GetProductRequestDto extends BaseDTO {
  shortCode?: string
  locale?: string;

  constructor(
    shortCode?: string,
    locale?: string,
  ) {
    super();
    this.shortCode = shortCode;
    this.locale = locale
  }

  toQueryString(): string {
    let queryString = `?locale=${this.locale}`;

    return queryString;
  }
}
