import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { CandidateActionListModel } from "@/domain/interviewer/candidate-action-list/model/CandidateActionListModel";
import { CandidateActionListResponseDto } from "@/domain/interviewer/candidate-action-list/dto/CandidateActionListResponseDto";
import { CandidateActionListRequestDto } from "@/domain/interviewer/candidate-action-list/dto/CandidateActionListRequestDto";

const paginationMapper = new PaginationMapper();

export class CandidateActionListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CandidateActionListResponseDto): Result<CandidateActionListModel[]> {
    try {
      const candidateActionList: CandidateActionListModel[] = [];
      if (dto.data)
        if (dto.data.length > 0) {
          for (const item of dto.data) {
            const candidateActionListModel: CandidateActionListModel = {
              candidateAction: {
                interviewTitle: item.attributes.interviewTitle,
                status: item.attributes.status,
                updatedAt: item.attributes.updatedAt,
              },
              page: 0,
              pageSize: 0,
              pagination: dto.meta?.pagination,
            };

            candidateActionList.push(candidateActionListModel);
          }
          return Result.ok(candidateActionList);
        }
      return Result.ok(candidateActionList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }

  mapToDTO(domain: CandidateActionListModel): Result<CandidateActionListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize,
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const candidateActionListRequest = new CandidateActionListRequestDto(pagiRequest, domain.candidateAction.candidateId);

      return Result.ok(candidateActionListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }
}
