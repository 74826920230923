import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { ChangeTenantEstateUseCase } from "@/application/auth/change-tenant-estate/usecase/ChangeTenantEstateUseCase";
import { AboutUserUseCase } from "@/application/user/about-user/usecase/AboutUserUseCase";
import { UpdateUserUsecase } from "@/application/user/update-user/usecase/UpdateUserUseCase";
import { ChangeTenantEstateModel } from "@/domain/auth/change-tenant-estate/model/ChangeTenantEstateModel";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";
import { AboutUserModel } from "@/domain/user/about-user/model/AboutUserModel";

export function createUserController() {
  const useCases = {
    aboutUser: {
      useCase: new AboutUserUseCase(),
      model: AboutUserModel,
    },
    updateUser: {
      useCase: new UpdateUserUsecase(),
      model: UpdateUserModel,
    },
    changeTenantEstate: {
      useCase: new ChangeTenantEstateUseCase(),
      model: ChangeTenantEstateModel,
    },
  };

  return new UserController(useCases);
}

export class UserController implements IController{
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}