
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { TenantEstateModel } from "@/domain/user/tenant-estate/model/TenantEstateModel";
import { ChangeTenantEstateModel } from "@/domain/auth/change-tenant-estate/model/ChangeTenantEstateModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "name",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const userController = store.state.ControllersModule.userController;
    const router = useRouter();
    const changedTenant = ref<number>(0);
      const swalNotification = new SwalNotification();

    const tenantEstates = ref<TenantEstateModel[]>([]);
    
    const getUserInfo = () => {
      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {

            tenantEstates.value = response.getValue().tenantEstates
            
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const changeTenant = (tenantEstate: TenantEstateModel) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: Number(tenantEstate.id),
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          if (response.isSuccess) {
            window.location.href = "/dashboard";
            /*
                        location.reload();
            router.push({ name: "dashboard" });
            */

            /*
            changedTenant.value += 1;
              store.dispatch("changeTenant", changedTenant.value);

            router.push({ name: "dashboard" });
*/
          }
        });
    };

    onMounted(() => {
      getUserInfo();
    });

    return {
      tenantEstates,
      changeTenant,
    };
  },
});
