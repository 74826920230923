
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const interviewTitle = ref("");
    const interviewDesc = ref("");
    const showCustomQuestionInput = ref(false);
    
    const updateTitleDescription = () => {
      emit("updateTitleDescription", {
        title: interviewTitle.value,
        description: interviewDesc.value,
      });
    };

    return {
      showCustomQuestionInput,
      updateTitleDescription,
      interviewTitle,
      interviewDesc
    };
  },
});
