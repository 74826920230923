import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICandidateListUseCase } from "@/domain/interviewer/candidate-list/usecase/ICandidateListUsecase";
import { CandidateListRepository } from "../repository/CandidateListRepository";
import { ICandidateListRepository } from "@/domain/interviewer/candidate-list/repository/ICandidateListRepository";
import { CandidateListMapper } from "../mapper/CandidateListMapper";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";

export class CandidateListUseCase implements ICandidateListUseCase {
  candidateListRepository: ICandidateListRepository =
    new CandidateListRepository();
  candidateListMapper = new CandidateListMapper();

  constructor() {}

  async execute(
    model: CandidateListModel
  ): Promise<Result<CandidateListModel>> {
    try {
      const dto = this.candidateListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<CandidateListModel>(dto.getError());
      } else {
        const response = await this.candidateListRepository.candidateList(dto);

        if (response.isSuccess) {
          const domain = this.candidateListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateListModel>(domain.getValue());
        } else {
          return Result.fail<CandidateListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateListModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_LIST))
      );
    }
  }
}
