import { BaseDomain } from "@/core/base/BaseDomain";

export class AddQuestionOptionModel extends BaseDomain {
  interviewQuestion?: number;
  optionText?: string;
  ok?: boolean

  constructor(
    interviewQuestion?: number,
    optionText?: string,
    ok?: boolean,
  ) {
    super();
    this.interviewQuestion = interviewQuestion;
    this.optionText = optionText;
    this.ok = ok;
  }
}
