import { BaseDomain } from "@/core/base/BaseDomain";
import { JobPostingDetailModel } from "../../job-posting-detail/model/JobPostingDetailModel";
import { InterviewQuestionModel } from "../../model/InterviewQuestionsModel";

export class InterviewDetailModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  sequence?: number;
  hasCompletedInterviewSession?: boolean;
  jobPosting?: JobPostingDetailModel;
  interviewQuestions?: InterviewQuestionModel[];

  constructor(
    id?: number,
    title?: string,
    description?: string,
    sequence?: number,
    hasCompletedInterviewSession?: boolean,
    jobPosting?: JobPostingDetailModel,
    interviewQuestions?: InterviewQuestionModel[],
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.sequence = sequence;
    this.hasCompletedInterviewSession = hasCompletedInterviewSession;
    this.jobPosting = jobPosting;
    this.interviewQuestions = interviewQuestions;
  }
}
