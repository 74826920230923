import { BaseDomain } from "@/core/base/BaseDomain";

export class InterviewCountModel extends BaseDomain {
  total?: number;
  completed?: number;
  inProgress?: number;
  failed?: number;
  query?: string;
  jobPostingId?: number;
  interviewId?: number;

  constructor(
    total?: number,
    completed?: number,
    inProgress?: number,
    failed?: number,
    query?: string,
    jobPostingId?: number,
    interviewId?: number,
  ) {
    super();
    this.total = total;
    this.completed = completed;
    this.inProgress = inProgress;
    this.failed = failed;
    this.query = query;
    this.jobPostingId = interviewId;
    this.interviewId = jobPostingId;
  }
}
