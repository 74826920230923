import { BaseDomain } from "@/core/base/BaseDomain";
import { EmotionAnalysisModel } from "../../../emotion-analysis/model/EmotionAnalysisModel";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class SendMessageModel extends BaseDomain {
  text?: string;
  session: {
    id?: number;
    title?: string;
    createdAt?: Date;
    productSessionMessages: {
      messageId: number;
      owner: string;
      text: string;
      createdAt: Date;
      emotionAnalysis?: EmotionAnalysisModel;
    }[];
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  query?: string;

  constructor(
    productSessionMessages: {
      messageId: number;
      owner: string;
      text: string;
      createdAt: Date;
      emotionAnalysis?: EmotionAnalysisModel;
    }[],
    text?: string,
    id?: number,
    title?: string,
    createdAt?: Date,

    query?: string
  ) {
    super();
    this.text = text;
    this.session = {
      id: id,
      title: title,
      createdAt: createdAt,
      productSessionMessages: productSessionMessages,
    };
    this.query = query;
  }
}
