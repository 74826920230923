
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { DeleteQuestionOptionRepository } from "../repository/DeleteQuestionOptionRepository";
import { DeleteQuestionOptionMapper } from "../mapper/DeleteQuestionOptionMapper";
import { IDeleteQuestionOptionUseCase } from "@/domain/interviewer/update-interview/deleteQuestionOption/usecase/IDeleteQuestionOptionUseCase";
import { IDeleteQuestionOptionRepository } from "@/domain/interviewer/update-interview/deleteQuestionOption/repository/IDeleteQuestionOptionRepository";
import { DeleteQuestionOptionModel } from "@/domain/interviewer/update-interview/deleteQuestionOption/model/DeleteQuestionOptionModel";

export class DeleteQuestionOptionUseCase implements IDeleteQuestionOptionUseCase {
  deleteQuestionOptionRepository : IDeleteQuestionOptionRepository = new DeleteQuestionOptionRepository();
  deleteQuestionOptionMapper = new DeleteQuestionOptionMapper();

  constructor() {
  }

  async execute(model: DeleteQuestionOptionModel): Promise<Result<DeleteQuestionOptionModel>> {
    try {
      const dto = this.deleteQuestionOptionMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteQuestionOptionModel>(dto.getError());
      } else {
        const response = await this.deleteQuestionOptionRepository.deleteQuestionOption(dto);

        if (response.isSuccess) {
          const domain = this.deleteQuestionOptionMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteQuestionOptionModel>(domain.getValue());
        } else {
          return Result.fail<DeleteQuestionOptionModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteQuestionOptionModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.DELETE_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
