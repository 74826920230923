import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import StaffListService from "../service/StaffListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IStaffListRepository } from "@/domain/staff/staff-list/repository/IStaffListRepository";
import { StaffListRequestDto } from "@/domain/staff/staff-list/dto/StaffListRequestDto";
import { StaffListResponseDto } from "@/domain/staff/staff-list/dto/StaffListResponseDto";

const staffListService = new StaffListService();

export class StaffListRepository implements IStaffListRepository {
  async staffList(
    request: StaffListRequestDto
  ): Promise<Result<StaffListResponseDto>> {
    try {
      return await staffListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<StaffListResponseDto>(response.getValue());
          } else {
            return Result.fail<StaffListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<StaffListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.STAFF.STAFF_LIST))
      );
    }
  }
}
