import { BaseDomain } from "@/core/base/BaseDomain";
import { ProductModelSettingModel } from "./ProductModelSettingModel";
import { ProductPurchaseModel } from "./ProductPurchaseModel";

export class ProductPackageModel extends BaseDomain {
    id?: number;
    name?: string;
    price?: number;
    currency?: number;
    description?: string;
    period?: number;
    quota?: number;
    purchased?: boolean;
    purchase?: ProductPurchaseModel;
    productModelSettings: ProductModelSettingModel[];
  
  constructor(
    productModelSettings: ProductModelSettingModel[],
    id?: number,
    name?: string,
    price?: number,
    currency?: number,
    description?: string,
    period?: number,
    quota?: number,
    purchased?: boolean,
    purchase?: ProductPurchaseModel,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.price = price;
    this.currency = currency;
    this.description = description;
    this.period = period;
    this.quota = quota;
    this.purchased = purchased;
    this.purchase = purchase;
    this.productModelSettings = productModelSettings;
  }
}
