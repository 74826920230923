import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdateInterviewModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  // actions: UpdateInterviewActionModel[];
  ok?: boolean;

  constructor(
    // actions: UpdateInterviewActionModel[],
    id?: number,
    title?: string,
    description?: string,
    ok?: boolean
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    // this.actions = actions;
    this.ok = ok;
  }
}
