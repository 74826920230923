import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateProductModelSettingResponseDto } from "@/domain/product/update-product-model-setting/dto/UpdateProductModelSettingResponseDto";
import { UpdateProductModelSettingModel } from "@/domain/product/update-product-model-setting/model/UpdateProductModelSettingModel";
import { UpdateProductModelSettingRequestDto } from "@/domain/product/update-product-model-setting/dto/UpdateProductModelSettingRequestDto";

export class UpdateProductModelSettingMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(
    dto: UpdateProductModelSettingResponseDto
  ): Result<UpdateProductModelSettingModel> {
    try {
      const updateProductModelSettingModel: UpdateProductModelSettingModel = {
        productId: dto.data.id,
        name: dto.data.attributes.name,
        price: dto.data.attributes.price,
        currency: dto.data.attributes.currency,
        description: dto.data.attributes.description,
        period: dto.data.attributes.period,
        quota: dto.data.attributes.quota,
      };

      return Result.ok(dto);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.UPDATE_PRODUCT_MODEL_SETTING))
      );
    }
  }

  mapToDTO(
    domain: UpdateProductModelSettingModel
  ): Result<UpdateProductModelSettingRequestDto> {
    try {
      const updateProductModelSettingRequest = new UpdateProductModelSettingRequestDto(
        Number(domain.productModelSetting),
        Number(domain.productId)
      );
      return Result.ok(updateProductModelSettingRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.UPDATE_PRODUCT_MODEL_SETTING))
      );
    }
  }
}
