import { BaseDomain } from "@/core/base/BaseDomain";
import { JobPostingQualificationModel } from "../../job-posting-list/model/JobPostingQualificationModel";
import { JobPostingInterviewModel } from "../../model/JobPostingInterviewModel";

export class JobPostingDetailModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  status?: string;
  candidateCount?: number;
  hasCompletedInterviewSession?: boolean;
  hasCvAnalysis?: boolean;
  jobPostingQualifications?: JobPostingQualificationModel[];
  interviews?: JobPostingInterviewModel[];

  constructor(
    id?: number,
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    status?: string,
    candidateCount?: number,
    hasCompletedInterviewSession?: boolean,
    hasCvAnalysis?: boolean,
    jobPostingQualifications?: JobPostingQualificationModel[],
    interviews?: JobPostingInterviewModel[]
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.status = status;
    this.startDate = startDate;
    this.endDate = endDate;
    this.candidateCount = candidateCount;
    this.jobPostingQualifications = jobPostingQualifications;
    this.hasCompletedInterviewSession = hasCompletedInterviewSession;
    this.hasCvAnalysis = hasCvAnalysis;
    this.interviews = interviews;
  }
}
