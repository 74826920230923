import { BaseDTO } from "@/core/base/BaseDTO";

export class InviteUserRequestDto extends BaseDTO {
  data: {
    survey?: number;
    emails?: string[] | string;
  }
  locale?: string;
  constructor(
    survey?: number,
    emails?: string[] | string,
    locale?: string
  ) {
    super();
    this.data = {
      survey,
      emails
    }
    this.locale = locale;
  }
  
  toQueryString(): string {
    const queryString = `?locale=${this.locale}`;

    return queryString;
  }
}
