<template>
  <div class="d-flex flex-column flex-lg-row">
    <div
      class="
        flex-column flex-lg-row-auto
        w-100 w-lg-300px w-xl-800px
        mb-10 mb-lg-0
      "
    >
      <div class="card mb-5 mb-xxl-8 bg-white rounded shadow-sm">
        <div class="card-body">
          <div class="row" style="position: relative">
            <img
              :src="
                isGifPlaying
                  ? '/media/modules/pollster/woman-talking.gif'
                  : '/media/modules/pollster/woman.gif'
              "
              alt="AI"
              class="img-fluid"
            />
          </div>

          <div class="row">
            <!-- Progress Bar -->
            <div class="mb-6 mt-7">
              <div class="progress">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                 :style="{ width: completionPercentage + 1 + '%' }"
                   :aria-valuenow="completionPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <!-- Question Info -->
            <!-- <div class="d-flex justify-content-between">
              <h6>{{ $t("modals.interview.question") }} {{ 2 }}/{{ 8 }}</h6>
              <span class="badge bg-warning">{{
                $t("modals.interview.openEnded")
              }}</span>
            </div> -->

            <!-- Question Text -->
             
            <div class="mt-6 mb-6 text-center">
              <h4 v-if="messages.length > 0 "> {{ messages[messages.length -1].text }}</h4>
              <!-- <h4>
                <strong>Soru:</strong> React kütüphanesindeki state yapısının
                mantığı nedir, nasıl olduğunu anlatınız.
              </h4> -->
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              v-if="isInterviewSessionActive == true"
              @mousedown="startRecording"
              :disabled="isAiActive == false || isLoadingResponse"
              @mouseup="stopRecording"
              :class="{
                'btn btn-warning': !isRecording,
                'btn btn-danger': isRecording,
              }"
              type="button"
              data-kt-element="send"
              class="w-100 w-md-25"
            >
              {{ $t("modals.interview.pushToTalk") }}
            </button>
            <button
              v-else
              class="btn btn-success w-100 w-md-25"
              type="button"
              data-kt-element="send"
              @click="startChat"
            >
              {{ $t("modals.interview.startInterview.title") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card ms-lg-7 ms-xl-10 bg-white rounded shadow-sm">
      <div class="card bg-white rounded shadow-sm">
        <div class="card-header" id="kt_chat_messenger_header">
          <div class="card-title">
            <div
              class="
                d-flex
                justify-content-center
                text-center text-align-center
                flex-column
                me-3
              "
            >
              <a
                href="#"
                class="
                  fs-4
                  fw-bolder
                  text-center text-gray-900 text-hover-primary
                  me-1
                  mb-2
                  lh-1w
                "
              >
                {{ $t("modals.interview.candidate.candidateInfo") }}
              </a>
            </div>
          </div>
        </div>

        <div class="card-body text-center">
          <div
            v-if="!isActiveUserCamera && !isLoadingUserCamera"
            class="placeholder-name"
          >
            Buse Çallı
          </div>

          <div
            v-if="isLoadingUserCamera"
            class="loading-spinner"
            style="
              height: 370px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="text-center px-5 pb-7">
              <img
                class="max-h-25px"
                src="/media/loading_icon.gif"
                style="width: 35px"
              />
            </div>
          </div>

          <!-- Kamera açık ise video göster -->
          <div v-if="isActiveUserCamera">
            <video
              ref="videoElement"
              id="userCamera"
              height="370"
              autoplay
              class="w-100"
              style="object-fit: cover"
            ></video>
          </div>

          <!-- Ad Soyad ve Meslek -->
          <h4 class="text-gray-800 fw-bolder mb-2 mt-6">Buse Çallı</h4>
          <a class="text-hover-primary fs-7"> busecalli25@gmail.com</a>

          <!-- Detaylar -->
          <div class="text-start mt-4">
            <div class="row mb-7">
              <!-- İlan Başlığı -->
              <div class="col-md-6 mb-5">
                <div class="card border border-gray-300 shadow-sm rounded">
                  <div class="card-body text-center">
                    <div class="fw-bold text-gray-600">
                      {{ $t("modals.interview.startInterview.jobPosting") }}
                    </div>
                    <h4 class="mt-2 text-dark">
                      Hybrid Çalışacak Frontend Developer
                    </h4>
                  </div>
                </div>
              </div>

              <!-- Mülakat Türü -->
              <div class="col-md-6 mb-5">
                <div class="card border border-gray-300 shadow-sm rounded">
                  <div class="card-body text-center">
                    <div class="fw-bold text-gray-600">
                      {{ $t("modals.interview.startInterview.interviewType") }}
                    </div>
                    <h4 class="mt-2 text-primary">Teknik Mülakat</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-footer pt-4 pb-5"
          id="kt_chat_messenger_footer"
          style="padding-bottom: 1"
        >
          <div class="text-center">
            <button
              @click="stopChat"
              :disabled="isAiActive == false || isLoadingResponse"
              class="btn btn-dark"
              type="button"
              data-kt-element="send"
            >
              {{ $t("modals.interview.finishInterview") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorMessage, Field, Form } from "vee-validate";

import { defineComponent, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { environment } from "../../../../../../environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SocketService from "@/infrastructure/web-socket/SocketService";
import { MessageFlow } from "@/presentation/helper/MessageFlow";
import MediaRecorderService from "@/presentation/helper/MediaRecorder";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { BaseWsRequestDto } from "@/domain/base/BaseWsRequestDto";
import { InterviewSessionResponseDto } from "@/domain/interviewer/interview/model/ChatSectionResponseDto";

export default defineComponent({
  name: "Interview",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  methods: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const interviewKey = route.query.key;
    const isInterviewSessionActive = ref(false);

    const socketService = new SocketService();
    const mediaRecorderService = new MediaRecorderService();
    const fileController = store.state.ControllersModule.fileController;
    const swalNotification = new SwalNotification();

    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isActiveUserCamera = ref(false);
    const isLoadingUserCamera = ref(false);
    const isLoadingResponse = ref(false);
    const isError = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AiSpeakSecond = ref(0);
    let video: any;

    let completionPercentage = ref(0);
    const duration = ref("00:00");

    const messages = ref<Array<any>>([
      {
        text: "Mülakata hazır olduğunuzda 'Mülakata Başla' butonuna tıklayınız.",
      },
    ]);

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const startChat = () => {
      const urlPath = `/interview?key=${interviewKey}`;

      socketService.connect(urlPath, () => {
        isLoadingUserCamera.value = true;
        if (socketService.isConnected()) {
          startUserCamera();
          isAiActive.value = true;
          isInterviewSessionActive.value = true;
          isLoadingResponse.value = true;
          messages.value = [];
          showStartChatAlert();

          socketService.addEventListener("message", handleMessage);
        }
      });
    };

    const handleMessage = (message: any) => {
      // message {"data":null,"error":{"status":403,"name":"ForbiddenError","message":"Forbidden","details":{}}}

      console.log("message", JSON.parse(message));
      if (JSON.parse(message).data == null) {
        isLoadingResponse.value = false;
        isError.value = true;
        stopUserCamera();

        Swal.fire({
          text: "Mülakat başlatılamadı, geçersiz link ya da token limiti dolmuş olabilir. lütfen Yönetici ile iletişime geçin.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => router.push({ name: "landingPage" }));
      } else {
        let res: InterviewSessionResponseDto = JSON.parse(message);
        processResponse(res);
      }
    };
    
    const processResponse = (res: InterviewSessionResponseDto) => {
      const newMessage = res.data.attributes.assistantText;

      if (res.data.attributes.voice.data) {
          let url =
            environment.baseUploadUrl +
            res.data.attributes.voice.data.attributes.url;
          startAIVoice(url).then(() => {
              MessageFlow(
                newMessage,
                Number(AiSpeakSecond.value),
                messages.value,
                messages.value.length - 1
              );
          });
        }

      messages.value.push({text: newMessage})
      completionPercentage.value = res.data.attributes.completionPercentage;
      
      if (res.data.attributes.endDate) {
        Swal.fire({
          title: "Tebrikler!",
          text: "Mülakatı başarıyla tamamladınız...",
          icon: "success",
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      }
    };


    const showStartChatAlert = () => {
      Swal.fire({
        text: "Mülakat başladı, başarılar!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => router.push({ name: "doInterview" }));
    };

    const startRecording = async () => {
      video = document.getElementById("userCamera");
      if (video.srcObject) {
        mediaRecorderService.startVideoRecording(video);
      }
      isRecording.value = true;
      stopAIVoice();

      mediaRecorderService.startAudioRecording();
    };

    const startUserCamera = async () => {
      isActiveUserCamera.value = true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        isLoadingUserCamera.value = false;

        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.push({ name: "landingPage" });
        });
      }
    };

    const stopUserCamera = async () => {
      if (videoElement.value) {
        videoElement.value.style.display = "none";
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      if (
        mediaRecorderService.videoRecorder &&
        mediaRecorderService.videoRecorder.state !== "inactive" &&
        mediaRecorderService.audioRecorder &&
        mediaRecorderService.audioRecorder.state !== "inactive"
      ) {
        mediaRecorderService.stopVideoRecording();
        mediaRecorderService.stopAudioRecording();
        if (mediaRecorderService.audioRecorder)
          mediaRecorderService.audioRecorder.onstop = () => {
            console.log(mediaRecorderService.videoChunks);
            const audioBlob = new Blob(mediaRecorderService.audioChunks, {
              type: "audio/mp3",
            });
            const videoBlob = new Blob(mediaRecorderService.videoChunks, {
              type: 'video/webm; codecs="vp8, opus"',
            });
            sendBlobToAPI(audioBlob, videoBlob);
            mediaRecorderService.audioChunks = [];
            mediaRecorderService.videoChunks = [];
          };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob, videoBlob: Blob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration);
        if (event.target.duration < 1) {
          isLoadingResponse.value = false;
          return Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            router.push({ name: "doInterview" });
          });
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });

          const soundUploadModel = new FileUploadModel(soundFile);

          isLoadingResponse.value = true;

          fileController
            .fileUpload(soundUploadModel)
            .then((response) => {
              if (response.isSuccess) {
                let res = response.getValue();
                let soundId = res.id;

                const data = {
                  voice: soundId,
                };

                const request: BaseWsRequestDto = {
                  data: data,
                  type: "voiceAnalysis",
                };

                socketService.sendMessage(JSON.stringify(request));
                sendVideoToAPI(soundId, videoBlob);
              } else {
                console.log("error", response.error.cause.cause);
                return Swal.fire({
                  text: "Lütfen tekrar deneyiniz.",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  router.push({ name: "doInterview" });
                });
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      }
    };

    const sendVideoToAPI = (soundId: string, videoBlob: Blob) => {
      const videoFile = new File([videoBlob], "userVideo.mp4", {
        type: "video/mp4",
      });

      const videoUploadModel = new FileUploadModel(videoFile);

      console.log("videoUploadModel", videoUploadModel);

      fileController
        .fileUpload(videoUploadModel)
        .then((response) => {
          if (response.isSuccess) {
            let res = response.getValue();
            let videoId = res.id;

            const data = {
              voice: Number(soundId),
              video: videoId,
            };

            const request: BaseWsRequestDto = {
              data: data,
              type: "videoAnalysis",
            };

            socketService.sendMessage(JSON.stringify(request));
          } else {
            console.log("error", response.error.cause.cause);
            return Swal.fire({
              text: "Video gönderilemedi, lütfen tekrar deneyiniz.",
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Tamam",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "doInterview" });
            });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Mülakatı sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
          }).then(() => {
            const request: BaseWsRequestDto = {
              type: "closeSession",
            };

            socketService.sendMessage(JSON.stringify(request));
            isAiActive.value = false;
            localStorage.clear();
            window.location.reload();
          });
        }

        router.push({ name: "doInterview" });
      });
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AiSpeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    onMounted(() => {
      ScrollComponent.bootstrap();
      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isLoadingResponse,
      isInterviewSessionActive,
      isActiveUserCamera,
      isLoadingUserCamera,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      messagesInRef,
      messagesOutRef,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      messages,
      messagesRef,
    };
  },
});
</script>

<style>
html .swal2-height-auto {
  height: 100% !important;
}

/* Kullanıcı adı ekranı */
.placeholder-name {
  background-color: grey;
  color: white;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Yüklenme animasyonu */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Genişliği %100 yaparak, genişliği kapsayan alanda tamamlanmasını sağlar */
  height: 370px; /* Yükseklik sabit kalacak */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Transparan daire */
  border-top: 4px solid white; /* Dönen daire */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Dönen animasyon */
}

/* Spinner animasyonu */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
