
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";

export default defineComponent({
  name: "userPollDetailChatLog",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const pollId = ref<number>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: pollId.value,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollChatLogs();
    });

    return {
      pollChatLogs,
      isLoading,
    };
  },
});
