
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IRevokeInterviewInviteUseCase } from "@/domain/interviewer/revoke-interview-invite/usecase/IRevokeInterviewInviteUsecase";
import { IRevokeInterviewInviteRepository } from "@/domain/interviewer/revoke-interview-invite/repository/IRevokeInterviewInviteRepository";
import { RevokeInterviewInviteRepository } from "../repository/RevokeInterviewInviteRepository";
import { RevokeInterviewInviteMapper } from "../mapper/RevokeInterviewInviteMapper";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";

export class RevokeInterviewInviteUsecase implements IRevokeInterviewInviteUseCase {
  createInterviewRepository : IRevokeInterviewInviteRepository = new RevokeInterviewInviteRepository();
  createInterviewMapper = new RevokeInterviewInviteMapper();

  constructor() {
  }

  async execute(model: RevokeInterviewInviteModel): Promise<Result<RevokeInterviewInviteModel>> {
    try {
      const dto = this.createInterviewMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<RevokeInterviewInviteModel>(dto.getError());
      } else {
        const response = await this.createInterviewRepository.interviewInvite(dto);

        if (response.isSuccess) {
          const domain = this.createInterviewMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<RevokeInterviewInviteModel>(domain.getValue());
        } else {
          return Result.fail<RevokeInterviewInviteModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<RevokeInterviewInviteModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.REVOKE_INTERVIEW_INVITE))
      );
    }
  }
}
