import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class CandidateListModel extends BaseDomain {
  candidate: {
    jobPostingId?: number;
    candidateId?: number;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    cvUrl?: string;
    cv?: File;
    candidateStatus?: string;
    checked?: boolean;
    hasInterviewSession?: boolean;
    positionSuitability?: number;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  searchQuery?: string;
  sort?: string;

  constructor(
    jobPostingId?: number,
    candidateId?: number,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    email?: string,
    cvUrl?: string,
    cv?: File,
    candidateStatus?: string,
    checked?: boolean,
    hasInterviewSession?: boolean,
    positionSuitability?: number,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto,
    searchQuery?: string,
    sort?: string,
  ) {
    super();
    this.candidate = {
      jobPostingId,
      candidateId,
      firstName,
      lastName,
      fullName,
      email,
      cvUrl,
      cv,
      candidateStatus,
      checked,
      hasInterviewSession,
      positionSuitability,
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
    this.searchQuery = searchQuery;
    this.sort = sort;
  }
}
